const company = {
  CREATE_PROJECT: '00000001', // 创建项目
  EDIT_MEMBER: '00000002', // 增删公司成员 公司/项目成员管理
  MANAGE_TEAM_WOEKER_BLACKLIST: '00000202', // 班组/工人/黑名单管理
  MANAGE_DEPARTMENT_POSITION_WORKTYPE: '00000203', // 部门/职位/工种管理
  MANAGE_PARTICIPANT: '00000204', // 参建单位管理
  MANAGE_ATTEND_RECORD: '00000205', // 墨计考勤 考勤设置-考勤记录管理
  MANAGE_WORKER_SALARY: '00000206', // 劳务费管理 工人工钱管理
  MANAGE_REAL_TIME_POSITIONING: '00000207', // 人员实时定位管理
  MANAGE_CONSTRUCTION_LOG: '00000208', // 施工日志管理
  MANAGE_QUALITY: '00000210', // 质量管理
  MANAGE_SECURITY: '00000209', // 安全管理
  VIEW_ATTENDANCE_DATA: '00000101', // 查看出勤数据
  VIEW_CONSTRUCTION_LOG: '00000102', // 查看施工日志
  VIEW_WORKER_PORTRAIT: '00000103', // 查看工人画像
  VIEW_BALANCE: '00000104', // 查看劳务费 查看工钱数据汇总
  VIEW_REAL_TIME_POSITIONING: '00000105', // 查看人员实时定位
  VIEW_QUALITY: '00000107', // 查看质量
  VIEW_SECURITY: '00000106', // 查看安全
  VIEW_EDUCATION: '00000108', // 查看工人教育
  MANAGE_LEARN: '00000109', // 学习任务管理
  MANAGE_RED_PACKAGE: '00000110', // 红包金额管理
  VIEW_CONTRACT: '60000001', // 查看电子合同
  MANAGE_CONTRACT: '60000002', // 管理电子合同
  VIEW_COMPLAINT: '00000109', // 查看工人投诉
};

const project = {
  VIEW_ATTENDANCE_DATA: '50002001', //  查看出勤数据
  VIEW_CONSTRUCTION_LOG: '50002002', // 查看施工日志
  VIEW_WORKER_PORTRAIT: '50002003', // 查看工人画像
  VIEW_BALANCE: '50001001', // 查看劳务费 查看工钱数据汇总
  VIEW_REAL_TIME_POSITIONING: '50002004', // 查看人员实时定位
  VIEW_QUALITY: '50003002', // 查看质量
  VIEW_SECURITY: '50003001', // 查看安全
  VIEW_EDUCATION: '50003003', // 工人教育
  MANAGE_PEOJECT_MESSAGE: '50002008', // 项目信息管理
  EDIT_ADMIN: '50001002', // 人员管理-增删项目管理员
  MANAGE_TEAM_WOEKER_BLACKLIST: '50002005', // 班组/工人/黑名单管理
  MANAGE_WORKTYPE: '50002006', // 工种管理
  MANAGE_PARTICIPANT: '50001006', // 参建单位管理
  MANAGE_ATTEND_RECORD: '50001005', // 墨计考勤 考勤设置-考勤记录管理
  MANAGE_WORKER_SALARY: '50001007', // 劳务费管理 工人工钱管理
  MANAGE_REAL_TIME_POSITIONING: '50002007', // 人员实时定位管理
  MANAGE_CONSTRUCTION_LOG: '50001008', // 施工日志管理
  MANAGE_QUALITY: '50002010', // 质量管理
  MANAGE_SECURITY: '50002009', // 安全管理
  MANAGE_LEARN: '50003004', // 学习任务管理
  MANAGE_RED_PACKAGE: '50003005', // 红包金额管理
  VIEW_CONTRACT: '60001001', // 查看电子合同
  MANAGE_CONTRACT: '60001002', // 管理电子合同
  VIEW_COMPLAINT: '50003004', // 查看工人投诉
};

const team = {
  manager: {
    VIEW_BALANCE: '30001002',
    EDIT_ATTEND_RECORD: '30001001',
    ADD_WORKER: '30001004',
    REMOVE_WORKER: '30001005',
    EDIT_BALANCE: '30001003',
  },
};

export default {
  company,
  project,
  team,
};
