import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import styled from 'styled-components';

import managePath from '../../../../img/manage@3x.png';
import managerPath from '../../../../img/manager@3x.png';
import dailyPath from '../../../../img/daily@3x.png';
import unitsPath from '../../../../img/units@3x.png';
import moneyPath from '../../../../img/money@3x.png';
import config from '../../../../config';

const posTypeMap = {
  COMPANY: '00',
  PROJECT: '01',
};

const {
  EDIT_MEMBER,
  CREATE_PROJECT,
  VIEW_WORKER_TALENT_POOL,
  VIEW_LEADER_TALENT_POOL,
} = config.authority.company;
const {
  VIEW_BALANCE,
  EDIT_ADMIN,
  EDIT_TEAM,
  ADD_OR_REMOVE_WORKER,
  MANAGE_ATTEND_RECORD,
  MANAGE_PARTICIPANT,
  MANAGE_WORKER_SALARY,
  MANAGE_CONSTRUCTION_LOG,
} = config.authority.project;

function AuthRow({ ttl, desc, icon, isSuper, onChange, checked, disabled }) {
  return (
    <div
      className={`auth_row${isSuper ? ' super' : ''}`}
      data-flex="main:left cross:center"
    >
      {icon || null}
      <div data-flex-box="1">
        <div className="ttl">{ttl}</div>
        {desc ? <div className="desc">{desc}</div> : null}
      </div>
      <div data-flex-box="0">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

AuthRow.propTypes = {
  ttl: PropTypes.string.isRequired,
  desc: PropTypes.string,
  icon: PropTypes.element,
  isSuper: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

AuthRow.defaultProps = {
  desc: null,
  icon: null,
  isSuper: false,
  checked: false,
  disabled: false,
};

const StyledAuthBox = styled.div`
  .auth_group {
    padding: 8px 0;
    margin: 0 8px;
    border-bottom: 1px solid #e8e8e8;
  }
  .auth_group:last-child {
    border-bottom-width: 0;
  }
  .auth_row {
    padding: 12px 0;

    &.super {
      border-radius: 4px;
      background-color: rgba(24, 144, 255, 0.1);
      padding: 8px;
    }

    .icon {
      display: block;
      width: 30px;
      margin-right: 8px;
    }

    img.icon {
      height: 30px;
    }

    .ttl {
      font-size: 14px;
      color: rgba(33, 39, 50, 0.65);
    }

    .desc {
      font-size: 12px;
      color: rgba(33, 39, 50, 0.45);
      margin-top: 4px;
    }
  }
`;

function AuthBox({ type, onChange, initValue }) {
  const [superAdmin, setSuperAdmin] = useState(false);
  const [val, setVal] = useState({});
  const onSuperAdminChange = useCallback(
    value => {
      setSuperAdmin(value);
      if (value) {
        onChange({ superAdmin: value, authorities: [] });
      } else {
        onChange({
          superAdmin: value,
          authorities: Object.keys(val).filter(k => val[k]),
        });
      }
    },
    [val]
  );
  const onAuthRowChange = useCallback(value => {
    setVal(s => {
      const newVal = { ...s, [value.name]: value.value };
      onChange({
        superAdmin,
        authorities: Object.keys(newVal).filter(k => newVal[k]),
      });
      return newVal;
    });
  }, []);
  const onMemberChange = useCallback(value => {
    setVal(s => {
      const newVal = {
        ...s,
        [EDIT_ADMIN]: value,
        [EDIT_TEAM]: value,
        [ADD_OR_REMOVE_WORKER]: value,
      };
      onChange({
        superAdmin,
        authorities: Object.keys(newVal).filter(k => newVal[k]),
      });
      return newVal;
    });
  }, []);
  useEffect(() => {
    if (!initValue) {
      return;
    }
    setSuperAdmin(!!initValue.superAdmin);
    const newVal = (initValue.authorities || []).reduce((pV, cV) => {
      const p = pV;
      p[cV] = true;
      return p;
    }, {});
    setVal(newVal);
  }, [initValue]);
  if (type === posTypeMap.PROJECT) {
    return (
      <StyledAuthBox>
        <AuthRow
          ttl="项目超管"
          desc="拥有全部权限"
          isSuper
          checked={superAdmin}
          onChange={onSuperAdminChange}
        />
        {!superAdmin ? (
          <React.Fragment>
            <div className="auth_group">
              <AuthRow
                ttl="查看项目数据星盘"
                desc="拥有查看项目汇总数据的权限"
                checked={val[config.authority.project.VIEW_DASHBOARD]}
                onChange={v =>
                  onAuthRowChange({
                    name: config.authority.project.VIEW_DASHBOARD,
                    value: v,
                  })
                }
                disabled
              />
              <AuthRow
                ttl="查看项目工钱数据"
                desc="项目数据星盘中展示工钱数据模块"
                checked={val[VIEW_BALANCE]}
                onChange={v =>
                  onAuthRowChange({ name: VIEW_BALANCE, value: v })
                }
              />
            </div>
            <div className="auth_group">
              <AuthRow
                icon={<img className="icon" src={managePath} alt="icon" />}
                ttl="人员管理"
                name="member"
                checked={
                  val[EDIT_ADMIN] && val[EDIT_TEAM] && val[ADD_OR_REMOVE_WORKER]
                }
                onChange={onMemberChange}
              />
              <AuthRow
                icon={<div className="icon" />}
                ttl="项目成员管理"
                desc="项目成员添加、删除，权限分配"
                checked={val[EDIT_ADMIN]}
                onChange={v => onAuthRowChange({ name: EDIT_ADMIN, value: v })}
              />
              <AuthRow
                icon={<div className="icon" />}
                ttl="增删班组"
                checked={val[EDIT_TEAM]}
                onChange={v => onAuthRowChange({ name: EDIT_TEAM, value: v })}
              />
              <AuthRow
                icon={<div className="icon" />}
                ttl="入退场工人"
                checked={val[ADD_OR_REMOVE_WORKER]}
                onChange={v =>
                  onAuthRowChange({ name: ADD_OR_REMOVE_WORKER, value: v })
                }
              />
            </div>
            <div className="auth_group">
              <AuthRow
                icon={<img className="icon" src={dailyPath} alt="icon" />}
                ttl="施工日志管理"
                desc="施工日志后台管理、导出权限"
                checked={val[MANAGE_CONSTRUCTION_LOG]}
                onChange={v =>
                  onAuthRowChange({ name: MANAGE_CONSTRUCTION_LOG, value: v })
                }
              />
            </div>
            <div className="auth_group">
              <AuthRow
                icon={<img className="icon" src={managerPath} alt="icon" />}
                ttl="考勤管理"
                desc="修改工人异常考勤记录，审批补卡、误打卡申请"
                checked={val[MANAGE_ATTEND_RECORD]}
                onChange={v =>
                  onAuthRowChange({ name: MANAGE_ATTEND_RECORD, value: v })
                }
              />
            </div>
            <div className="auth_group">
              <AuthRow
                icon={<img className="icon" src={unitsPath} alt="icon" />}
                ttl="参建单位管理"
                checked={val[MANAGE_PARTICIPANT]}
                onChange={v =>
                  onAuthRowChange({ name: MANAGE_PARTICIPANT, value: v })
                }
              />
            </div>
            <div className="auth_group">
              <AuthRow
                icon={<img className="icon" src={moneyPath} alt="icon" />}
                ttl="工人工钱管理"
                checked={val[MANAGE_WORKER_SALARY]}
                onChange={v =>
                  onAuthRowChange({ name: MANAGE_WORKER_SALARY, value: v })
                }
              />
            </div>
          </React.Fragment>
        ) : null}
      </StyledAuthBox>
    );
  }
  return (
    <StyledAuthBox>
      <AuthRow
        ttl="超级管理员"
        desc="拥有全部权限"
        isSuper
        checked={superAdmin}
        onChange={onSuperAdminChange}
      />
      {!superAdmin ? (
        <React.Fragment>
          <div className="auth_group">
            <AuthRow
              ttl="查看公司数据星盘"
              desc="拥有查看公司项目汇总数据权限"
              border
              checked={val[config.authority.company.VIEW_DASHBOARD]}
              onChange={v =>
                onAuthRowChange({
                  name: config.authority.company.VIEW_DASHBOARD,
                  value: v,
                })
              }
            />
          </div>
          <div className="auth_group">
            <AuthRow
              ttl="公司成员管理"
              desc="公司成员添加、删除，权限分配"
              checked={val[EDIT_MEMBER]}
              onChange={v => onAuthRowChange({ name: EDIT_MEMBER, value: v })}
            />
            <AuthRow
              ttl="新增项目"
              checked={val[CREATE_PROJECT]}
              onChange={v =>
                onAuthRowChange({ name: CREATE_PROJECT, value: v })
              }
            />
            <AuthRow
              ttl="查看工人库"
              checked={val[VIEW_WORKER_TALENT_POOL]}
              onChange={v =>
                onAuthRowChange({ name: VIEW_WORKER_TALENT_POOL, value: v })
              }
            />
            <AuthRow
              ttl="查看班组库"
              checked={val[VIEW_LEADER_TALENT_POOL]}
              onChange={v =>
                onAuthRowChange({ name: VIEW_LEADER_TALENT_POOL, value: v })
              }
            />
          </div>
        </React.Fragment>
      ) : null}
    </StyledAuthBox>
  );
}

AuthBox.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  initValue: PropTypes.object,
};

AuthBox.defaultProps = {
  initValue: null,
};

export default AuthBox;
