import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Select,
  Tabs,
  Button,
  Modal,
  message,
  Tooltip,
  Empty,
  Spin,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';
import req from '../../req';
import config from '../../config';

import Data from './Data/index';
import List from './List/index';
import Setting from './Setting';
import bg from '../../img/bg_11@3x.png';
import icon from '../../img/icon@3x.png';
import contentIcon from '../../img/ic_28_function@3x.png';
import infoIcon from '../../img/info@3x.png';

const {
  company: companyAuthority,
  project: projectAuthority,
} = config.authority;

const StyledDiv = styled.div`
  .nav {
    padding: 16px 32px;
    background-color: #fff;
    .current {
      display: flex;
      align-items: center;
      .project {
        margin-left: 24px;
      }
    }
  }
  .ant-tabs {
    .ant-tabs-nav {
      background-color: #fff;
      padding: 0 32px;
      margin-bottom: 24px;
    }
  }
`;
const StyledModal = styled.div`
  .project_name {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    margin-top: 30px;
    text-align: center;
  }
  .qrcode {
    width: 180px;
    height: 180px;
    margin: 14px auto 16px;
    display: block;
  }
  .desc {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 22px;
  }
  .download {
    width: 328px;
    height: 44px;
    margin: 24px auto;
    display: block;
  }
`;

// 处理浏览器直接预览pdf文件、以及文件名问题
function downloadFile(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  // 创建隐藏的可下载链接
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}
const projectOptionsMap = {};

const StyledEmptyData = styled.div`
  margin: 0 24px;
  background-color: #fff;
  padding: 200px 0;
`;

const StyledNotOpened = styled.div`
  margin: 0 24px;
  background-color: #fff;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 108px 132px 57px;
  position: relative;
  overflow: hidden;
  .title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    line-height: 24px;
  }
  .desc {
    font-weight: 400;
    font-size: 16px;
    color: #7f8388;
    line-height: 22px;
    margin-top: 16px;
  }
  .content {
    padding: 24px;
    background-color: #fff;
    margin-top: 32px;
    position: relative;
    z-index: 2;
    .content_t {
      display: flex;
      align-items: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #e9e9e9;
      .icon {
        width: 28px;
        height: 28px;
      }
      .t {
        margin-left: 4px;
        font-weight: 500;
        font-size: 16px;
        color: #0084ff;
        line-height: 28px;
      }
      .c_item {
        font-weight: 400;
        font-size: 16px;
        color: #1a202a;
        line-height: 28px;
        margin-left: 4px;
      }
    }
    .info {
      position: relative;
      .item {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .num {
          width: 24px;
          height: 24px;
          background: #e6e8eb;
          border-radius: 8px;
          font-weight: 500;
          font-size: 12px;
          color: #1a202a;
          text-align: center;
          line-height: 24px;
        }
        .t {
          font-weight: 400;
          line-height: 28px;
          color: #666666;
          font-size: 14px;
          margin-left: 12px;
        }
      }
      .info_icon {
        position: absolute;
        top: 32px;
        right: 0;
        width: 225px;
        height: 132px;
      }
    }
  }
  .icon_c {
    position: absolute;
    width: 215px;
    height: 215px;
    top: 39.5px;
    right: 124.5px;
    z-index: 1;
  }
  .contact {
    margin: 16px auto 0;
    /* width: 200px; */
    height: 58px;
    background-color: #fff;
    border-radius: 8px;
    color: #0084ff;
    line-height: 58px;
    text-align: center;
    cursor: pointer;
  }
`;

const EmptyData = ({ ready, pid }) => {
  if (!ready) {
    return (
      <StyledEmptyData
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size="large" />
      </StyledEmptyData>
    );
  }
  return (
    <StyledEmptyData>
      {pid ? (
        <Empty description="暂无权限" />
      ) : (
        <Empty
          description={
            <>
              暂无数据<div>若您是项目管理员，可选择项目进行查看</div>
            </>
          }
        />
      )}
    </StyledEmptyData>
  );
};

const NotOpened = () => {
  return (
    <StyledNotOpened>
      <div className="title">投诉最便捷 处置最快捷</div>
      <div className="desc">
        工人投诉有途径，将影响范围先控制在项目投诉记录在线化，投诉处理进展实时管理
      </div>
      <div className="content">
        <div className="content_t">
          <img src={contentIcon} alt="" className="icon" />
          <div className="t">功能亮点：</div>
          <div className="c_item">
            控制影响&nbsp;&nbsp;I&nbsp;&nbsp;在线记录&nbsp;&nbsp;I&nbsp;&nbsp;实时管理
          </div>
        </div>
        <div className="info">
          <div className="item">
            <div className="num">01</div>
            <div className="t">
              工人只需打开微信
              <span style={{ color: '#303133', fontWeight: 500 }}>
                扫描“投诉码”，填写投诉信息，
              </span>
              即可随时随地、足不出户地发起维权投诉
            </div>
          </div>
          <div className="item">
            <div className="num">02</div>
            <div className="t">
              投诉直达项目/公司处理人，线上
              <span style={{ color: '#303133', fontWeight: 500 }}>
                跟进处理，
              </span>
              处理进度
              <span style={{ color: '#303133', fontWeight: 500 }}>
                实时可见
              </span>
            </div>
          </div>
          <div className="item">
            <div className="num">03</div>
            <div className="t">
              企业/项目管理层可实时查看项目投诉数据，
              <span style={{ color: '#303133', fontWeight: 500 }}>
                控制投诉影响
              </span>
            </div>
          </div>
          <img src={infoIcon} className="info_icon" alt="" />
        </div>
      </div>
      <div className="contact">请联系客服开通，客服电话：400-004-0686</div>
      <img src={icon} alt="" className="icon_c" />
    </StyledNotOpened>
  );
};

EmptyData.propTypes = {
  ready: PropTypes.bool,
  pid: PropTypes.string,
};

EmptyData.defaultProps = {
  ready: false,
  pid: null,
};

export default function WorkerComplaints() {
  const match = useRouteMatch();
  const [curentCompany, setCurentCompany] = useState({});
  const [projectOptions, setProjectOptions] = useState([]);
  const [pid, setPid] = useState(null);
  const [qrcodeUrl, setQrcodeUrl] = useState('');
  const [navType, setNavType] = useState('statistics');
  const [visible, setVisible] = useState(false);
  const [now, setNow] = useState(null);
  const [isShowComplaint, setIsShowComplaint] = useState();
  const [canEditComplaint, setCanEditComplaint] = useState(false);
  const [showComplaintOpen, setShowComplaintOpen] = useState(false);
  /**
   * 获取当前公司
   */
  const getMyCrtCompany = useCallback(() => {
    return req.company
      .getMyCrt()
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        console.log(res);
        setCurentCompany(res.data);
        // return res;
      })
      .catch(req.err.show);
  }, []);
  const getProjects = useCallback(() => {
    return req.company
      .getProjects({ companyId: curentCompany.id, type: '05' })
      .then(r1 => {
        if (r1.code !== 0) {
          req.err.show(r1);
          return;
        }
        const list = r1.data.list || [];
        list.forEach(item => {
          projectOptionsMap[item.id] = item.name;
        });
        setProjectOptions(
          list.map(item => ({ value: item.id, label: item.name }))
        );
        console.log('项目列表', r1, projectOptions);
      })
      .catch(err => {
        req.err.show(err);
      });
  }, [curentCompany]);
  const getModuleBetaInfo = useCallback(() => {
    const params = {};
    if (pid) {
      params.projectId = pid;
    } else {
      params.companyId = curentCompany.id;
    }
    console.log('getModuleBetaInfo', req.sys);
    req.sys
      .getModuleBetaInfo(params, match.params.token)
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        const { workerComplaint } = res.data;
        console.log('模块开通', res);
        setShowComplaintOpen(workerComplaint === '01');
      })
      .catch(req.err.show);
  }, [pid, curentCompany.id, match]);
  const getQrCode = useCallback(async () => {
    const result = await req.complaints.getProjectComplaintsQrCode({
      projectId: pid,
    });
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    const { picUrl } = result.data;
    setQrcodeUrl(picUrl);
    console.log(result, 'qrcode');
  }, [pid]);
  const handleDownload = useCallback((url, fileName) => {
    const { protocol } = window.location;
    let u = url;
    if (protocol === 'https:' && /^http:/.test(u)) {
      u = u.replace('http:', protocol);
    }
    fetch(u)
      // record.doc_url为文件url地址
      .then(res => res.blob())
      .then(blob => {
        downloadFile(blob, fileName);
      })
      .catch(error => {
        console.log(error);
        message.error('下载失败');
      });
  }, []);
  useEffect(() => {
    const data = {};
    if (pid) {
      data.projectId = pid;
    }
    setIsShowComplaint(undefined);
    Promise.all([
      req.authority.getMap(data),
      req.complaints[pid ? 'showProjectModule' : 'showCompanyModule'](data),
    ])
      .then(([r1, r2]) => {
        if (r1.code !== 0) {
          req.err.show(r1.msg);
          return;
        }
        if (r2.code !== 0) {
          req.err.show(r2.msg);
          return;
        }
        const authMap = r1.data;
        let viewAuth = false;
        let manageAuth = false;
        if (pid) {
          viewAuth =
            authMap[projectAuthority.VIEW_COMPLAINT] ||
            authMap.company[companyAuthority.VIEW_COMPLAINT];
          manageAuth =
            authMap[projectAuthority.MANAGE_COMPLAINT] ||
            authMap.company[companyAuthority.MANAGE_COMPLAINT];
        } else {
          viewAuth = authMap[companyAuthority.VIEW_COMPLAINT];
          manageAuth = authMap[companyAuthority.MANAGE_COMPLAINT];
        }
        console.log(authMap, 'authMap');
        const isShow = r2.data.show || !!viewAuth;
        const canEdit = r2.data.show || !!manageAuth;
        setIsShowComplaint(isShow);
        setCanEditComplaint(canEdit);
      })
      .catch(req.err.show);
  }, [pid]);
  useEffect(() => {
    if (pid) {
      getQrCode();
      console.log(showComplaintOpen);
    }
    getModuleBetaInfo();
  }, [pid]);
  useEffect(() => {
    getProjects();
    if (curentCompany.id) {
      getModuleBetaInfo();
    }
  }, [curentCompany]);
  useEffect(() => {
    getMyCrtCompany();
    console.log(curentCompany, '当前公司');
  }, []);
  return (
    <StyledDiv>
      <div className="nav">
        <div className="current">
          <div className="t">当前公司：</div>
          <span>{curentCompany.name}</span>
          <div className="t project">项目：</div>
          <Select
            placeholder="请选择项目"
            value={pid}
            onChange={value => {
              setPid(value);
            }}
            allowClear
            showSearch
            optionFilterProp="label"
            options={projectOptions}
            style={{ width: 273 }}
          />
          <div style={{ flexGrow: 1 }} />
          {navType === 'statistics' ? (
            <Tooltip
              mouseEnterDelay={1}
              title={
                <>
                  <div>项目投诉码: 工人微信扫码直接发起投诉</div>
                  {/* <div>处理率：已处理/累计投诉*100%</div> */}
                  {/* <div>累计投诉：工人投诉数量的总和</div>
                  <div>未处理：工人投诉未处理的数量</div>
                  <div>已处理：工人投诉已处理的数量</div> */}
                </>
              }
            >
              <Button
                type="primary"
                onClick={() => {
                  if (!pid) {
                    message.error('请先选择项目', 3);
                    return;
                  }
                  if (!showComplaintOpen) {
                    message.error('该项目暂未开通“工人投诉”功能', 3);
                    return;
                  }
                  setVisible(true);
                }}
              >
                投诉码
                <QuestionCircleOutlined color="#fff" />
              </Button>
            </Tooltip>
          ) : null}
        </div>
      </div>
      {/* eslint-disable-next-line */}
      {(function() {
        if (!showComplaintOpen) {
          return (
            <div style={{ marginTop: 24 }}>
              <NotOpened />
            </div>
          );
        }
        console.log(!isShowComplaint, '!isShowComplaint');
        if (canEditComplaint) {
          return (
            <Tabs onChange={e => setNavType(e)}>
              <Tabs.TabPane tab="投诉统计" key="statistics">
                {!isShowComplaint ? (
                  <EmptyData ready={isShowComplaint !== undefined} pid={pid} />
                ) : (
                  <>
                    <Data
                      key="data"
                      companyId={curentCompany.id || ''}
                      projectId={pid || ''}
                      now={now}
                    />
                    <List
                      key="list"
                      companyId={curentCompany.id || ''}
                      projectId={pid || ''}
                      canEditComplaint={canEditComplaint}
                      updateData={() => {
                        setNow(Date.now());
                      }}
                    />
                  </>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="投诉处理人设置" key="setting">
                {!isShowComplaint ? (
                  <EmptyData ready={isShowComplaint !== undefined} pid={pid} />
                ) : (
                  <Setting
                    companyId={curentCompany.id || ''}
                    projectId={pid || ''}
                    tag={pid ? projectOptionsMap[pid] : curentCompany.name}
                  />
                )}
              </Tabs.TabPane>
            </Tabs>
          );
        }
        if (!isShowComplaint) {
          return (
            <div style={{ marginTop: 24 }}>
              <EmptyData ready={isShowComplaint !== undefined} pid={pid} />
            </div>
          );
        }
        return (
          <div style={{ marginTop: 24 }}>
            <Data
              key="data"
              companyId={curentCompany.id || ''}
              projectId={pid || ''}
              now={now}
            />
            <List
              key="list"
              companyId={curentCompany.id || ''}
              projectId={pid || ''}
              canEditComplaint={canEditComplaint}
              updateData={() => {
                console.log(now, '更新');
                setNow(Date.now());
              }}
            />
          </div>
        );
      })()}
      <Modal
        title="工人投诉码"
        footer={null}
        width={483}
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <StyledModal>
          <div className="project_name">{projectOptionsMap[pid] || '-'}</div>
          <img src={qrcodeUrl} className="qrcode" alt="" />
          <div className="desc">工人使用墨计APP/微信扫码</div>
          <div className="desc">填写投诉信息</div>
          <Button
            type="primary"
            className="download"
            onClick={() => {
              handleDownload(
                qrcodeUrl,
                `${projectOptionsMap[pid]}投诉二维码.png`
              );
            }}
          >
            下载二维码图片
          </Button>
        </StyledModal>
      </Modal>
    </StyledDiv>
  );
}
