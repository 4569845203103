const api = {
  getTeams: {
    url: '/bi-api/api/project2/getGroupsList/:projectId',
  },
  getAttendStatistics: {
    url: '/bi-api/api/project2/getGroupWorkersAttStatictis',
    method: 'POST',
  },
  getAttendStatisticsByOrder: {
    url: '/bi-api/api/project2/getWorkersAttStatisWithRule',
    method: 'POST',
  },
  getSalaryStatistics: {
    url: '/bi-api/api/project2/getGroupWorkersPayStatictis',
    method: 'POST',
  },
  getTeamsTotalSalary: {
    url: '/bi-api/api/project2/getGroupsPayStatistics',
    method: 'POST',
  },
  getTeamsSalaryType: {
    url: '/bi-api/api/group/getGroupsSalaryType',
    method: 'POST',
  },
  getProjectsPageType: {
    url: '/bi-api/api/project2/listCompanyProject',
    method: 'POST',
  },
  getProjectsSummary: {
    url: '/bi-api/api/project2/getProjectsSummary',
    method: 'POST',
  },
  searchBuildProjects: {
    url: '/bi-api/api/project2/searchBuildProjects',
    method: 'POST',
  },
  searchHistoryProjects: {
    url: '/bi-api/api/project2/searchHistoryProjects',
    method: 'POST',
  },
  getPresentWorkersNum: {
    url: '/bi-api/api/project2/getPresentWorkersNum/:projectId',
  },
  getExitWorkerCounts: {
    url: '/bi-api/api/project2/getExitWorkerCounts',
  },
  getGroupStatistics: {
    url: '/bi-api/api/project2/listGroupsAttendanceDetails',
  },
  listCategoryStatistics: {
    url: '/bi-api/api/project2/listCategoryAttendanceDetails',
  },
  getProjectDataById: {
    url: '/bi-api/api/project2/baseInfo/:id',
  },
  getProjectCost: {
    url: '/bi-api/api/project2/getProjectCost',
  },
  getWorkerAttendanceDetails: {
    url: '/bi-api/api/project2/getWorkerAttendanceDetails',
  },
  getMultiDayAttendanceDetails: {
    url: '/bi-api/api/project2/v2/getMultiDayAttendanceDetails',
  },
  listProjectTrend: {
    url: '/bi-api/api/project2/attendance/listProjectTrend',
  },
  listProjectPeriodTrend: {
    url: '/bi-api/api/project2/attendance/listProjectPeriodTrend',
  },
  listGroupsDayPay: {
    url: '/bi-api/api/project2/listGroupsDayPay',
  },
  listProjectCostTrendDay: {
    url: '/bi-api/api/project2/listProjectCostTrendDay',
  },
  listProjectCostTrendsMonth: {
    url: '/bi-api/api/project2/listProjectCostTrendsMonth',
  },
  getWorkerProvinceArear: {
    url: '/bi-api/api/project2/dayCount/:id/:key',
  },
  currentUserInfo: {
    url: '/bi-api/api/sys/currentUserInfo',
  },
  getCurrentPermissions: {
    url: '/bi-api/api/project2/getCurrentPermissions',
  },
  getCurrentProjectRoleType: {
    url: '/bi-api/api/project2/getCurrentProjectRoleType/:projectId',
  },
  getDashboard: {
    url: '/bi-api/api/project2/getProjectDashboard/:projectId',
  },
  // 根据班组id获取全部、在场工人、退场工人班组工人信息
  getSelectedList: {
    // url: '/bi-api/api/group/getWorkerInfo', // 旧接口
    url: '/bi-api/api/group/v2/getWorkerInfo',
    method: 'POST',
  },
  // 获取班组工人数量（去重)
  getGroupWorkerNumber: {
    url: '/bi-api/api/group/getGroupWorkerNumber',
  },
  // 获取班组工种
  getGroupWorkerCategory: {
    url: '/bi-api/api/group/getGroupWorkerCategory',
  },
  addManagerAuthory: {
    url:
      '/bi-api/api/project2/addProjectPermission/:projectId/:userId/:permissionCode',
    method: 'POST',
  },
  delManagerAuthory: {
    url:
      '/bi-api/api/project2/delProjectPermission/:projectId/:userId/:permissionCode',
    method: 'POST',
  },
  // delManager: {
  //   url: '/bi-api/api/project2/delAdmin/:projectId/:number',
  //   method: 'POST',
  // },
  addBlacklist: {
    url: '/bi-api/api/worker/blackMember/addBlackMember',
    method: 'POST',
  },
  exportAttendReport: {
    url: '/bi-api/api/project2/exportProjectAttendanceReport',
  },
  // 获取公司/项目角色及权限列表
  getUserRoleCodeAndPermissionList: {
    url: '/bi-api/api/sys/getUserRoleCodeAndPermissionList',
  },
  // 获取权限
  getAuthorityList: {
    url: '/bi-api/api/project/permission/getUserPermissionInfo',
  },
  // 获取权限
  getCrtAuthorityList: {
    url: '/bi-api/api/project/permission/getCurrentPermissionInfo',
  },
  // v2.7 确认项目迁移申请
  confirmTransfer: {
    url: '/bi-api/api/project/joinApply/confirmTransferProject/:id/:status',
    method: 'POST',
  },
  // v2.7 是否有子项目
  hasChildProject: {
    url: '/bi-api/api/project2/hasChildProject',
  },
  // 查询总包或者分包下的班组列表
  getPackProjectGroupsList: {
    url: '/bi-api/api/project2/getPackProjectGroupsList/:projectId',
  },
  // 取消管理员权限
  delManager: {
    url: '/bi-api/api/project/user/del/:projectId/:userId',
    method: 'POST',
  },
  // 确认将我所属项目添加为他们的父项目
  confirmAddParentProject: {
    url:
      '/bi-api/api/project/joinApply/confirmAddParentProject/:applyId/:status',
    method: 'POST',
  },
  // 确认将我所属项目添加为他们的子项目
  confirmAddChildProject: {
    url:
      '/bi-api/api/project/joinApply/confirmAddChildProject/:applyId/:status/:syncPermission',
    method: 'POST',
  },
  // 当前用户所在项目是否已将管理权限授出
  checkProjectPermissionWasGaveOrNo: {
    url: '/bi-api/api/project/org/checkProjectPermissionWasGaveOrNo',
  },
  // 编辑项目
  update: {
    url: '/bi-api/api/project2/simpleUpdate/:id',
    method: 'POST',
  },
  // // 项目出勤数据列表
  // getProjectListByCondition: {
  //   url: '/bi-api/api/project2/listProjectStatistics',
  //   method: 'POST',
  // },
  // v3.0.2 项目出勤数据列表
  getProjectListByCondition: {
    url: '/bi-api/api/project2/v2/listProjectStatistics',
    method: 'POST',
  },
  // 获取用户自定义查询列
  getQuerySetting: {
    url: '/bi-api/api/project2/getUserSearchProjectCriteria',
  },
  // 设置用户自定义查询列
  setQuerySetting: {
    url: '/bi-api/api/project2/setUserSearchProjectCriteria',
    method: 'POST',
  },
  // 异步生成项目出勤数据统计记录表
  exportTotalProjectAttendanceReport: {
    url: '/bi-api/api/project2/v2/exportProjectAttendanceReport',
    method: 'POST',
  },
  // 获取分包项目出勤及工天列表【分包项目出勤榜】通过参建单位
  getListByParticipant: {
    url: '/bi-api/api/project2/listOrgProjectCostAndWorkingDays',
  },
  getListByGroup: {
    url: '/bi-api/api/project2/getGroupCostAndWorkingDays',
  },
  getListByCategory: {
    url: '/bi-api/api/project2/getWorkerCategoryCostAndWorkingDays',
  },
  // 异步生成项目数据表
  exportProjectSummary: {
    url: '/bi-api/api/project2/exportProjectSummary',
  },
  // 获取项目概况数据
  getProjectStaticData: {
    url: '/bi-api/api/project2/v2/getProjectsSummary',
  },
  exportOrg: {
    url: '/bi-api/api/report/export/exportProjectTreeReport',
  },
  getOrgTree: {
    url: '/bi-api/api/v2/project/listProjectOrgAndGroupRelationTreeMap',
  },
  // 4.19 考勤表设置表格预览
  createAttendanceExportBase64: {
    url: '/bi-api/api/report/export/createAttendanceExportBase64',
    method: 'POST',
  },
  // 4.19 考勤表设置表头数据
  getAttendanceContentList: {
    url: '/bi-api/api/v2/project/getAttendanceContentList',
    method: 'POST',
  },
  // 4.19 考勤表设置更新表头
  updateAttendanceContentList: {
    url: '/bi-api/api/v2/project/updateAttendanceContentList',
    method: 'POST',
  },
  // 判断项目是否开通电子合同（开通“电子合同”功能：是指项目开通了电子合同，或同步了“管理权限”的上级项目有开通“电子合同”功能）
  projectOpenContract: {
    url: '/bi-api/api/group/check/project/open/contractFunction',
  },
  listCompanyVisibleTags: {
    url: '/bi-api/api/project/tag/listCompanyVisibleTags',
  },
  // 电子合同首页劳务合同签约数据板展示
  showElectronicLaborDataBoard: {
    url: '/bi-api/api/contract/labor/showElectronicLaborDataBoard',
  },
  // 电子合同首页退场合同签约数据板展示
  showElectronicExitDataBoard: {
    url: '/bi-api/api/contract/exit/showElectronicExitDataBoard',
  },
  // 三级安全教育数据汇总
  getSafeEducationDashBoardInfoOfProject: {
    url:
      '/bi-api/api/education/safety/study/getSafeEducationDashBoardInfoOfProject',
  },
};

export default api;
