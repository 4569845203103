import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { connect } from 'react-redux';

import req from '../../../req';
import config from '../../../config';
import StyledInfoModal from '../InfoModal/StyledInfoModal';

const { applyFormTypeMap } = config.joinCompany;

function InfoModal({ id, visible, onClose, changeMessageCount }) {
  const [info, setInfo] = useState({});

  useEffect(() => {
    if (!visible) {
      setInfo({});
    }
  }, [visible]);

  useEffect(() => {
    let didCancel = false;
    if (!visible || !id) {
      return () => {};
    }
    req.sys
      .getMessageDetails({
        messageId: id,
      })
      .then(res => {
        if (didCancel) {
          return;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        if (
          res.data.projectJoinApplyDTO &&
          res.data.projectJoinApplyDTO.status !== applyFormTypeMap.check &&
          !res.data.readStatus
        ) {
          changeMessageCount();
        }
        setInfo(res.data);
        console.log(res.data);
      })
      .catch(err => {
        if (didCancel) {
          return;
        }
        req.err.show(err);
      });
    return () => {
      didCancel = true;
    };
  }, [id, visible]);

  if (!info.id || !visible) {
    return null;
  }
  return (
    <React.Fragment>
      <Modal
        visible={visible}
        title={info.title || ''}
        footer={null}
        onCancel={onClose}
      >
        <StyledInfoModal>
          <div className="head">{info.content || '-'}</div>
        </StyledInfoModal>
      </Modal>
    </React.Fragment>
  );
}

InfoModal.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  changeMessageCount: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeMessageCount: () => ({ type: 'changeMessageCount' }),
};

export default connect(null, mapDispatchToProps)(InfoModal);
