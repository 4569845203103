import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input } from 'antd';

const { TextArea } = Input;

/**
 * 用户名弹窗
 */
function RejectModal({ onCancel, onOk, visible, form, placeholder, idList }) {
  const { getFieldDecorator, validateFields } = form;
  console.log('666', idList);
  function onSubmit() {
    validateFields((err, val) => {
      if (err) {
        console.log(err);
        return;
      }
      onOk(val.text, idList);
    });
  }
  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      title="拒绝"
      onOk={onSubmit}
      destroyOnClose
      style={{ zIndex: 1100 }}
    >
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
      >
        <Form.Item label="原因：">
          {getFieldDecorator('text', {
            rules: [{ max: 30, message: '长度不能大于30' }],
            initialValue: '',
          })(<TextArea placeholder={placeholder} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
}

RejectModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  idList: PropTypes.array.isRequired,
};

RejectModal.defaultProps = {
  placeholder: '请输入拒绝原因，选填',
};

export default Form.create({ name: 'reject_modal' })(RejectModal);
