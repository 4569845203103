const map = {
  leader: 2,
  agent: 1,
  worker: 0,
  manager: 3,
};

const table = {
  2: '班组长',
  1: '代班',
  0: '工人',
  3: '代班',
};

export default {
  table,
  map,
};
