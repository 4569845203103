import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Divider, Button } from 'antd';
import moment from 'moment';
import SeachBox from './SeachBox';
import UploadResourceModal from './UploadResourceModal';
import req from '../../req';

// const typeMaps = {
//   '00': '视频',
//   '01': 'PPT',
//   '02': 'word',
//   '03': 'PDF',
//   '04': '图片',
// };

const StyledDiv = styled.div`
  margin: 24px;
  padding: 23px 32px;
  border-radius: 2px;
  background-color: #fff;
`;

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      tableLoading: false,
      showUploadResourceModal: false,
      editResourceId: '',
      crtItem: {},
    };
    this.timer = '';
    this.searchOpt = null;
    this.onSearch = this.onSearch.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.editLearingMsg = this.editLearingMsg.bind(this);
    this.deleteLearingMsg = this.deleteLearingMsg.bind(this);
    this.onContentModalClose = this.onContentModalClose.bind(this);
    this.addUploadResourceChange = this.addUploadResourceChange.bind(this);
  }

  componentDidMount() {
    this.listFileCategory();
    this.onSearch({
      searchText: '',
      resourceTypeList: [],
    });
    const { location } = this.props;
    if (location.search === '') {
      return;
    }
    const data = JSON.parse(new URLSearchParams(location.search).get('data'));
    console.log('data', data);
    if (data) {
      this.onSearch(data);
    }
  }

  onSearch(options) {
    console.log('onSearch options', options);
    const that = this;
    this.searchOpt = options;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      that.setState(
        {
          page: 1,
          tableLoading: true,
          resourceTypeList: [],
        },
        () => {
          that.getList(options);
        }
      );
    }, 100);
  }

  /**
   * 表格变动
   */
  onTableChange({ current, pageSize }, filters, { columnKey, order }) {
    console.log(filters, columnKey, order, pageSize, current);
    const that = this;
    const { searchOpt } = this;
    const fileCategoryList = {};
    if (filters.fileCategoryList) {
      Object.keys(filters).forEach(item => {
        fileCategoryList[item] = filters[item].join(',');
      });
    }
    console.log('fileCategoryList', fileCategoryList);
    this.setState(
      {
        page: current,
      },
      () => that.getList(Object.assign(searchOpt, fileCategoryList))
    );
  }

  // const handleTableChange = ({ current, pageSize }, filters, { columnKey, order }) => {
  //   console.log(filters, columnKey, order, pageSize, current);
  //   const filterItem2Str = {};
  //   Object.keys(filters).forEach(
  //     (item) => {
  //       filterItem2Str[item] = filters[item].join(',');
  //     },
  //   );
  //   handleSortChange(columnKey, order || null);
  //   queryRef.current = { ...filterItem2Str, sortType: columnKey, sort: sortMaps[order] };
  //   loadData({
  //     page: current,
  //     size: pageSize,
  //     ...filterItem2Str,
  //     orderType: columnKey,
  //     order: sortMaps[order],
  //   });
  // };

  onContentModalClose() {
    this.setState({
      showUploadResourceModal: false,
    });
    this.onSearch({
      searchText: '',
      resourceTypeList: [],
    });
  }

  getList(searchOpt) {
    console.log('searchOpt', searchOpt);
    const that = this;
    const { pageSize, page } = this.state;
    req.education
      .listResource(
        Object.assign(searchOpt || {}, {
          page,
          size: pageSize,
          // resourceTypeList: [],
        })
      )
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        const { records, total } = res.data;
        that.setState({
          list: records || [],
          total: total || 0,
          tableLoading: false,
        });
      })
      .catch(req.err.show);
  }

  listFileCategory() {
    req.education
      .listFileCategory()
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        console.log('listFileCategory', res, this);
      })
      .catch(req.err.show);
  }

  addUploadResourceChange() {
    console.log('addUploadResourceChange');
    this.setState({
      showUploadResourceModal: true,
      editResourceId: '',
      crtItem: {},
    });
  }

  editLearingMsg(item) {
    console.log('editLearingMsg', item);
    this.setState({
      showUploadResourceModal: true,
      editResourceId: item.id,
      crtItem: item,
    });
  }

  deleteLearingMsg(e) {
    console.log('deleteLearingMsg', e, this);
    // req.err.show('删除资料成功');
    req.education
      .deleteResource({
        videoId: e,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        req.err.show('删除资料成功');
        this.getList();
      })
      .catch(req.err.show);
  }

  render() {
    const {
      list,
      pageSize,
      total,
      page,
      tableLoading,
      showUploadResourceModal,
      editResourceId,
      crtItem,
    } = this.state;
    const rowKey = row => row.id;
    const columns = [
      {
        title: '',
        dataIndex: 'videoCover',
        width: 100,
        render(item) {
          return (
            <div
              style={{
                backgroundImage: `url(${item})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: 58,
                height: 70,
                borderRadius: 4,
              }}
            >
              {' '}
            </div>
          );
        },
      },
      {
        title: '名称',
        align: 'left',
        dataIndex: 'name',
        key: 'name',
        width: 300,
        render(txt) {
          return (
            <div style={{ color: 'rgba(0, 0, 0, 0.65)', width: 300 }}>
              {txt}
            </div>
          );
        },
      },
      {
        title: '类型',
        dataIndex: 'fileCategoryName',
        key: 'fileCategoryList',
        width: 150,
        filters: [
          { text: '视频', value: 'video' },
          { text: 'PPT', value: 'ppt' },
          { text: 'word', value: 'word' },
          { text: 'PDF', value: 'pdf' },
          { text: '图片', value: 'image' },
        ],
        render(txt) {
          return (
            <div style={{ color: 'rgba(0, 0, 0, 0.65)', width: 150 }}>
              {txt}
            </div>
          );
        },
      },
      {
        title: '标签',
        dataIndex: 'educationTypeList',
        width: 220,
        render(arr) {
          return arr.map((r, index) => (
            <Button
              size="small"
              style={{
                backgroundColor: '#f3f3f3',
                marginRight: 12,
                marginBottom: 4,
              }}
              key={index}
            >
              {r.value}
            </Button>
          ));
        },
      },
      {
        title: '适用工种',
        align: 'left',
        dataIndex: 'workerCategoryList',
        key: 'workerCategoryList',
        width: 250,
        render(arr, item) {
          return (
            <div>
              {item.isAllCategory === '01' ? (
                '全部工种'
              ) : (
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.65)',
                    width: 150,
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                  }}
                >
                  {arr.map((ele, index) => (
                    <div key={index} style={{ flexShrink: 0 }}>
                      {index < 3 ? (
                        <span>
                          {ele.value || '-'}
                          {index === arr.length - 1 || index >= 2 ? null : (
                            <span>、</span>
                          )}
                        </span>
                      ) : null}
                    </div>
                  ))}
                  {arr.length > 2 ? <span>...</span> : null}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: '上传人',
        align: 'left',
        dataIndex: 'uploadUserName',
        key: 'uploadUserName',
        width: 150,
        render(txt) {
          return <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{txt}</div>;
        },
      },
      {
        title: '上传时间',
        align: 'left',
        dataIndex: 'createdTime',
        key: 'createdTime',
        width: 180,
        render(txt) {
          return (
            <div style={{ color: 'rgba(0, 0, 0, 0.65)', width: 180 }}>
              {txt ? moment.unix(txt / 1000).format('YYYY-MM-DD HH:mm:ss') : ''}
            </div>
          );
        },
      },
      {
        title: '操作',
        width: 150,
        fixed: 'right',
        render: record => (
          <>
            <a onClick={() => this.editLearingMsg(record)}>编辑</a>
            <Divider type="vertical" />
            <Popconfirm
              title="您确定要删除这个资料吗？"
              onCancel={() => {}}
              onConfirm={() => {
                this.deleteLearingMsg(record.id);
              }}
            >
              <a>删除</a>
            </Popconfirm>
          </>
        ),
      },
    ];

    return (
      <>
        <div
          style={{
            color: '#212732',
            backgroundColor: '#fff',
            lineHeight: '60px',
            paddingLeft: 20,
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          学习资源管理
        </div>
        <StyledDiv>
          <SeachBox
            onSearch={this.onSearch}
            uploadResourceChange={this.addUploadResourceChange}
            loading={tableLoading}
          />
          <Table
            dataSource={list}
            rowKey={rowKey}
            scroll={{
              x: columns
                .map(item => item.width || 0)
                .reduce((pV, nV) => pV + nV),
            }}
            style={{
              color: 'rgba(0, 0, 0, 0.65)',
            }}
            columns={columns}
            onChange={this.onTableChange}
            pagination={{
              current: page,
              total,
              pageSize,
            }}
            loading={tableLoading}
          />
        </StyledDiv>
        <UploadResourceModal
          id={editResourceId}
          crtItem={crtItem}
          onClose={this.onContentModalClose}
          visible={showUploadResourceModal}
        />
      </>
    );
  }
}
Page.propTypes = {
  location: PropTypes.object.isRequired,
};
export default withRouter(Page);
