import React from 'react';
import PropTypes from 'prop-types';
import req from '../../../req';
import defautAvt from '../../../img/default-face.png';

class UserCardSuper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
    };
    this.unmounted = true;
  }

  componentDidMount() {
    this.unmounted = false;
    this.getInfo();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  getInfo() {
    const that = this;
    const { number } = this.props;
    req.sys
      .getManagerInfo({
        number,
      })
      .then(res => {
        if (that.unmounted) {
          return;
        }
        if (res.code === 0) {
          that.setState({
            info: res.data,
          });
        } else {
          req.err.show(res);
        }
      })
      .catch(req.err.show);
  }

  render() {
    const { info } = this.state;
    const { name, userName, img, workCode, mobile } = info || {};

    return (
      <div style={{ padding: '12px 8px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={img || defautAvt}
            alt={name || userName}
            style={{
              display: 'block',
              width: 72,
              height: 72,
              borderRadius: '100%',
            }}
          />
          <div style={{ marginLeft: 24 }}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                color: 'rgba(0, 0, 0, 0.85)',
              }}
            >
              {name || userName}
            </div>
            <div
              style={{
                fontSize: 14,
                marginTop: 4,
                color: 'rgba(0, 0, 0, 0.45)',
              }}
            >{`工号：${workCode}`}</div>
          </div>
        </div>
        <div
          style={{
            marginTop: 24,
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.65)',
          }}
        >
          <div>
            电话
            <span style={{ marginLeft: 16 }}>{mobile || '-'}</span>
          </div>
        </div>
      </div>
    );
  }
}

UserCardSuper.propTypes = {
  number: PropTypes.string.isRequired,
};

export default UserCardSuper;
