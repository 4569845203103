import * as dateUtils from './date';

export const attendTypeMap = {
  twiceADay: '02',
  fourTimesADay: '04',
};

export const ruleTypeMap = {
  FIXED: '00',
  FREE: '01',
};

export const ruleTypeLabelMap = {
  [ruleTypeMap.FIXED]: '固定时间打卡',
  [ruleTypeMap.FREE]: '自由时间打卡',
};

export const clockTypeMap = {
  start: '00', // 开工
  end: '01', // 收工
  disable: '02', // 不可操作
  normal: '03', // 打卡
};

export const signStatusMap = {
  normal: '00', // 正常
  late: '01', // 迟到
  early: '02', // 早退
  miss: '03', // 缺卡
};

/**
 * 考勤类型
 */
export function attendType2str(type) {
  const typeMap = {
    '02': '一天两次',
    '04': '一天四次',
  };

  return typeMap[type];
}

/**
 * 开工时间
 */
export function clockType2str(type) {
  const typeMap = {
    '00': '开工',
    '01': '收工',
    '02': '不可操作',
    '03': '打卡',
  };

  return typeMap[type];
}

/**
 * 打卡状态
 */
export function signStatus2str(status) {
  const statusMap = {
    '00': '正常',
    '01': '迟到',
    '02': '早退',
    '03': '缺卡',
  };

  return statusMap[status];
}

/**
 * 审批状态
 */
export function approveStatus2str(status) {
  const statusMap = {
    '01': '审核中',
    '02': '已拒绝',
    '03': '已同意',
    '04': '已撤销',
  };

  return statusMap[status];
}

/**
 * 审批类型
 */
export function approveType2str(status) {
  const statusMap = {
    '02': '误打卡',
    '01': '补打卡',
  };

  return statusMap[status];
}

/**
 * 错误时间解析
 */
export function missTimeParser(sec) {
  const h = Math.floor(sec / 60 / 60);
  const s = sec % 60;
  const m = (sec - s - h * 60 * 60) / 60 + (s > 0 ? 1 : 0);
  let res = '';

  if (h > 0) {
    res += `${h}小时`;
  }

  if (m > 0) {
    res += `${m}分钟`;
  }

  return res;
}

/**
 * 错误时间分钟解析
 */
export function missMinParser(min) {
  const sec = min * 60;
  const h = Math.floor(sec / 60 / 60);
  const s = sec % 60;
  const m = (sec - s - h * 60 * 60) / 60 + (s > 0 ? 1 : 0);
  let res = '';

  if (h > 0) {
    res += `${h}小时`;
  }

  if (m > 0) {
    res += `${m}分钟`;
  }

  return res;
}

/**
 * 申请审批状态
 */
export function status2apply(status) {
  const statusMap = {
    '00': '误打卡',
    '01': '误打卡',
    '02': '误打卡',
    '03': '补打卡',
  };

  return statusMap[status];
}

/**
 * 工时转换
 */
export function workingTimesParser(min) {
  const sec = min * 60;
  const h = Math.floor(sec / 60 / 60);
  const s = sec % 60;
  const m = (sec - s - h * 60 * 60) / 60 + (s > 0 ? 1 : 0);
  let res = '';

  if (h > 0) {
    res += `${h}小时`;
  }

  if (m >= 0) {
    res += `${m}分钟`;
  }

  return res;
}

export const overTimeSalaryTypeMap = {
  '01': '正常时薪',
  '02': '1.5倍时薪',
  '03': '半个工',
  '04': '1个工',
  '05': '{data}小时算1个工',
};

/**
 * 薪水类型转换文本
 */
export function overTimeSalaryType2str(type, data) {
  if (!type) {
    return '';
  }
  let res = overTimeSalaryTypeMap[type];
  if (typeof data !== 'undefined') {
    res = res.replace('{data}', data);
  }

  return res;
}

/**
 * 考勤规则显示
 */
export function ruleDisplay(info, hideDesc, hideMiddleRes) {
  if (!info || !info.attendanceTimesType) {
    return '';
  }
  if (info.attendanceTimesType === attendTypeMap.twiceADay) {
    let res = `${dateUtils.normalizeTimeStr(
      info.workStartTime,
      true
    )}-${dateUtils.normalizeTimeStr(info.workEndTime, true)}`;
    if (
      !hideMiddleRes &&
      (info.middleRest === true || info.middleRest === '01')
    ) {
      res += `午休${dateUtils.normalizeTimeStr(
        info.middleStartTime,
        true
      )}-${dateUtils.normalizeTimeStr(info.middleEndTime, true)}`;
    }
    if (!hideDesc) {
      res += `(${attendType2str(info.attendanceTimesType)})`;
    }
    return res;
  }
  if (info.attendanceTimesType === attendTypeMap.fourTimesADay) {
    let res = `${dateUtils.normalizeTimeStr(
      info.workStartTime,
      true
    )}-${dateUtils.normalizeTimeStr(
      info.workEndTime,
      true
    )} ${dateUtils.normalizeTimeStr(
      info.workStartTime2,
      true
    )} ${dateUtils.normalizeTimeStr(info.workEndTime2, true)}`;
    if (!hideDesc) {
      res += `(${attendType2str(info.attendanceTimesType)})`;
    }
    return res;
  }
  return '';
}

/**
 * 加班规则显示
 */
export function otRuleDisplay(info, hideContinue) {
  if (!info) {
    return '';
  }
  if (info.attendanceType === ruleTypeMap.FREE) {
    const res = `超出${info.shortestOtTime || 0}分钟开始算加班`;
    return res;
  }
  if (info.attendanceType === ruleTypeMap.FIXED) {
    let res = `${dateUtils.normalizeTimeStr(
      info.otStartTime,
      true
    )}-${dateUtils.normalizeTimeStr(info.otEndTime, true)}`;
    if (!hideContinue && info.continuation === '01') {
      res += '(便捷打卡)';
    }
    return res;
  }
  return '';
}
