import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import overDuring from '../../../img/mark-overdue@3x.png';

const { Search } = Input;

const StyledBox = styled.div`
  .item {
    margin: 20px 0;
    .avt {
      width: 45px;
      height: 45px;
      border-radius: 24px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .content {
      margin-left: 10px;
      .name {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        .mark {
          width: 28px;
          height: 15px;
          margin-top: -1px;
          margin-left: 6px;
        }
      }
      .desc {
        margin-top: 4px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
`;

function dataFormmat(str) {
  const year = str.slice(0, 4);
  const month = str.slice(4, 6);
  const day = str.slice(6);
  return [year, month, day];
}

function data2str(str, separator = '.') {
  return dataFormmat(str).join(separator);
}

const cacheSuccListStoreKey = 'uploadSuccList';
const cacheErrListStoreKey = 'uploadErrList';

function Page({ visible, toggleShowFn, succCount, errCount }) {
  const [list, setList] = useState([]);
  const listRef = useRef([]);
  const titleDom = (
    <div
      style={{ fontSize: 16, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.85)' }}
    >
      成功录入{succCount}张身份证，
      <span style={{ color: '#f52c36' }}>其中{errCount}张身份证过期</span>
    </div>
  );
  const onSearchChange = useCallback(val => {
    if (!listRef.current.length) {
      return [];
    }
    const regex = new RegExp(val, 'i');
    return setList(listRef.current.filter(item => regex.test(item.realName)));
  });
  useEffect(() => {
    const cachSuccList =
      JSON.parse(sessionStorage.getItem(cacheSuccListStoreKey)) || [];
    const cachErrList =
      JSON.parse(sessionStorage.getItem(cacheErrListStoreKey)) || [];
    if (cachSuccList.length || cachErrList.length) {
      const concatList = [...cachErrList, ...cachSuccList];
      setList(concatList);
      listRef.current = concatList;
    }
  }, [succCount, errCount]);

  return (
    <Modal
      title={titleDom}
      visible={visible}
      onOk={() => toggleShowFn()}
      onCancel={() => toggleShowFn()}
      footer={null}
    >
      <StyledBox className="main">
        <Search
          placeholder="请输入名字搜索"
          onSearch={e => onSearchChange(e)}
        />
        {list.map(item => (
          <div className="item" data-flex="main: center" key={item.idCard}>
            <img
              className="avt"
              src={item.electronicPhoto}
              data-flex-box="0"
              alt="avt"
            />
            <div className="content" data-flex-box="1">
              <div className="name">
                {item.realName}
                {item.isInDuring ? (
                  <img src={overDuring} alt="icon" className="mark" />
                ) : (
                  ''
                )}
              </div>
              <div className="desc">{item.idCard}</div>
              <div className="desc">
                {item.nationality}族 | {item.sex} |
                <span style={{ marginLeft: 4 }}>
                  {data2str(item.effectiveDate)} — {data2str(item.expiryDate)}
                </span>
              </div>
              <div className="desc">{item.address}</div>
            </div>
          </div>
        ))}
      </StyledBox>
    </Modal>
  );
}
Page.propTypes = {
  visible: PropTypes.bool.isRequired,
  succCount: PropTypes.number.isRequired,
  errCount: PropTypes.number.isRequired,
  toggleShowFn: PropTypes.func.isRequired,
};
export default Page;
