import React, { useState, useCallback, useEffect } from 'react';
import { useDidRecover } from 'react-router-cache-route';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import req from '../../../req';
import PieProgressChart from '../PieProgressChart/index';
import complaintSrc from '../../../img/ic_62_complaint@3x.png';
import completeSrc from '../../../img/ic_62_complete@3x.png';
import untreatedSrc from '../../../img/ic_62_untreated@3x.png';
import untreatedingSrc from '../../../img/ic_62_untreateding@3x.png';

const StyledDiv = styled.div`
  margin: 0 24px;
  background-color: #fff;
  padding: 24px;
  .title {
    display: flex;
    align-items: center;
    .t {
      font-weight: 500;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 5px;
    }
  }
  .data {
    display: flex;
    margin-top: 32px;
    .item {
      padding: 24px;
      border-radius: 16px;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      margin-left: 32px;
      .info {
        margin-left: 16px;
        .tip {
          font-weight: 400;
          font-size: 14px;
          color: #909399;
          line-height: 22px;
          padding-top: 2px;
        }
        .num {
          margin-top: 4px;
          font-weight: 500;
          font-size: 24px;
          color: #303133;
          line-height: 32px;
        }
      }
      > img {
        width: 62px;
        height: 62px;
      }
      &:nth-of-type(2) {
        background-color: rgba(26, 114, 255, 0.13);
        margin-left: 50px;
      }
      &:nth-of-type(3) {
        background-color: rgba(255, 140, 46, 0.12);
      }
      &:nth-of-type(4) {
        background-color: rgba(0, 200, 155, 0.12);
      }
    }
    &.project .item {
      &:nth-of-type(3) {
        background-color: rgba(184, 123, 255, 0.12);
      }
      &:nth-of-type(4) {
        background-color: rgba(255, 140, 46, 0.12);
      }
      &:nth-of-type(5) {
        background-color: rgba(0, 200, 155, 0.12);
      }
    }
  }
`;
export default function Data({ companyId, projectId, now }) {
  const [info, setInfo] = useState({});
  const getInfo = useCallback(async () => {
    const requestApi = projectId
      ? req.complaints.getProjectWorkerComplaintStatistical
      : req.complaints.getCompanyWorkerComplaintStatistical;
    const result = await requestApi({ projectId, companyId });
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    setInfo(result.data || {});
    console.log(result, '数据统计');
  }, [companyId, projectId]);

  useEffect(() => {
    if (projectId || companyId) {
      getInfo();
    }
  }, [projectId, companyId, now]);
  useDidRecover(() => {
    getInfo();
  });
  return (
    <StyledDiv>
      <div className="title">
        <div className="t">数据说明</div>
        <Tooltip
          title={
            <>
              <div>处理率：已处理/累计投诉*100%</div>
              <div>累计投诉：当前级的累计投诉数量，包含已撤销的数据</div>
              <div>未处理：当前级未处理状态的投诉数量</div>
              <div>已处理：已处理的单子数量</div>
              {projectId ? <div>上级处理中：上级处理中单子数量</div> : ''}
            </>
          }
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      <div className={projectId ? 'project data' : 'data'}>
        <PieProgressChart
          signRate={
            info.processingRate ? Math.ceil(info.processingRate * 100) : 0
          }
        />
        <div className="item">
          <img src={complaintSrc} alt="" />
          <div className="info">
            <div className="tip">累计投诉</div>
            <div className="num">{info.totalCount || 0}</div>
          </div>
        </div>
        {projectId ? (
          <div className="item">
            <img src={untreatedingSrc} alt="" />
            <div className="info">
              <div className="tip">上级处理中</div>
              <div className="num">{info.parentProcessingCount || 0}</div>
            </div>
          </div>
        ) : null}
        <div className="item">
          <img src={untreatedSrc} alt="" />
          <div className="info">
            <div className="tip">未处理</div>
            <div className="num">{info.notProcessCount || 0}</div>
          </div>
        </div>
        <div className="item">
          <img src={completeSrc} alt="" />
          <div className="info">
            <div className="tip">已处理</div>
            <div className="num">{info.processedCount || 0}</div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
}

Data.propTypes = {
  companyId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  now: PropTypes.number.isRequired,
};
