function caculateUnReadMessageCount(unReadMessageCount = 0, action) {
  switch (action.type) {
    case 'assignMessageCount':
      return action.unReadMessageCount;
    default:
      return unReadMessageCount;
  }
}
function caculateBlacklistCount(blacklistCount = 0, action) {
  switch (action.type) {
    case 'assignBlacklistCount':
      return action.blacklistCount;
    default:
      return blacklistCount;
  }
}
const reducer = (
  state = { unReadMessageCount: 0, blacklistCount: 0 },
  action
) => ({
  ...state,
  unReadMessageCount: caculateUnReadMessageCount(
    state.unReadMessageCount,
    action
  ),
  blacklistCount: caculateBlacklistCount(state.blacklistCount, action),
});

export default reducer;
