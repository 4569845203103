import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, message } from 'antd';

import req from '../../../req/index';
import UserInfo from './UserInfo';
import CompanyInfo from './CompanyInfo';

const StyledHeader = styled(Layout.Header)`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }
`;

const StyledExitTrailBtn = styled.div`
  border-radius: 4px;
  border: solid 1px #f57423;
  padding: 0 15px;
  color: #f57423;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  margin-right: 40px;
`;

/**
 * 切换当前公司
 * @param {string} id 公司id
 */
function onSwicthCompany(id) {
  req.company
    .switchCrt({
      companyId: id,
    })
    .then(res => {
      if (res.code === 0) {
        message.loading('切换公司成功，即将刷新页面...', 1.5).then(() => {
          window.location.href = '/';
        });
      } else {
        req.err.show(res);
      }
    })
    .catch(req.err.show);
}

/**
 * 退出体验
 */
function exitTrail() {
  return req.user
    .exitTrail()
    .then(res => {
      if (res.code !== 0) {
        req.err.show(res);
        return;
      }
      message.loading('退出体验成功，即将刷新页面...', 1.5).then(() => {
        window.location.href = '/';
      });
    })
    .catch(req.err.show);
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crtCompany: {},
      myInfo: {},
    };
    this.onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    const that = this;
    Promise.all([this.getMyCrtCompany(), this.getMyInfo()]).then(([r1, r2]) => {
      if (!r1 || !r2 || r1.code !== 0 || r2.code !== 0) {
        return;
      }
      that.setState({
        crtCompany: r1.data || {},
        myInfo: r2.data,
      });
    });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onLogout() {
    const { history } = this.props;
    req.sys.logout();
    history.replace('/login');
  }

  /**
   * 获取当前公司
   */
  getMyCrtCompany() {
    const that = this;
    return req.company
      .getMyCrt()
      .then(res => {
        if (that.isUnmounted) {
          return null;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return null;
        }
        return res;
      })
      .catch(req.err.show);
  }

  /**
   * 获取当前个人信息
   */
  getMyInfo() {
    const that = this;
    return req
      .cachify('user.getMyInfo')()
      .then(res => {
        if (that.isUnmounted) {
          return null;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return null;
        }
        return res;
      })
      .catch(req.err.show);
  }

  render() {
    const { collapsed, toggle } = this.props;
    const { myInfo, crtCompany } = this.state;
    return (
      <StyledHeader
        data-flex="main:left cross:center"
        style={{
          background: '#fff',
          padding: 0,
          boxShadow: '0 1px 4px 0 rgba(0, 21, 41, 0.12)',
          position: 'relative',
        }}
      >
        <LegacyIcon
          className="trigger"
          type={collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={toggle}
        />
        <CompanyInfo
          onChange={onSwicthCompany}
          info={crtCompany}
          top={myInfo.currentTopCompany}
          topRoles={myInfo.topCompanyRoles}
        />
        <div data-flex-box="1" />
        {crtCompany && crtCompany.experience === '01' ? (
          <StyledExitTrailBtn onClick={exitTrail}>
            退出体验模式
          </StyledExitTrailBtn>
        ) : null}
        <UserInfo info={myInfo} logout={this.onLogout} />
      </StyledHeader>
    );
  }
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default withRouter(Header);
