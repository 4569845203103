import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDidRecover } from 'react-router-cache-route';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  Select,
  DatePicker,
  Input,
  Checkbox,
  Button,
  Table,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import HandlerModal from '../HandlerModal/index';
import req from '../../../req';

const StyledDiv = styled.div`
  padding: 24px;
  background-color: #fff;
  margin: 24px;
`;

const EllipsisDiv = styled.div`
  display: -webkit-box; /* 必须设置此项才能生效 */
  -webkit-line-clamp: 2; /* 限制最大行数 */
  -webkit-box-orient: vertical; /* 垂直布局 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
`;

const typesLabel = {
  0: '欠薪',
  1: '工伤',
  2: '合同纠纷',
};

const typesList = [
  {
    value: 0,
    label: '欠薪',
  },
  {
    value: 1,
    label: '工伤',
  },
  {
    value: 2,
    label: '合同纠纷',
  },
];

const processStatusMap = {
  0: '未处理',
  1: '已处理',
  2: '已撤诉',
  3: '上级处理中',
};
const processStatusColorMap = {
  0: '#FF9033',
  1: '#00C89B',
  2: 'rgba(0, 0, 0, 0.45)',
  3: '#1A72FF',
};
const processStatusList = [
  {
    value: 10,
    label: '全部',
  },
  {
    value: 3,
    label: '上级处理中',
  },
  {
    value: 0,
    label: '未处理',
  },
  {
    value: 1,
    label: '已处理',
  },
  {
    value: 2,
    label: '已撤诉',
  },
];
const { RangePicker } = DatePicker;

// 处理浏览器直接预览pdf文件、以及文件名问题
// function downloadFile(blob, filename) {
//   const url = window.URL.createObjectURL(blob);
//   // 创建隐藏的可下载链接
//   const link = document.createElement('a');
//   link.style.display = 'none';
//   link.href = url;
//   link.setAttribute('download', filename);
//   document.body.appendChild(link);
//   link.click();
// }
function formatUpwardFeedback(upwardFeedback) {
  if (!upwardFeedback || upwardFeedback.length === 2) {
    return null;
  }
  return upwardFeedback[0];
}
const disabledDate = current => {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
};

export default function List({
  companyId,
  projectId,
  canEditComplaint,
  updateData,
}) {
  console.log('updateData', updateData);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const complaintId = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const pagination = {
    current: page,
    pageSize: 10,
    total: totalCount,
    onChange(pageSize) {
      setPage(pageSize);
    },
  };
  const columns = [
    {
      title: '姓名',
      dataIndex: 'worker',
      key: 'worker',
      fixed: 'left',
      width: 100,
      render(value) {
        return value.name;
      },
    },
    {
      title: '手机号码',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      width: 150,
      render(value) {
        return value || '-';
      },
    },
    {
      title: '投诉类型',
      dataIndex: 'type',
      key: 'type',
      width: 130,
      render(value) {
        return typesLabel[value];
      },
    },
    {
      title: '所属公司',
      dataIndex: 'contractor',
      key: 'contractor',
      width: 200,
      render(value) {
        return value && value.name ? value.name : '-';
      },
    },
    {
      title: '所属项目',
      dataIndex: 'project',
      key: 'project',
      width: 200,
      render(value) {
        return value && value.name ? value.name : '-';
      },
    },
    {
      title: '所属班组',
      dataIndex: 'group',
      key: 'group',
      width: 200,
      render(value) {
        return value && value.name ? value.name : '-';
      },
    },
    {
      title: '问题描述',
      dataIndex: 'description',
      key: 'description',
      width: 240,
      render(value) {
        return (
          <Tooltip title={value}>
            <EllipsisDiv>{value}</EllipsisDiv>
          </Tooltip>
        );
      },
    },
    {
      title: '涉及总金额（元）',
      dataIndex: 'involvedAmount',
      key: 'involvedAmount',
      width: 160,
      render(value) {
        return value || '-';
      },
    },
    {
      title: '涉及人数',
      dataIndex: 'involvedPersonCount',
      key: 'involvedPersonCount',
      width: 100,
      render(value) {
        return value || '-';
      },
    },
    {
      title: '投诉时间',
      dataIndex: 'complaintTime',
      key: 'complaintTime',
      width: 160,
    },
    {
      title: '处理状态',
      dataIndex: 'processStatus',
      key: 'processStatus',
      width: 110,
      render(value) {
        return (
          <span
            style={{
              color: processStatusColorMap[value],
            }}
          >
            {processStatusMap[value]}
          </span>
        );
      },
    },
    {
      title: '是否向上反馈',
      dataIndex: 'upwardFeedback',
      key: 'upwardFeedback',
      width: 120,
      render(value) {
        return value ? '是' : '否';
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right',
      width: 180,
      render(value, record) {
        console.log(record);
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              type="link"
              onClick={() => {
                console.log(form.getFieldsValue(), 'data');
              }}
            >
              <Link
                to={`/worker_complaints/detail/${record.id}?${
                  projectId ? 'projectId' : 'companyId'
                }=${projectId || companyId}`}
              >
                查看详情
              </Link>
            </Button>
            {record.processStatus === 0 && canEditComplaint ? (
              <Button
                type="link"
                onClick={() => {
                  complaintId.current = record.id;
                  setVisible(true);
                }}
              >
                处理投诉
              </Button>
            ) : null}
          </div>
        );
      },
    },
  ];
  // /**
  //  * 获取权限
  //  */
  // const getAuthority = useCallback(() => {
  //   return req.authority
  //     .getMap()
  //     .then(res => {
  //       if (res.code !== 0) {
  //         return null;
  //       }
  //       console.log('权限', res);
  //       return res;
  //     })
  //     .catch(req.err.show);
  // }, []);
  // const handleDownload = useCallback((url, fileName) => {
  //   fetch(url)
  //     // record.doc_url为文件url地址
  //     .then(res => res.blob())
  //     .then(blob => {
  //       downloadFile(blob, fileName);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       message.error('下载失败');
  //     });
  // }, []);
  const getList = useCallback(async () => {
    console.log(form.getFieldsValue(), '表单数据');
    const {
      date,
      searchText,
      status,
      types,
      upwardFeedback,
      waitMeHandle,
    } = form.getFieldsValue();
    const requestApi = projectId
      ? req.complaints.listComplaintsInProject
      : req.complaints.listComplaintsInCompany;
    console.log('upwardFeedback', formatUpwardFeedback(upwardFeedback));
    setLoading(true);
    const result = await requestApi(
      JSON.stringify({
        companyId: projectId ? null : companyId,
        projectId,
        endDate: date && date[1] ? date[1].format('YYYY-MM-DD') : null,
        handleStatuss: (status || []).includes(10) ? null : status,
        includeChildren: true,
        page: pagination.current,
        searchText: searchText || '',
        size: pagination.pageSize,
        startDate: date && date[0] ? date[0].format('YYYY-MM-DD') : null,
        types: types || null,
        upwardFeedback: formatUpwardFeedback(upwardFeedback),
        waitMeHandle: !!(waitMeHandle && waitMeHandle[0] === 1),
      })
    );
    setLoading(false);
    console.log(result, '列表');
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    const { list, total } = result.data;
    setTotalCount(total);
    setData(list);
  }, [companyId, projectId, page]);
  const getFileStatus = useCallback(fileKey => {
    req.sys
      .checkExportReport({
        fileKey,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        console.log('生成文件', res);
        if (!res.data) {
          return;
        }
        const a = document.createElement('a');
        switch (res.data.status) {
          case '00':
            console.log('生成中', res);
            setTimeout(() => {
              getFileStatus(fileKey);
            }, 2000);
            break;
          case '01':
            // that.setState({
            //   loading: false,
            //   file: res.data.file,
            // });
            a.href = res.data.file.url;
            a.click();
            // handleDownload(res.data.file.url, res.data.file.fileName);
            console.log('生成成功', res);
            break;
          case '02':
            req.err.show(res.data.failReason);
            break;
          default:
            break;
        }
      })
      .catch(err => {
        req.err.show(err);
      });
  }, []);
  const handleExport = useCallback(async () => {
    const {
      date,
      searchText,
      status,
      types,
      upwardFeedback,
      waitMeHandle,
    } = form.getFieldsValue();
    console.log((status || []).includes(10) ? null : status, 'status');
    const result = await req.complaints.exportWorkerComplaintList(
      JSON.stringify({
        companyId: projectId ? null : companyId,
        projectId,
        endDate: date && date[1] ? date[1].format('YYYY-MM-DD') : null,
        handleStatuss: (status || []).includes(10) ? null : status,
        includeChildren: true,
        page: pagination.current,
        searchText: searchText || '',
        size: pagination.pageSize,
        startDate: date && date[0] ? date[0].format('YYYY-MM-DD') : null,
        types: types || null,
        upwardFeedback: formatUpwardFeedback(upwardFeedback),
        waitMeHandle: !!(waitMeHandle && waitMeHandle[0] === 1),
      })
    );
    console.log(result, '导出');
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    getFileStatus(result.data.fileKey);
    console.log(result, '导出');
  }, [companyId, projectId, page]);
  const onValuesChange = useCallback((changedValues, allValues) => {
    console.log('---onValuesChange---', changedValues, allValues, form);
    const { status = [] } = allValues;
    let list = [...status];
    if (status.includes(10) && status.length > 1 && status[0] === 10) {
      const index = list.findIndex(item => item === 10);
      list.splice(index, 1);
    } else {
      list = status.includes(10) ? [10] : status;
    }
    form.setFieldsValue({ status: list });
  }, []);
  useEffect(() => {
    if (projectId || companyId) {
      getList();
    }
    console.log(form, history);
  }, [companyId, projectId, page]);

  useEffect(() => {
    // getAuthority();
  }, []);
  useDidRecover(() => {
    getList();
  });
  return (
    <StyledDiv>
      <Form form={form} onValuesChange={onValuesChange}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="types" label="投诉类型">
              <Select
                placeholder="请选择"
                options={typesList}
                mode="multiple"
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="status" label="处理状态">
              <Select
                placeholder="请选择"
                options={processStatusList}
                mode="multiple"
                maxTagCount={1}
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="date" label="投诉时间">
              <RangePicker
                disabledDate={disabledDate}
                onCalendarChange={e => {
                  form.setFieldsValue({
                    date: e,
                  });
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="searchText" label="搜索">
              <Input
                suffix={<SearchOutlined />}
                placeholder="请输入姓名、手机号、工号进行搜索"
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item name="upwardFeedback" label="是否向上反馈">
              <Select
                placeholder="请选择"
                mode="multiple"
                showArrow
                options={[
                  {
                    value: 1,
                    label: '是',
                  },
                  {
                    value: 0,
                    label: '否',
                  },
                ]}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item name="waitMeHandle" label="处理投诉">
              {/* <Radio.Group>
                <Radio value={1}>待我处理</Radio>
              </Radio.Group> */}
              <Checkbox.Group>
                <Checkbox value={1}>待我处理</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ span: 16 }}>
          <Button
            type="primary"
            onClick={() => {
              // console.log(form.getFieldsValue(), '表单数据');
              setPage(1);
              getList();
            }}
          >
            查询
          </Button>
          <Button
            type="primary"
            style={{
              backgroundColor: '#00C89B',
              borderColor: '#00C89B',
              marginLeft: 16,
            }}
            onClick={() => {
              handleExport();
            }}
          >
            下载
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={pagination}
        scroll={{ x: 1700 }}
        rowKey="id"
      />
      <HandlerModal
        visible={visible}
        setVisible={setVisible}
        complaintId={complaintId.current || ''}
        success={() => {
          getList();
          console.log(updateData, '更新');
          if (updateData) {
            updateData();
          }
        }}
      />
    </StyledDiv>
  );
}
List.propTypes = {
  companyId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  canEditComplaint: PropTypes.bool.isRequired,
  updateData: PropTypes.func.isRequired,
};
