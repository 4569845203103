import loadable from '@loadable/component';

const Attend = loadable(() => import('./Attend/index'));
const Salary = loadable(() => import('./Salary/index'));
const Roster = loadable(() => import('./Roster/index'));
const Org = loadable(() => import('./Org/index'));
const AttendExport = loadable(() => import('./Attend/Export/index'));
const SalaryExport = loadable(() => import('./Salary/Export/index'));
const RosterExport = loadable(() => import('./Roster/Export/index'));
const OrgExport = loadable(() => import('./Org/Export/index'));

export default {
  Attend,
  Salary,
  Roster,
  Org,
  AttendExport,
  SalaryExport,
  RosterExport,
  OrgExport,
};
