const api = {
  getMyCompanies: {
    url: '/bi-api/api/company/baseInfos',
  },
  getMyCrt: {
    url: '/bi-api/api/company/getCurrentCompanyBaseInfo',
  },
  switchCrt: {
    url: '/bi-api/api/company/switchCompany/:companyId',
  },
  getApplyInfo: {
    url: '/bi-api/api/company/applyCompanyRecord',
  },
  apply: {
    url: '/bi-api/api/company/registerApplyCompanyInfo',
    method: 'POST',
  },
  updateApplyInfo: {
    url: '/bi-api/api/company/modifyApplyCompanyInfo',
  },
  getDataByKey: {
    url: '/bi-api/api/company/dayCount/:key',
  },
  getProjectDistributions: {
    url: '/bi-api/api/company/listProjectDistributions',
  },
  getAvgSalaryByCompany: {
    url: '/bi-api/api/company/listCategoryAvgSalaryByCompany',
  },
  getWorkerAvgSalaryByCompanyData: {
    url: '/bi-api/api/company/listWorkerAvgSalaryByCompany',
  },
  getPresentGroupLeaderCounts: {
    url: '/bi-api/api/company/getPresentGroupLeaderCounts',
  },
  getPresentWorker: {
    url: '/bi-api/api/company/getPresentWorker',
  },
  getWorkerAttendanceDetails: {
    url: '/bi-api/api/company/getWorkerAttendanceDetails',
  },
  getMultiDayAttendanceDetails: {
    url: '/bi-api/api/company/v2/getMultiDayAttendanceDetails',
  },
  listCompanyTrend: {
    url: '/bi-api/api/company/attendance/listCompanyTrend',
  },
  listCompanyPeriodTrend: {
    url: '/bi-api/api/company/attendance/listCompanyPeriodTrend',
  },
  listBuildingProjectDistribution: {
    url: '/bi-api/api/company/listBuildingProjectDistribution/:key',
  },
  listAttendanceProjectDistribution: {
    url: '/bi-api/api/company/listAttendanceProjectDistribution/:key',
  },
  getCompanySummary: {
    url: '/bi-api/api/company/getCompanySummary',
  },
  listProjectCosts: {
    url: '/bi-api/api/company/listProjectCosts',
  },
  getDashboard: {
    url: '/bi-api/api/company/getCompanyDashboard',
  },
  // 获取公司面包屑导航
  getNavList: {
    url: '/bi-api/api/company/getCompanyCrumbs',
  },
  // 切换公司
  switchCompany: {
    url: '/bi-api/api/company/switchCompany',
  },
  getCrtOrg: {
    url: '/bi-api/api/company/getCompanyOrg',
  },
  search: {
    url: '/bi-api/api/company/searchCompany',
  },
  getInfo: {
    url: '/bi-api/api/company/baseInfo/:id',
  },
  appendCompany: {
    url: '/bi-api/api/company/addChildCompany/:parentCompanyId/:childCompanyId',
    method: 'POST',
  },
  createAppendCompany: {
    url: '/bi-api/api/company/createChildCompany',
    method: 'POST',
  },
  joinCompany: {
    url: '/bi-api/api/company/joinParentCompany/:parentCompanyId',
    method: 'POST',
  },
  checkAppendCompanyAuthority: {
    url: '/bi-api/api/company/hasAddChildPermission',
  },
  checkJoinCompanyAuthority: {
    url: '/bi-api/api/company/hasAddParentPermission',
  },
  removeCompany: {
    url: '/bi-api/api/company/removeChildCompany/:childCompanyId',
    method: 'POST',
  },
  getOrgInfo: {
    url: '/bi-api/api/company/getCompanyOrgRole',
  },
  // 获取当前公司下管理员信息
  getManagers: {
    url: '/bi-api/api/company/getCompanyRoleUsers',
  },
  // 获取当前公司下管理员信息
  getManagersList: {
    url: '/bi-api/api/company/user/list',
  },
  // 获取公司成员详情
  getCompanyUser: {
    url: '/bi-api/api/company/user/getCompanyUser',
  },
  // 获取公司成员详情
  editCompany: {
    url: '/bi-api/api/company/edit/:id',
    method: 'POST',
  },
  addCompanyManager: {
    url: '/bi-api/api/company/createUserRole',
    method: 'POST',
  },
  editCompanyManagerRemark: {
    url: '/bi-api/api/company/setCompanyUserNoteName',
    method: 'POST',
  },
  delCompanyManager: {
    url: '/bi-api/api/company/delCompanyUser',
    method: 'POST',
  },
  // getManagerProjects: {
  //   url: '/bi-api/api/project2/listManageableProject',
  // },
  getManagerProjects: {
    url: '/bi-api/api/company/user/listManageableProject',
  },
  exitCompany: {
    url: '/bi-api/api/company/exitParentCompany/:parentCompanyId',
    method: 'POST',
  },
  getOrgOverviewData: {
    url: '/bi-api/api/company/v2/getCompanyOrgDataSummary',
  },
  getCompanyOverviewData: {
    url: '/bi-api/api/company/getProjectDataSummary',
  },
  searchCompanyProjects: {
    url: '/bi-api/api/project2/v2/searchProjectBases',
  },
  getCompanyRole: {
    url: '/bi-api/api/sys/getCompanyRole',
  },
  // 审批添加或拒绝添加黑名单
  approveAddBlacklist: {
    url: '/bi-api/api/worker/blackMember/approveAddBlacklist',
    method: 'POST',
  },
  // 获取待审批黑名单申请数量
  // getProcessingBlackMemberApplyCounts: {
  //   url: '/bi-api/api/worker/blackMember/getProcessingBlackMemberApplyCounts',
  // },

  // 获取待审批黑名单申请数量
  getProcessingBlackMemberApplyCounts: {
    url:
      '/bi-api/api/worker/blackMember/v2/getProcessingBlackMemberApplyCounts',
  },
  //  公司黑名单列表
  listBlackMembers: {
    url: '/bi-api/api/worker/blackMember/listBlackMembers',
  },
  //  黑名单申请详情
  getBlackMemberApplyDetails: {
    url: '/bi-api/api/worker/blackMember/getBlackMemberApplyDetails',
  },
  // POST  删除黑名单
  delBlackMember: {
    url: '/bi-api/api/worker/blackMember/delBlackMember/:id',
    method: 'POST',
  },
  searchBlacklist: {
    url: '/bi-api/api/worker/blackMember/searchWorkers',
  },
  getAuditors: {
    url: '/bi-api/api/worker/blackMember/listApprovers',
  },
  importBlacklist: {
    url: '/bi-api/api/worker/blackMember/importBlackMembers',
    method: 'POST',
  },
  getBlacklistCandidateAuditors: {
    url: '/bi-api/api/sys/listSuperAdminAndGeneralAdmin',
  },
  setupBlacklistFlow: {
    url: '/bi-api/api/worker/blackMember/setBlacklistUploadProcess',
    method: 'POST',
  },
  // 是否有上报黑名单权限
  hasReportPermission: {
    url: '/bi-api/api/worker/blackMember/hasReportPermission',
  },
  // 是否有审批权限
  hasApprovePermission: {
    url: '/bi-api/api/worker/blackMember/hasApprovePermission',
  },
  getBlacklistFlow: {
    url: '/bi-api/api/worker/blackMember/getBlacklistSetting',
  },
  getProjectsAttendData: {
    url: '/bi-api/api/project2/listProjectAttendanceStatistics',
  },
  getAttendCompanies: {
    url: '/bi-api/api/company/listOrgCompanys',
  },
  // 获取当前用户在公司的权限信息
  getCurrentPermissionInfo: {
    url: '/bi-api/api/company/getCurrentPermissionInfo',
  },
  // 获取权限
  getAuthorityList: {
    url: '/bi-api/api/company/permission/getPermissionInfo',
  },
  // 获取权限
  getCrtAuthorityList: {
    url: '/bi-api/api/company/permission/getCurrentPermissionInfo',
  },
  // 删除公司成员
  delMember: {
    url: '/bi-api/api/company/user/del/:userId',
    method: 'POST',
  },
  // 添加公司成员
  addMember: {
    url: '/bi-api/api/company/user/add',
    method: 'POST',
  },
  // 设置或取消超管
  setSuperAdmin: {
    url: '/bi-api/api/company/user/setSuperAdmin',
    method: 'POST',
  },
  // 设置公司成员权限
  addPermissions: {
    url: '/bi-api/api/company/user/addPermissions',
    method: 'POST',
  },
  // 编辑公司成员(弹窗功能)
  editAndPopUps: {
    url: '/bi-api/api/company/user/editAndPopUps/:editCodeOrNo',
    method: 'POST',
  },
  // 取消公司成员权限
  delPermissions: {
    url: '/bi-api/api/company/user/delPermissions',
    method: 'POST',
  },
  // 获取职位列表
  getPositionList: {
    url: '/bi-api/api/company/position/list',
  },
  // 可管理查看的项目列表
  listManageProjects: {
    url: '/bi-api/api/company/user/listManageProjects',
  },
  // 搜索成员
  getSearchManageList: {
    url: '/bi-api/api/company/user/search',
    method: 'POST',
  },
  // 添加职位
  addPosition: {
    url: '/bi-api/api/company/position/add',
    method: 'POST',
  },
  exportCompanySummary: {
    url: '/bi-api/api/company/exportCompanySummary',
  },
  // v3.0.2 查询必须默认设置的权限code集合
  listSystemSpecifyDefaultPermissions: {
    url: '/bi-api/api/company/listSystemSpecifyDefaultPermissions',
  },
  // v3.0.2 加入上级公司
  joinParentCompany: {
    url: '/bi-api/api/company/v2/joinParentCompany',
    method: 'POST',
  },
  // v3.0.2 加入子公司
  addChildCompany: {
    url:
      '/bi-api/api/company/v2/addChildCompany/:parentCompanyId/:childCompanyId',
    method: 'POST',
  },
  // v3.0.2  检查权限是否可同步给上级
  checkPermissionCouldOpenOrNo: {
    url: '/bi-api/api/company/permission/checkPermissionCouldOpenOrNo',
  },
  // v3.0.2  PC端查询批量黑名单审核列表
  listApplyBlackMemberItems: {
    url: '/bi-api/api/worker/blackMember/listBatchApplyBlackMemberItems',
  },
  // v3.0.2  黑名单申请列表
  listBlackMemberApplys: {
    url: '/bi-api/api/worker/blackMember/v2/listBlackMemberApplys',
  },
  // v3.0.2 批量同意/拒绝黑名单审核列表
  batchApproveBlackItems: {
    url: '/bi-api/api/worker/blackMember/batchApproveBlackItems',
    method: 'POST',
  },
  // v3.0.2 设置同步权限code值给到上级公司
  addOrCancelCompanySyncPermission: {
    url: '/bi-api/api/company/permission/addOrCancelCompanySyncPermission',
    method: 'POST',
  },
  // v3.0 获取当前公司同步给上级公司的权限列表（包含上级公司名）
  listCompanySyncPermission: {
    url: '/bi-api/api/company/permission/listCompanySyncPermission',
  },
  // v3.0.2 是否展示公司数据总览
  showCompanyOrgDataSummaryOrNo: {
    url: '/bi-api/api/company/showCompanyOrgDataSummaryOrNo',
  },
  // v3.0.2 获取当前公司数据汇总
  getCompanyOrgDataSummary: {
    url: '/bi-api/api/company/v2/getCompanyOrgDataSummary',
  },
  // v3.0.2 获取公司项目列表(有对应权限的)
  getProjects: {
    url: '/bi-api/api/project2/v2/getCompanyProjectListWhichHasVistPermission',
  },
  // v3.0 公司视角-查询项目管理员列表页
  listProjectUserList: {
    url: '/bi-api/api/v2/project/user/listProjectUserList',
  },
  // v3.0 搜索公司成员(从公司成员中添加成员的列表)
  listCompanyManagers: {
    url: '/bi-api/api/company/manager/listCompanyManagers',
  },
  // 获取公司出勤实况
  getWorkerAttendanceSummary: {
    url: '/bi-api/api/dashboardManage/share/getWorkerAttendanceSummary',
  },
  // 获取公司项目数量统计
  getProjectCountStatistic: {
    url: '/bi-api/api/dashboardManage/share/getProjectCountStatistic',
  },
  // 项目列表
  listProjectDistribution: {
    url: '/bi-api/api/dashboardManage/share/v2/listProjectDistribution',
  },
  // 判断是否是公司管理员或者顶部公司管理员
  checkIsCompanyManagerOrNo: {
    url: '/bi-api/api/company/manager/isCurrentOrTopCompanyManager',
  },
  // v2.2.0-pc 获取具有某种权限下所能看到的项目列表
  listProjectsAtSpecificPermission: {
    url: '/bi-api/api/v2/company/listProjectsAtSpecificPermission',
  },
  // v3.8.0 是否是当前公司或当前顶部公司的公司管理员
  isCurrentCompanyManager: {
    url: '/bi-api/api/company/manager/isCurrentOrTopCompanyManager',
  },
  // v5.7.0 查询公司加入上级时，应默认设置的权限code集合，数据权限全部包含，功能权限按公司实际情况查询
  listCompanyShouldSettingDefaultPermissions: {
    url: '/bi-api/api/company/listCompanyShouldSettingDefaultPermissions',
  },
  listProjectsOfSupAndSubByCompanyId: {
    url: '/bi-api/api/company/org/listProjectsOfSupAndSubByCompanyId',
  },
  listProjectsOfCompanyAndSubByCompanyId: {
    url: '/bi-api/api/company/org/listProjectsOfCompanyAndSubByCompanyId',
  },
  getCompanysOfSupAndSubByCompanyId: {
    url: '/bi-api/api/company/org/getCompanysOfSupAndSubByCompanyId',
  },
  exportBlackWorkerList: {
    url: '/bi-api/api/blacklist/export/exportBlackWorkerList',
    method: 'POST',
  },
  setBlackListProjectInfo: {
    url: '/bi-api/api/worker/blackMember/setBlackListProjectInfo',
    method: 'POST',
  },
  getSafetyEducationDashBoardInfo: {
    url: '/bi-api/api/education/safety/study/getSafetyEducationDashBoardInfo',
  },
  showElectronicLaborDataBoardOfCompany: {
    url: '/bi-api/api/contract/labor/showElectronicLaborDataBoardOfCompany',
  },
  listProjectWorkerSignedSummarys: {
    url: '/bi-api/api/company/labor/contract/listProjectWorkerSignedSummarys',
  },
  listCompanyStatistics: {
    url: '/bi-api/api/education/safety/study/listCompanyStatistics',
  },
  listProjectStatistics: {
    url: '/bi-api/api/education/safety/study/listProjectStatistics',
  },
  downloadProjectWorkerSignedSummarys: {
    url:
      '/bi-api/api/company/labor/contract/downloadProjectWorkerSignedSummarys',
  },
};

export default api;
