import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Button } from 'antd';

import logoPath from '../../img/logo_black.svg';
import picPath from '../../img/group-6@2x.png';

const StyledPage = styled(Layout)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .header {
    padding: 16px 23px;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    background-color: #fff;

    .logo {
      display: block;
      height: 32px;
    }
  }
  .footer {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    text-align: center;
    line-height: 1.57;
  }

  .pic {
    display: block;
    height: 340px;
  }

  .info_box {
    margin-left: 121px;

    .ttl {
      font-size: 70px;
      color: #434e59;
      line-height: 1;
    }

    .desc {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 24px;
    }

    .home_btn {
      background-color: transparent;
      border-color: #1890ff;
      color: #1890ff;
      margin-top: 16px;
    }
  }
`;

const Page = ({ history }) => (
  <StyledPage>
    <Layout.Header className="header">
      <Link to="/">
        <img src={logoPath} className="logo" alt="logo" />
      </Link>
    </Layout.Header>
    <Layout.Content data-flex="main:center cross:center">
      <img src={picPath} className="pic" alt="?" />
      <div className="info_box">
        <div className="ttl">404</div>
        <div className="desc">抱歉，你访问的页面不存在</div>
        <Button className="home_btn" onClick={() => history.push('/')}>
          返回首页
        </Button>
      </div>
    </Layout.Content>
    <Layout.Footer className="footer">
      400-004-0686（8:00-20:00） 广州市黄埔区澳门青年人创新部落15楼
      <br />
      墨斗科技Ⓒ2018-2020 粤ICP备18035980号
    </Layout.Footer>
  </StyledPage>
);

Page.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Page;
