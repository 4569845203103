const api = {
  listComplaintsInCompany: {
    url: '/bi-api/api/complaint/query/listComplaintsInCompany',
    method: 'POST',
  },
  listComplaintsInProject: {
    url: '/bi-api/api/complaint/query/listComplaintsInProject',
    method: 'POST',
  },
  getProjectComplaintsQrCode: {
    url: '/bi-api/api/complaint/operation/getProjectComplaintsQrCode',
  },
  getCompanyWorkerComplaintStatistical: {
    url:
      '/bi-api/api/complaint/statistical/getCompanyWorkerComplaintStatistical',
  },
  getProjectWorkerComplaintStatistical: {
    url:
      '/bi-api/api/complaint/statistical/getProjectWorkerComplaintStatistical',
  },
  getComplaintDetail: {
    url: '/bi-api/api/complaint/detail/getComplaintDetail',
  },
  getComplaintFlowEvent: {
    url: '/bi-api/api/complaint/detail/getComplaintFlowEvent',
  },
  handleWorkerComplaint: {
    url: '/bi-api/api/complaint/operation/handleWorkerComplaint',
    method: 'POST',
  },
  queryHandlePersonSetting: {
    url: '/bi-api/api/complaint/setting/queryHandlePersonSetting',
  },
  delHandlePerson: {
    url: '/bi-api/api/complaint/setting/delHandlePerson',
    method: 'POST',
  },
  setHandlePersonType: {
    url: '/bi-api/api/complaint/setting/setHandlePersonType',
    method: 'POST',
  },
  setHandlePerson: {
    url: '/bi-api/api/complaint/setting/setHandlePerson',
    method: 'POST',
  },
  exportWorkerComplaintList: {
    url: '/bi-api/api/complaint/export/exportWorkerComplaintList',
    method: 'POST',
  },
  listProjectUsers: {
    url: '/bi-api/api/v2/project/user/listProjectUsers',
  },
  searchCompanyManagers: {
    url: '/bi-api/api/v2/department/searchCompanyManagers',
  },
  changeHandlePersonPosition: {
    url: '/bi-api/api/complaint/setting/changeHandlePersonPosition',
  },
  showCompanyModule: {
    url: '/bi-api/api/complaint/module/showCompanyModule',
  },
  showProjectModule: {
    url: '/bi-api/api/complaint/module/showProjectModule',
  },
  exportWorkerComplaint: {
    url: '/bi-api/api/complaint/export/exportWorkerComplaint',
    method: 'POST',
  },
};

export default api;
