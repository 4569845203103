import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Radio, Button, Table, Modal, Form, Select, message } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import req from '../../../req';

const StyledDiv = styled.div`
  margin: 0 24px;
  background-color: #fff;
  padding: 24px;
  .title {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #e9e9e9;
    .t {
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }
    .project {
      font-size: 12px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      margin-left: 24px;
      height: 22px;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 0 8px;
    }
  }
  .add {
    display: flex;
    justify-content: end;
    margin-top: 16px;
  }
  .ant-radio-group {
    margin-top: 16px;
  }
  .tips {
    margin: 16px 0;
    .t {
      font-size: 14px;
      color: #7f8388;
      line-height: 22px;
      margin-bottom: 16px;
    }
  }
  .post_wrap {
    display: flex;
    align-items: center;
    .post_t {
    }
  }
`;

// const dataSource = [
//   {
//     key: '1',
//     name: '胡彦斌',
//     age: 32,
//     address: '西湖区湖底公园1号',
//     phone: 12132344,
//   },
//   {
//     key: '2',
//     name: '胡彦祖',
//     age: 42,
//     address: '西湖区湖底公园1号',
//     phone: 12132344,
//   },
// ];
function formatCompanyLabel(item) {
  let { userName } = item;
  if (item.companyUserPositionList && item.companyUserPositionList.length) {
    userName = `${userName}_${item.companyUserPositionList
      .map(({ positionName }) => positionName)
      .join('/')}`;
  }
  return userName;
}
function formatProjectLabel(item) {
  let { name } = item;
  if (item.positions && item.positions.length) {
    name = `${name}_${item.positions
      .map(({ positionName }) => positionName)
      .join('/')}`;
  }
  return name;
}

export default function Setting({ companyId, projectId, tag }) {
  // const [columns, setColumns] = useState();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [addFlag, setAddFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [positionList, setPositionList] = useState([]);
  const [assignerList, setAssignerList] = useState([]);
  const [handlePerson, setHandlePerson] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [type, setType] = useState(1);
  const [positionId, setPositionId] = useState(null);

  const getList = useCallback(async () => {
    setLoading(true);
    const result = await req.complaints.queryHandlePersonSetting({
      companyId: projectId ? null : companyId,
      projectId,
    });
    setLoading(false);
    console.log('指定人列表', result);
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    result.data = result.data || {};
    const newPositionId = result.data.positionId;
    const personList = result.data.personList || [];
    const { positionUserList } = result.data;
    const filterList = personList.map((item, index) => ({
      key: item.userId,
      ...item,
      displayOrder: index + 1,
    }));
    const { type: newType } = result.data;
    setType(newType || 1);
    setPositionId(newPositionId);
    setUserLists(positionUserList);
    setData(filterList);
    setHandlePerson(personList.map(item => item.userId));
    form.setFieldsValue({ status: personList.map(item => item.userId) });
    console.log('指定人列表', filterList);
  }, [companyId, projectId]);
  const getPositionList = useCallback(async () => {
    const result = await req.company.getPositionList({
      companyId: projectId ? null : companyId,
      projectId,
      positionType: projectId ? '01' : '00',
    });
    // console.log('职位', result);
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    const list = result.data.list || [];
    setPositionList(list.map(item => ({ value: item.id, label: item.name })));
    console.log('职位', result);
  }, [companyId, projectId]);
  const getAssignerList = useCallback(async () => {
    const requestApi = projectId
      ? req.complaints.listProjectUsers
      : req.complaints.searchCompanyManagers;
    const result = await requestApi({
      companyId: projectId ? null : companyId,
      projectId: projectId || null,
      page: 1,
      size: 999,
      departmentId: null,
      searchText: '',
      containsSub: '00',
      containsPreUser: '00',
    });
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    const { userList, records } = result.data;
    const filterList = projectId
      ? records.map(item => ({
          label: formatProjectLabel(item),
          value: item.userId,
          key: item.userId,
        }))
      : userList.map(item => ({
          label: formatCompanyLabel(item),
          value: item.userId,
          key: item.userId,
        }));
    setAssignerList(filterList);
    console.log('下拉列表', filterList);
  }, [companyId, projectId]);
  const setHandlePersonCom = useCallback(async params => {
    const result = await req.complaints.setHandlePerson(
      JSON.stringify({
        companyId: projectId ? null : companyId,
        projectId,
        ...params,
      })
    );
    console.log('设置', result);
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    console.log('设置', result);
  }, []);
  const setHandlePersonPosition = useCallback(
    async id => {
      const result = await req.complaints.changeHandlePersonPosition(
        JSON.stringify({
          positionId: id,
          companyId: projectId ? null : companyId,
          projectId,
        })
      );
      if (result.code !== 0) {
        req.err.show(result);
        return;
      }
      console.log('设置职位', result);
    },
    [companyId, positionId]
  );
  const handleChangeType = useCallback(
    async e => {
      const result = await req.complaints.setHandlePersonType(
        JSON.stringify({
          type: e.target.value,
          companyId: projectId ? null : companyId,
          projectId,
        })
      );
      if (result.code !== 0) {
        req.err.show(result);
        return;
      }
      getList();
      setType(e.target.value);
    },
    [companyId, projectId]
  );
  useEffect(() => {
    if (companyId || projectId) {
      getList();
      getPositionList();
      getAssignerList();
    }
    // setColumns([]);
  }, [companyId, projectId]);
  const columns = [
    {
      title: '序号',
      dataIndex: 'displayOrder',
      key: 'displayOrder',
    },
    {
      title: '处理人名称',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '职位',
      dataIndex: 'positions',
      key: 'positions',
      render(value) {
        if (!value || !value.length) {
          return '-';
        }
        console.log(value, '职位');
        return value.map(item => item.positionName).join('/');
      },
    },
    {
      title: '操作',
      dataIndex: 'edit',
      key: 'edit',
      render(value, record) {
        console.log(record);
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: '是否删除该投诉处理人',
                  async onOk() {
                    const result = await req.complaints.delHandlePerson(
                      JSON.stringify({
                        userId: record.userId,
                        companyId: projectId ? null : companyId,
                        projectId,
                      })
                    );
                    if (result.code !== 0) {
                      req.err.show(result);
                      return;
                    }
                    message.success('删除成功');
                    getList();
                    console.log(result, '删除');
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                });
              }}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <StyledDiv>
      <div className="title">
        <div className="t">
          {projectId ? '项目级投诉处理人' : '公司级投诉处理人'}
        </div>
        {tag ? <div className="project">{tag}</div> : null}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Radio.Group
          buttonStyle="solid"
          value={type}
          onChange={handleChangeType}
        >
          <Radio.Button value={1}>指定人</Radio.Button>
          <Radio.Button value={2}>指定岗位</Radio.Button>
        </Radio.Group>
        {type === 1 ? (
          <div className="add">
            <Button type="primary" onClick={() => setAddFlag(true)}>
              添加指定人
            </Button>
          </div>
        ) : null}
      </div>
      <div className="tips">
        <div className="t">
          {projectId
            ? '仅支持一种指定方式，若处理人存在多人时，任意一人处理即可'
            : '工人投诉时会先由各项目先进行处理，处理不了才上升到公司进行处理。仅支持一种指定方式，若处理人存在多人时，任意一人处理即可。'}
        </div>
      </div>
      {type === 1 ? (
        <>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={false}
          />
        </>
      ) : (
        <>
          <div className="post_wrap">
            <div className="post_t">处理岗位：</div>
            <Select
              placeholder="请选择岗位"
              value={positionId}
              clearIcon
              showArrow
              style={{ width: 360, marginRight: 8 }}
              options={positionList}
              onChange={async e => {
                console.log(e);
                await setHandlePersonPosition(e);
                setPositionId(e);
                getList();
                message.success('设置成功');
              }}
            />
          </div>
          {positionId && (!userLists || !userLists.length) ? (
            <div
              className="post_wrap"
              style={{ marginTop: 12, color: '#7F8388' }}
            >
              <div className="post_t" style={{ opacity: 0 }}>
                处理岗位：
              </div>
              <div>
                该职位未设置成员，请到云筑劳务进行设置
                <Link
                  style={{ marginLeft: 16 }}
                  to="/posts/about_worker_complaints"
                  target="_blank"
                >
                  如何设置
                  <RightOutlined style={{ marginLeft: 4, fontSize: 12 }} />
                </Link>
              </div>
            </div>
          ) : null}
        </>
      )}
      <Modal
        title="新增指定人"
        visible={addFlag}
        onOk={() => {
          console.log(123);
        }}
        onCancel={() => setAddFlag(false)}
        footer={[
          <Button
            onClick={() => {
              setHandlePerson([]);
              form.setFieldsValue({
                status: [],
              });
            }}
          >
            重置
          </Button>,
          <Button
            onClick={() => {
              setAddFlag(false);
            }}
          >
            取消
          </Button>,
          <Button
            type="primary"
            onClick={async () => {
              if (!handlePerson.length) {
                message.error('请选择处理人');
                return;
              }
              await setHandlePersonCom({
                handlePersonList: handlePerson.map((item, index) => ({
                  userId: item,
                  displayOrder: index + 1,
                })),
              });
              message.success('设置成功');
              setHandlePerson([]);
              form.setFieldsValue({
                status: [],
              });
              setAddFlag(false);
              getList();
            }}
          >
            确定
          </Button>,
        ]}
      >
        <Form form={form}>
          <Form.Item name="status" label="处理人" required>
            <Select
              placeholder="请选择"
              mode="multiple"
              value={handlePerson}
              maxTagCount={1}
              allowClear
              showArrow
              options={assignerList}
              optionFilterProp="label"
              onChange={async e => {
                // await setHandlePersonCom({  });
                setHandlePerson(e);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </StyledDiv>
  );
}
Setting.propTypes = {
  companyId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
};
