import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Modal, Spin } from 'antd';

import req from '../../req';
import FormModal from './FormModal';

import logoPath from '../../img/logo_black.svg';
import qrcodePath from '../../img/wx_qrcode.jpg';

const StyledPage = styled(Layout)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .header {
    padding: 16px 23px;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    background-color: #fff;

    .logo {
      display: block;
      height: 32px;
    }
  }
  .footer {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    text-align: center;
    line-height: 1.57;
  }

  .pic {
    display: block;
    height: 340px;
  }

  .info_box {
    margin-left: 121px;

    .ttl {
      font-size: 70px;
      color: #434e59;
      line-height: 1;
    }

    .desc {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 24px;
    }

    .home_btn {
      background-color: transparent;
      border-color: #1890ff;
      color: #1890ff;
      margin-top: 16px;
    }
  }
`;

function InitLoading(props) {
  return (
    <div {...props} data-flex="main:center cross:center">
      <Spin tip="正在初始化" size="large" />
    </div>
  );
}

const StyledInitLoading = styled(InitLoading)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

class Page extends React.Component {
  constructor() {
    super();
    this.state = {
      userInfo: null,
      applyModalStatus: false,
      initLoading: false,
    };
    this.onApplyModalOk = this.onApplyModalOk.bind(this);
    this.checkAvailable = this.checkAvailable.bind(this);
    this.initTrail = this.initTrail.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.getMyInfo();
  }

  onApplyModalOk() {
    this.setState({
      applyModalStatus: false,
    });
    window.location.href = '/';
  }

  /**
   * 获取当前个人信息
   */
  getMyInfo() {
    const that = this;
    return req
      .cachify('user.getMyInfo')()
      .then(res => {
        if (!that.mounted) {
          return;
        }
        if (res.code !== 0) {
          if (res.code === req.err.CODE.sessionExpired) {
            req.err.goLogin();
            return;
          }
          req.err.show(res);
          return;
        }
        if (res.data.mobile) {
          that.checkAvailable();
          return;
        }
        that.setState({
          userInfo: res.data,
        });
      })
      .catch(req.err.show);
  }

  /**
   * 检查资格
   */
  checkAvailable() {
    const that = this;
    return req.user
      .checkTrailAlailable()
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        if (!res.data.flag) {
          that.setState({
            applyModalStatus: true,
          });
          return;
        }
        that.initTrail();
      })
      .catch(req.err.show);
  }

  /**
   * 初始化体验项目
   */
  initTrail() {
    const that = this;
    this.setState({
      initLoading: true,
    });
    req.user
      .initTrail()
      .then(res => {
        if (res.code !== 0) {
          that.setState({
            initLoading: true,
          });
          req.err.show(res);
          return;
        }
        window.location.href = '/';
      })
      .catch(err => {
        this.setState({
          initLoading: false,
        });
        req.err.show(err);
      });
  }

  render() {
    const { userInfo, applyModalStatus, initLoading } = this.state;
    return (
      <StyledPage>
        <Layout.Header className="header">
          <Link to="/">
            <img src={logoPath} className="logo" alt="logo" />
          </Link>
        </Layout.Header>
        <Layout.Content
          data-flex="main:center cross:center"
          style={{ position: 'relative' }}
        >
          {userInfo && !userInfo.mobile ? (
            <FormModal user={userInfo} onSucc={this.checkAvailable} />
          ) : null}
          {initLoading ? <StyledInitLoading /> : null}
        </Layout.Content>
        <Layout.Footer className="footer">
          400-004-0686（8:00-20:00） 广州市黄埔区澳门青年人创新部落15楼
          <br />
          墨斗科技Ⓒ2018-2020 粤ICP备18035980号
        </Layout.Footer>
        <Modal
          visible={applyModalStatus}
          onOk={this.onApplyModalOk}
          onCancel={this.onApplyModalOk}
          footer={null}
          title="请联系客服"
          width={280}
          maskClosable={false}
        >
          <div style={{ textAlign: 'center' }}>
            该账号没有开通体验功能，请联系客服申请开通体验功能
          </div>
          <img
            src={qrcodePath}
            alt="客服微信"
            style={{ display: 'block', width: 150, margin: '20px auto' }}
          />
          <div style={{ textAlign: 'center' }}>
            截图扫码或搜索添加客服微信
            <br />
            19966289230
          </div>
        </Modal>
      </StyledPage>
    );
  }
}

export default Page;
