import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import req from '../../../req/index';
import PhoneLogin from './PhoneLogin';
import mpCodePath from '../../../img/code@2x.png';
import phonePath from '../../../img/phone.svg';
import logoPath from '../../../img/logo_black.svg';
import mobilePath from '../../../img/actionsheet_add_mobile@3x.png';
import wechatIcon from '../../../img/icon-wechat1@3x.png';

// const { Search } = Input;

const StyledQrcodeModal = styled.div`
  .header {
    .logo {
      display: block;
      height: 36px;
      margin: auto;
    }

    .ttl {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
      margin-top: 22px;
    }
  }

  .main_box {
    position: relative;
    width: 320px;
    height: 420px;
    border-radius: 4px;
    background-color: #fff;
    padding: 32px 0 0 0;
    margin-top: 36px;

    .tabs {
      margin: 0 50px;

      .item {
        text-align: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
        padding-bottom: 8px;
        width: 100px;
        border-bottom: solid 2px #fff;
        cursor: pointer;

        &.z-crt {
          color: #1890ff;
          border-bottom: solid 2px #1890ff;
        }
      }
    }
    .login {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 24px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    text-align: center;
    line-height: 1.57;
  }
`;

const typeMap = {
  company: '1',
  team: '2',
};

const Tabs = ({ crtTab, onChange }) => (
  <div className="tabs" data-flex="main:justify">
    <div
      className={`item${typeMap.company === crtTab ? ' z-crt' : ''}`}
      onClick={() => onChange(typeMap.company)}
    >
      企业登录
    </div>
    <div
      className={`item${typeMap.team === crtTab ? ' z-crt' : ''}`}
      onClick={() => onChange(typeMap.team)}
    >
      班组长登录
    </div>
  </div>
);

Tabs.propTypes = {
  onChange: PropTypes.func,
  crtTab: PropTypes.string,
};

Tabs.defaultProps = {
  onChange: () => {},
  crtTab: '',
};

const QrcodeBox = ({ codeUrl, status, onUpdate }) => (
  <div
    style={{
      position: 'relative',
      width: 180,
      height: 180,
      margin: 'auto',
      marginTop: 31,
    }}
  >
    {codeUrl ? (
      <img
        src={codeUrl}
        alt="小程序登录"
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
        }}
      />
    ) : (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingOutlined
          style={{
            display: 'block',
            margin: 'auto',
            fontSize: 32,
          }}
        />
      </div>
    )}
    {status === -1 ? (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          textAlign: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <div
          style={{
            fontSize: 16,
            marginTop: 57,
            color: 'rgba(0, 0, 0, 0.65)',
          }}
        >
          二维码已失效
        </div>
        <Button type="primary" style={{ marginTop: 12 }} onClick={onUpdate}>
          刷新
        </Button>
      </div>
    ) : null}
  </div>
);

QrcodeBox.propTypes = {
  codeUrl: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

class QrcodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crtTab: typeMap.company,
      codeUrl: '',
      codeStatus: 1,
      loginType: 'scancode',
    };
    this.actKey = '';
    this.getQrcodeStatusTimer = '';
    this.countDownTimer = '';
    this.onTabChange = this.onTabChange.bind(this);
    this.onCodeUpdate = this.onCodeUpdate.bind(this);
    this.getQrcode = this.getQrcode.bind(this);
    this.getQrcodeStatus = this.getQrcodeStatus.bind(this);
  }

  componentDidMount() {
    this.getQrcode();
    req.clearAllCache();
  }

  componentWillUnmount() {
    clearTimeout(this.getQrcodeStatusTimer);
  }

  onTabChange(value) {
    this.setState({
      crtTab: value,
    });
    if (value === typeMap.company) {
      this.getQrcodeStatus();
    } else {
      clearTimeout(this.getQrcodeStatusTimer);
    }
  }

  onCodeUpdate() {
    this.getQrcode();
  }

  /**
   * 获取小程序码
   */
  getQrcode() {
    const that = this;
    this.setState({
      codeUrl: '',
      codeStatus: 1,
    });
    return req.sys
      .getQrcode()
      .then(res => {
        if (res.code === 0) {
          const { actKey, picUrl } = res.data;
          that.actKey = actKey;
          that.setState({
            codeUrl: picUrl,
          });
          that.getQrcodeStatus();
        } else {
          req.err.show(res.msg);
        }
      })
      .catch(err => {
        req.err.show(err);
      });
  }

  /**
   * 获取小程序状态
   */
  getQrcodeStatus() {
    const that = this;
    const { actKey } = this;
    const { onSucc } = this.props;
    clearTimeout(this.getQrcodeStatusTimer);
    return req.sys
      .getQrcodeStatus({
        actKey,
      })
      .then(res => {
        if (res.code === 0) {
          const { status } = res.data;
          that.setState({
            codeStatus: status,
          });
          switch (status) {
            case -1: // 已过期
              break;
            case 0: // 登录成功
              req.session.set(res.data.sessionId);
              setTimeout(() => {
                onSucc();
              }, 1000);
              break;
            case 1: // 等待扫码
              that.getQrcodeStatusTimer = setTimeout(() => {
                that.getQrcodeStatus();
              }, 2000);
              break;
            default:
              break;
          }
        } else {
          req.err.show(res.msg);
        }
      })
      .catch(req.err.show);
  }

  render() {
    const { crtTab, codeUrl, codeStatus, loginType } = this.state;
    // const that = this;
    const qrcode = (
      <React.Fragment>
        <QrcodeBox
          codeUrl={codeUrl}
          status={codeStatus}
          onUpdate={this.onCodeUpdate}
        />
        <div
          style={{
            marginTop: 11,
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.45)',
            textAlign: 'center',
          }}
        >
          <div>微信扫描二维码即可登录</div>
          <div style={{ color: '#f5a623' }}>
            （请使用具有项目管理权限的微信号扫描）
          </div>
        </div>
      </React.Fragment>
    );
    const CompanyCnt = (
      <React.Fragment>
        {/* <QrcodeBox
          codeUrl={codeUrl}
          status={codeStatus}
          onUpdate={this.onCodeUpdate}
        />
        <div
          style={{
            marginTop: 33,
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.45)',
            textAlign: 'center',
          }}
        >
          <div>微信扫描二维码即可登录</div>
          <div style={{ color: '#f5a623' }}>
            （请使用具有项目管理权限的微信号扫描）
          </div>
        </div> */}
        {loginType === 'scancode' ? qrcode : <PhoneLogin />}
        <div
          className="login"
          style={{
            height: 68,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px dashed #BFBFBF',
            marginTop: 24,
          }}
          onClick={() => {
            if (loginType === 'scancode') {
              clearTimeout(this.getQrcodeStatusTimer);
              console.log(90);
            } else {
              this.getQrcodeStatus();
            }
            this.setState({
              loginType: loginType === 'scancode' ? 'phoneLogin' : 'scancode',
            });
          }}
        >
          <img
            src={loginType === 'scancode' ? mobilePath : wechatIcon}
            alt=""
            style={{
              width: 24,
              height: 24,
            }}
          />
          <div
            style={{
              fontSize: 14,
              marginLeft: 8,
              cursor: 'pointer',
              color: '8c8c8c',
            }}
          >
            {loginType === 'scancode' ? '手机号登录' : '微信扫码登录'}
          </div>
        </div>
      </React.Fragment>
    );
    const TeamCntTab = (
      <React.Fragment>
        <img
          style={{
            display: 'block',
            width: 51,
            height: 56,
            margin: 'auto',
          }}
          src={phonePath}
          alt="在手机上操作"
        />
        <div
          style={{
            marginTop: 24,
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.45)',
            textAlign: 'center',
          }}
        >
          目前仅支持手机上进行班组管理和考勤
        </div>
        <div
          style={{
            margin: '54px 16px 0',
            padding: '11px 16px',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
          }}
        >
          <img
            src={mpCodePath}
            alt="小程序码"
            style={{
              display: 'block',
              width: 90,
              height: 90,
              flexShrink: 0,
            }}
          />
          <div
            style={{
              marginLeft: 12,
              fontSize: 14,
              color: 'rgba(0, 0, 0, 0.45)',
            }}
          >
            扫码进入「墨计考勤」小程序，进行班组管理
          </div>
        </div>
      </React.Fragment>
    );
    const TeamCnt = (
      <React.Fragment>
        <div
          style={{
            marginTop: 40,
          }}
        >
          {/* {loginType === 'scancode' ?  : <PhoneLogin />} */}
          {TeamCntTab}
        </div>
      </React.Fragment>
    );
    return (
      <StyledQrcodeModal>
        <div className="header">
          <img className="logo" src={logoPath} alt="logo" />
          <div className="ttl">国内领先的建筑业劳务管理系统</div>
        </div>
        <div className="main_box">
          <Tabs crtTab={crtTab} onChange={this.onTabChange} />
          {crtTab === typeMap.company ? CompanyCnt : TeamCnt}
        </div>
        <div className="footer">
          400-004-0686（8:00-20:00） 广州市黄埔区澳门青年人创新部落15楼
          <br />
          墨斗科技Ⓒ2018-2020 粤ICP备18035980号
        </div>
      </StyledQrcodeModal>
    );
  }
}

QrcodeModal.propTypes = {
  onSucc: PropTypes.func.isRequired,
};

export default QrcodeModal;
