import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

import config from '../../../../config';

import CompanyPicker from '../CompanyPicker';

const StyledCompanyInfo = styled.div`
  margin-left: 8px;
  cursor: pointer;

  .info {
    margin-right: 5px;
    line-height: normal;

    .parent {
      .icon {
        display: block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 2px;
        background-color: #6a8ec6;
        text-align: center;
        color: #fff;
        font-size: 13px;
        border-radius: 2px;
      }

      .name {
        margin-left: 8px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        line-height: 1.75;
      }
    }

    .crt {
      position: relative;
      padding-left: 32px;
      line-height: 1.5;

      &::after {
        content: '';
        position: absolute;
        left: 12px;
        top: 4px;
        width: 10px;
        height: 10px;
        border: solid 2px rgba(0, 0, 0, 0.25);
        border-top: 0;
        border-right: 0;
      }
    }
  }
`;

class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updatePicker: 0,
    };
  }

  render() {
    const { updatePicker } = this.state;
    const { top, info, topRoles } = this.props;
    if (!info || !info.id) {
      return null;
    }
    return (
      <Dropdown
        overlay={
          <CompanyPicker
            topRoles={topRoles}
            defaultCrt={info.id}
            update={updatePicker}
          />
        }
      >
        <StyledCompanyInfo data-flex="main:left cross:center">
          <div className="info">
            <div className="parent" data-flex="main:left cross:center">
              <img
                className="icon"
                src={top.logo || config.defaultLogo}
                alt="logo"
                data-flex-box="0"
              />
              <div className="name">{top.shortName || top.name}</div>
            </div>
            {info.id && top.id && info.id !== top.id ? (
              <div className="crt">{info.shortName || info.name}</div>
            ) : null}
          </div>
          <CaretDownOutlined style={{ color: '#1890ff' }} />
        </StyledCompanyInfo>
      </Dropdown>
    );
  }
}

CompanyInfo.propTypes = {
  info: PropTypes.object,
  top: PropTypes.object,
  topRoles: PropTypes.array,
};

CompanyInfo.defaultProps = {
  info: {},
  top: {},
  topRoles: [],
};

export default CompanyInfo;
