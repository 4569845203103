import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RightOutlined } from '@ant-design/icons';
import { Modal, Avatar, message, Popover } from 'antd';
import UserCard from '../UserCard/index';
import StatusBox from '../InfoModal/StatusBox';

import RejectModal from '../../Blacklist/RejectModal/index';
import ManagerDrawer from './ManagerDrawer';

import req from '../../../req';
import config from '../../../config';

import defautAvt from '../../../img/default-face.png';
import codeUrl from '../../../img/code.jpg';

import { timestamp2dateTime } from '../../../utils/date';

const { applyFormTypeMap, joinType } = config.joinCompany;

const authorityCode = config.authority;

const StyledDiv = styled.div`
  margin-top: 24px;
  padding-bottom: 15px;
  background-color: #fafafa;
  border-radius: 2px;
  .item {
    padding: 12px 12px 0 12px;
  }

  .item .lable {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
`;

function mapStateToProps(state) {
  return {
    unReadMessageCount: state.unReadMessageCount,
  };
}
const mapDispatchToProps = {
  changeMessageCount: () => ({ type: 'changeMessageCount' }),
};

function Details({
  id,
  toggleModel,
  modelStatus,
  listernerAgreeOrReject,
  changeMessageCount,
  crtUserInfos,
}) {
  const [rejectModalStatus, setRejectModalStatus] = useState(false);
  const [managerDrawerVisible, setManagerDrawerVisible] = useState(false);
  const [managerModalVisible, setManagerModalVisible] = useState(false);
  const [authorityMap, setAuthorityMap] = useState([]);

  const [infos, setInfos] = useState(null);
  function toggleRejectModel() {
    setRejectModalStatus(!rejectModalStatus);
  }

  function getUserRoleCodeAndPermissionList(obj) {
    console.log('执行了');
    req.authority
      .getMap(obj)
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        setAuthorityMap(res.data);
      })
      .catch(req.err.show);
  }

  function getMsgDetails() {
    req.sys
      .getMessageDetails({
        messageId: id,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }

        setInfos(res.data);
        if (
          res.data.joinCompanyApplyDTO.status !== applyFormTypeMap.check &&
          crtUserInfos.id === res.data.joinCompanyApplyDTO.applicant.id &&
          !res.data.readStatus
        ) {
          changeMessageCount();
          listernerAgreeOrReject();
        }
        const obj = {};
        if (res.data.joinCompanyApplyDTO.applyType === joinType.company.value) {
          obj.companyId = res.data.joinCompanyApplyDTO.companyId;
        } else {
          obj.projectId = res.data.joinCompanyApplyDTO.projectId;
        }
        getUserRoleCodeAndPermissionList(obj);
      })
      .catch(req.err.show);
  }

  function dealWith(obj) {
    // const { listernerAgreeOrReject } = this.props;
    req.sys
      .approveMyJoinApply(JSON.stringify(obj))
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        getMsgDetails();
        changeMessageCount();
        listernerAgreeOrReject();
        console.log('dealWith申请公司审批同意或者拒绝成功');
      })
      .catch(req.err.show);
  }

  useEffect(() => {
    if (!id || !modelStatus) {
      return;
    }
    setInfos(null);
    getMsgDetails();
  }, [modelStatus]);

  function callbackReject(text) {
    const len = text.length;
    if (len && len > 30) {
      message.error('拒绝原因不能大于30字');
      return;
    }
    dealWith({
      id: infos.recordId,
      status: applyFormTypeMap.reject,
      remark: text,
    });
    toggleRejectModel();
  }

  const onManagerDrawerFinish = useCallback(() => {
    setManagerDrawerVisible(false);
    getMsgDetails();
  }, [getMsgDetails]);

  const onManagerDrawerCancel = useCallback(() => {
    setManagerDrawerVisible(false);
  }, [getMsgDetails]);

  if (!infos) {
    return null;
  }
  const options = {
    title: infos.title,
    visible: modelStatus,
    onOk() {
      // setManagerDrawerVisible(true);
      setManagerModalVisible(true);
      // confirm({
      //   title: '提示',
      //   content: applyFormTipMap[applyFormTypeMap.agree],
      //   onOk() {
      //     dealWith({
      //       id: infos.recordId,
      //       status: applyFormTypeMap.agree,
      //     });
      //   },
      //   // zIndex: 1050,
      // });
    },
    onCancel(e) {
      if (/cancel_btn/g.test(e.currentTarget.className)) {
        setRejectModalStatus(true);
        return;
      }
      toggleModel();
    },
    destroyOnClose: true,
    cancelText: '拒绝',
    okText: '同意',
  };
  // 公司超管跟项目拥有者跟增删管理员权限
  const projectFlag = authorityMap[authorityCode.project.EDIT_ADMIN];
  const flag =
    infos.joinCompanyApplyDTO.applyType === joinType.company.value
      ? authorityMap[authorityCode.company.EDIT_MEMBER]
      : projectFlag;

  if (infos.joinCompanyApplyDTO.status === applyFormTypeMap.check && flag) {
    console.log('拥有权限');
  } else {
    options.footer = null;
  }

  return (
    <>
      <Modal {...options} cancelButtonProps={{ className: 'cancel_btn' }}>
        <p>{infos.content}</p>
        <CommonArea infos={infos} />
      </Modal>
      <RejectModal
        visible={rejectModalStatus}
        onCancel={toggleRejectModel}
        onOk={callbackReject}
      />
      <ManagerDrawer
        aid={id}
        visible={managerDrawerVisible}
        onFinish={onManagerDrawerFinish}
        onCancel={onManagerDrawerCancel}
      />
      <Modal
        visible={managerModalVisible}
        destroyOnClose
        footer={null}
        maskClosable
        onCancel={() => setManagerModalVisible(false)}
        closable={false}
      >
        <div
          style={{
            marginTop: 40,
          }}
        >
          <div
            style={{
              margin: '24px 24px 0',
            }}
          >
            <img
              src={codeUrl}
              alt="小程序登录"
              style={{
                display: 'block',
                width: 120,
                height: 120,
                margin: 'auto',
              }}
            />
            <div
              style={{
                marginLeft: 12,
                marginTop: 12,
                fontSize: 14,
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'center',
              }}
            >
              推荐使用小程序添加管理员
            </div>
            <div
              style={{
                marginLeft: 12,
                marginTop: 5,
                fontSize: 14,
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'center',
              }}
            >
              用微信扫码进入
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function CommonArea({ infos }) {
  // console.log('CommonArea', month);
  const actualRow = () => {
    if (infos.joinCompanyApplyDTO.applyType === joinType.company.value) {
      return (
        <>
          <div className="lable">加入公司：</div>
          <div className="content">
            {infos.joinCompanyApplyDTO.companyBase.name}
          </div>
        </>
      );
    }
    return (
      <>
        <div className="lable">加入项目：</div>
        <div className="content">
          {infos.joinCompanyApplyDTO.projectBase.name}
        </div>
      </>
    );
  };
  const {
    companyPositionList,
    projectPositionList,
    applyRemark,
    inviter,
  } = infos.joinCompanyApplyDTO;
  return (
    <>
      <StyledDiv>
        <div className="item" data-flex="main:center cross:stretch box:first">
          <div className="lable">申请人：</div>
          <Popover
            content={
              <UserCard number={infos.joinCompanyApplyDTO.applicant.mobile} />
            }
            trigger={['click']}
            placement="right"
          >
            <div className="content">
              <Avatar
                size="small"
                src={infos.joinCompanyApplyDTO.applicant.img || defautAvt}
                style={{
                  color: '#6a8ec6',
                  backgroundColor: '#d5e9ff',
                  margin: '0 5px',
                }}
              />
              {infos.joinCompanyApplyDTO.userName}
              <RightOutlined style={{ width: '10px', height: '8px' }} />
            </div>
          </Popover>
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          {actualRow()}
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          <div className="lable">职位：</div>
          <div className="content">
            {companyPositionList.length + projectPositionList.length < 1
              ? '无'
              : companyPositionList
                  .concat(projectPositionList)
                  .map(p => p.name)
                  .join('、')}
          </div>
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          <div className="lable">备注：</div>
          <div className="content">{applyRemark || '-'}</div>
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          <div className="lable">申请时间：</div>
          <div className="content">
            {timestamp2dateTime(infos.createdTime, '.') || '-'}
          </div>
        </div>
        {inviter && inviter.id ? (
          <div className="item" data-flex="main:center cross:center box:first">
            <div className="lable" />
            <div className="content">
              由 {inviter.name || inviter.userName || inviter.nikeName} 邀请加入
            </div>
          </div>
        ) : null}
      </StyledDiv>
      {infos.joinCompanyApplyDTO.status !== applyFormTypeMap.check ? (
        <StatusBox
          name={
            infos.joinCompanyApplyDTO.status === applyFormTypeMap.reset
              ? infos.joinCompanyApplyDTO.userName
              : infos.joinCompanyApplyDTO.approver.userName
          }
          avt={
            infos.joinCompanyApplyDTO.status === applyFormTypeMap.reset
              ? infos.joinCompanyApplyDTO.applicant.img
              : infos.joinCompanyApplyDTO.approver.img
          }
          status={infos.joinCompanyApplyDTO.status}
          remark={infos.joinCompanyApplyDTO.remark}
        />
      ) : null}
    </>
  );
}
Details.propTypes = {
  toggleModel: PropTypes.func.isRequired,
  modelStatus: PropTypes.bool.isRequired,
  id: PropTypes.string,
  changeMessageCount: PropTypes.func.isRequired,
  listernerAgreeOrReject: PropTypes.func.isRequired,
  crtUserInfos: PropTypes.object.isRequired,
};
CommonArea.propTypes = {
  infos: PropTypes.object.isRequired,
};
Details.defaultProps = {
  id: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
