const baseUrlTable = {
  // local: 'http://192.168.1.171:8086',
  local: '',
  dev: '',
  pre: '',
  release: 'https://labor.emodor.com',
};

export const baseUrl = baseUrlTable.local;

export default {
  baseUrl,
  uploadAction: `${baseUrl}/bi-api/api/file/upload`,
  // 3.8.0 上传学习资料文件API,返回文件id
  uploadEducationResourceAction: `${baseUrl}/bi-api/api/file/uploadEducationResource`,
};
