import React, { useState, useCallback } from 'react';
import { Upload, message, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import reqwest from 'reqwest';
import PropTypes from 'prop-types';

import reqConfig from '../../../../req/config';
import session from '../../../../req/api/session';
import req from '../../../../req';

const { Dragger } = Upload;

// const props = {
//   progress: {
//     strokeColor: {
//       '0%': '#108ee9',
//       '100%': '#87d068',
//     },
//     strokeWidth: 3,
//     format: percent => `${parseFloat(percent.toFixed(2))}%`,
//   },
// };

let loading = false;
let uploadAll = false;

let reFile = null;

function aftLoadImg(
  url,
  audioElement,
  callback,
  res,
  videoHttps,
  uid,
  fileName
) {
  console.log(
    'aftLoadImg',
    audioElement,
    fileName,
    fileName.indexOf('.pdf') === -1,
    uid
  );
  loading = false;
  if (fileName && !(fileName.indexOf('.pdf') === -1)) {
    console.log('我是pdf呀');
    callback(url, videoHttps, 0, 0, 0, uid, fileName);
    return;
  }
  const oImg = new Image();
  oImg.onload = () => {
    console.log(
      'oImg111',
      oImg,
      oImg.width,
      oImg.height,
      audioElement.duration
    );
    if (audioElement.duration > 0) {
      console.log('进来了有时长');
      // if (audioElement.duration <= 5) {
      //   callback('');
      //   message.error('请选择 5 秒以上的有效视频');
      //   return;
      // }
      // 3.9.0 视频时长调整为1小时
      if (audioElement.duration > 3600) {
        callback('');
        message.error('为便于学习，请选择时长为60分钟以内的视频');
        return;
      }
      const { videoCover } = res.data;
      // message.success('添加学习资料成功');
      callback(
        videoCover,
        videoHttps,
        audioElement.duration,
        oImg.width,
        oImg.height,
        uid,
        fileName
      );
    } else {
      audioElement.addEventListener('loadedmetadata', () => {
        // const { duration } = audioElement;
        // console.log('duration', duration, _event);
        // console.log('options', options);
        console.log('duration11', audioElement.duration);
        if (!res.code) {
          // if (audioElement.duration <= 5) {
          //   callback('');
          //   message.error('请选择 5 秒以上的有效视频');
          //   return;
          // }
          // 3.9.0 视频时长调整为1小时
          if (audioElement.duration > 3600) {
            callback('');
            message.error('为便于学习，请选择时长为60分钟以内的视频');
            return;
          }
          const { videoCover } = res.data;
          // message.success('添加学习资料成功');
          callback(
            videoCover,
            videoHttps,
            audioElement.duration,
            oImg.width,
            oImg.height,
            uid,
            fileName
          );
        } else {
          callback('');
          message.error('学习资源上传失败：', res.msg);
        }
      });
    }
  };
  oImg.src = url;
}

/**
 * 视频格式转化为mp4
 */
function transfer2mp4(info, callback, uid) {
  req.education
    .videoProcessMedia(
      JSON.stringify({
        ...info,
      })
    )
    .then(res => {
      if (res.code !== 0) {
        req.err.show(res.msg);
        return;
      }
      const audioElement = new Audio(res.data.httpUrl);
      console.log('audioElement', audioElement);
      aftLoadImg(
        res.data.videoCover,
        audioElement,
        callback,
        res,
        res.data.httpUrl,
        uid,
        res.data.fileName
      );
    })
    .catch(err => {
      req.err.show(err);
    });
}

let uploaded = false;

// function useUploadHook(callback) {
//   const videoHttpUrl = useRef('');
//   const uploadAction = file => {
//     console.log('useUploadHook file', file);
//     const formData = new FormData();
//     formData.append('file', file);

//     const options = {
//       url: reqConfig.uploadEducationResourceAction,
//       // url: 'http://159.75.66.5:8080/bi-api/api/file/uploadEducationResource',
//       method: 'post',
//       processData: false,
//       crossOrigin: true,
//       withCredentials: true,
//       data: formData,
//       headers: { sessionId: session.get() },
//       timeout: 1000 * 60 * 5,
//     };
//     console.log('formData', formData);
//     loading = true;
//     if (!uploaded) {
//       return undefined;
//     }
//     return new Promise((resolve, reject) => {
//       reqwest(options)
//         .then(res => {
//           console.log('reqwest ', res);
//           if (res.code !== 0) {
//             loading = false;
//             console.log('loading', loading);
//             callback('');
//             req.err.show(res);
//             reject(res);
//             return;
//           }
//           // const fileurl = URL.createObjectURL(file);
//           // console.log('fileurl', fileurl);
//           const regex = /.mp4/i;
//           // const flag = regex.test(httpUrl);
//           let flag = true;
//           const includesNamePDF = res.data.fileName.indexOf('.pdf');
//           const includesNameMP4 = res.data.fileName.indexOf('.mp4');
//           console.log('includesNamePDF', includesNamePDF, includesNameMP4);
//           if (res.data && res.data.httpUrl) {
//             flag = regex.test(res.data.httpUrl);
//             console.log('flag', flag);
//           }
//           videoHttpUrl.current = res.data.videoCover;
//           // if (!includesNamePDF) {
//           //   callback(res.data.videoCover, res.data.httpUrl, 0, 0, 0);
//           //   return;
//           // }
//           if (!flag && includesNamePDF !== -1 && includesNameMP4 !== -1) {
//             const parama = {
//               ...res.data,
//             };
//             transfer2mp4(parama, callback, file.uid);
//           } else {
//             videoHttpUrl.current = res.data.videoCover;
//             const audioElement = new Audio(res.data.httpUrl);
//             // console.log('audioElement', audioElement);
//             // const image = new Image();
//             // image.onload = () => {
//             //   image.src = res.data.videoCover;
//             //   console.log('image', image.height, image.width);
//             // };
//             const { videoCover, httpUrl, fileName } = res.data;
//             aftLoadImg(
//               videoCover,
//               audioElement,
//               callback,
//               res,
//               httpUrl,
//               file.uid,
//               fileName
//             );
//           }
//           resolve(res.data);
//         })
//         .catch(err => {
//           if (file.size >= 500 * 1024 * 1024) {
//             loading = false;
//             req.err.show('学习资源不大于500M');
//             callback('');
//           } else {
//             loading = false;
//             callback('');
//             reject(err);
//             req.err.show(err);
//           }
//         });
//     });
//   };

//   return {
//     uploadAction,
//   };
// }

function UploadVideo1({
  // option,
  onUploadChange,
  multiple,
  delectUrlChange,
  // defaultFileList,
  uploadAllChange,
}) {
  // console.log(
  //   'option',
  //   // option,
  //   onUploadChange,
  //   multiple,
  //   delectUrlChange,
  //   // defaultFileList,
  //   uploadAllChange
  // );
  // const optionRef = useRef(option);
  const [fileList, setFileList] = useState([]);
  // const reDefaultFileList = defaultFileList.map(ele => ({
  //   name: ele.description,
  //   uid: ele.uid,
  //   status: 'done',
  //   url: ele.videoUrl,
  // }));
  // console.log(
  //   'UploadVideo defaultFileList',
  //   defaultFileList,
  //   reDefaultFileList
  // );
  // useEffect(() => {
  //   optionRef.current = option;
  // }, [option]);

  function onChange(info) {
    console.log('onChange', info);
    // console.log('info.file.status', info.file.status);
    uploadAll = !!info.fileList.find(ele => ele.status === 'uploading');
    console.log('onChange uploadAll', uploadAll);
    if (!uploadAll) {
      uploadAllChange(true);
    }
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`添加${info.file.name}成功`);
      // message.success('添加学习资料成功');
    } else if (info.file.status === 'error') {
      console.log('error', info.file, info.fileList);
      // message.error(`${info.file.name} file upload failed.`);
      message.error(`${info.file.name}上传失败.`);
    }
    if (info.file.status === 'removed') {
      delectUrlChange(info.file.uid, info.fileList.length);
    }
  }

  // // 文件上传改变事件
  // function updateChange(info) {
  //   console.log('info', info, fileList);
  //   if (info.file.status === 'done') {
  //   // 上传成功后将后台返回来的文件地址进行获取--info.file.response
  //     // if (info.file.response) {
  //     //   this.setState(preState => ({
  //     //     uploadPath : info.file.response.Data,
  //     //   })
  //     //   )
  //     // }
  //     message.success('上传成功！');
  //   } else if (info.file.status === 'error') {
  //   // 上传失败进行提示
  //     message.error('上传失败！');
  //   }
  // }

  // console.log('videoHttpUrl', videoHttpUrl);
  // eslint-disable-next-line no-unused-vars
  const beforeUpload = file => {
    console.log('UploadImgfile', file);
    reFile = file;
    loading = true;

    // const fileurl = URL.createObjectURL(file);
    // // 经测试，发现audio也可获取视频的时长
    // const audioElement = new Audio(fileurl);
    // audioElement.preload = 'metadata';
    // audioElement.src = fileurl;
    // audioElement.onloadedmetadata = () => {
    //   URL.revokeObjectURL(fileurl);
    //   const times = Math.round(audioElement.duration);
    //   console.log('times', times);
    //   if (parseFloat(times) > 1200) {
    //     // message.warning('为便于学习，请选择时长为20分钟以内的视频');
    //     onUploadChange('');
    //     message.error('为便于学习，请选择时长为20分钟以内的视频');
    //     loading = false;
    //     return false;
    //   }
    //   return true;
    // };
    // console.log('beforeUpload audioElement', audioElement, fileurl);
    const includesNamePDF = file.name.indexOf('.pdf') === -1;
    // if (fileSize >= current.size && !includesNamePDF) {
    //   message.error('文档超过限制，不大于20M');
    //   onUploadChange('');
    //   loading = false;
    //   return false;
    // }
    // if (fileSize >= 300 * 1024 * 1024) {
    //   message.error('学习资源不大于300M');
    //   onUploadChange('');
    //   loading = false;
    //   return false;
    // }
    const fileType = file.type;
    console.log('fileType', fileType);
    const includesType = [
      'video/mp4',
      'video/avi',
      'video/x-msvideo',
      'video/x-ms-wmv',
      'application/vnd.rn-realmedia-vbr',
      'audio/mpegurl',
    ].includes(fileType);
    // const includesTypeImg = ['image/jpg', 'image/png', 'image/jpeg'].includes(
    //   fileType
    // );
    // const includesTypeWord = [
    //   'doc',
    //   'docx',
    //   'xml',
    //   'application/msword',
    //   'application/vnd.openxmlformats-officedocument',
    //   'document',
    // ].includes(fileType);
    // const includesNamePPT = [
    //   'vnd.ms-powerpoint',
    //   'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // ].includes(fileType);
    const includesName1 = file.name.indexOf('.mkv') === -1;
    const includesName2 = file.name.indexOf('.ftv') === -1;
    // if (!['image/jpg', 'image/png', 'image/jpeg'].includes(fileType)) {
    //   message.error('支持文件格式.jpg .png');
    //   return false;
    // }
    console.log(
      'includesType',
      includesType,
      includesName1,
      includesName2,
      includesNamePDF
    );
    if (!includesType && includesName1 && includesName2 && includesNamePDF) {
      // message.error(`${file.name}以上文件未能识别，请选择可用的视频文件`);
      message.error(`${file.name}以上文件未能识别，请选择可用的资源文件`);
      onUploadChange('');
      loading = false;
      return false;
    }
    // if (!['video/mp4'].includes(fileType)) {
    //   message.error('只支持mp4格式的视频');
    //   return false;
    // }
    const fileArr = [];
    fileArr.push(file);
    setFileList(fileList.concat(fileArr));
    uploaded = true;
    return true;
  };

  function onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
    Array.from(e.dataTransfer.files).forEach(item => beforeUpload(item));
    // Array.from(e.dataTransfer.files).forEach(item => beforeUpload(item));
    // useUploadHook(onUploadChange);
  }
  // const { uploadAction } = useUploadHook(onUploadChange);
  // console.log('customRequest ', uploadAction);

  const customRequest = useCallback(({ onSuccess, onError }) => {
    console.log('useUploadHook file', reFile);
    let videoHttpUrl = '';
    const formData = new FormData();
    formData.append('file', reFile);

    const options = {
      url: reqConfig.uploadEducationResourceAction,
      // url: 'http://159.75.66.5:8080/bi-api/api/file/uploadEducationResource',
      method: 'post',
      processData: false,
      crossOrigin: true,
      withCredentials: true,
      data: formData,
      headers: { sessionId: session.get() },
      timeout: 1000 * 60 * 5,
    };
    console.log('formData', formData);
    loading = true;
    if (!uploaded) {
      onError('error -------');
    }
    reqwest(options)
      .then(res => {
        console.log('reqwest ', res);
        if (res.code !== 0) {
          loading = false;
          console.log('loading', loading);
          onUploadChange('');
          req.err.show(res);
          onError('error -------');
          return;
        }
        loading = false;
        // const fileurl = URL.createObjectURL(file);
        // console.log('fileurl', fileurl);
        const regex = /.mp4/i;
        // const flag = regex.test(httpUrl);
        let flag = true;
        const includesNamePDF = res.data.fileName.indexOf('.pdf');
        const includesNameMP4 = res.data.fileName.indexOf('.mp4');
        console.log('includesNamePDF', includesNamePDF, includesNameMP4);
        if (res.data && res.data.httpUrl) {
          flag = regex.test(res.data.httpUrl);
          console.log('flag', flag);
        }
        videoHttpUrl = res.data.videoCover;
        // if (!includesNamePDF) {
        //   callback(res.data.videoCover, res.data.httpUrl, 0, 0, 0);
        //   return;
        // }
        if (!flag && includesNamePDF === -1 && includesNameMP4 === -1) {
          const parama = {
            ...res.data,
          };
          transfer2mp4(parama, onUploadChange, reFile.uid);
        } else {
          videoHttpUrl = res.data.videoCover;
          const audioElement = new Audio(res.data.httpUrl);
          // console.log('audioElement', audioElement);
          // const image = new Image();
          // image.onload = () => {
          //   image.src = res.data.videoCover;
          //   console.log('image', image.height, image.width);
          // };
          const { videoCover, httpUrl, fileName } = res.data;
          aftLoadImg(
            videoCover,
            audioElement,
            onUploadChange,
            res,
            httpUrl,
            reFile.uid,
            fileName
          );
        }
        onSuccess('上传成功啦！！');
      })
      .catch(err => {
        if (reFile.size >= 500 * 1024 * 1024) {
          loading = false;
          req.err.show('学习资源不大于500M');
          onError('学习资源不大于500M');
          onUploadChange('');
        } else {
          loading = false;
          onUploadChange('');
          onError(err);
          // req.err.show(err);
        }
      });
    console.log('videoHttpUrl', videoHttpUrl);
    // setTimeout(() => {
    //   onError('error -------');
    // }, 1000 * 30);
  }, []);

  // const props = {};
  // if (!reDefaultFileList.length) {
  //   props.defaultFileList = reDefaultFileList;
  // }

  // useEffect(() => {
  //   console.log('2222');
  // }, []);
  // console.log('111 loading', loading);
  return (
    <div style={{ width: '100%' }}>
      <Dragger
        // showUploadList={false}
        action={reqConfig.uploadEducationResourceAction}
        beforeUpload={beforeUpload}
        // word .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document
        // PPT vnd.ms-powerpoint application/vnd.openxmlformats-officedocument.presentationml.presentation
        accept="video/x-msvideo, video/mp4, .mkv, .ftv, audio/mpegurl, .wmv, .rmvb, .pdf"
        // accept="video/mp4"
        multiple={multiple}
        progress={{
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          strokeWidth: 3,
          format: percent => `${parseFloat(percent.toFixed(2))}%`,
        }}
        onChange={onChange}
        onDrop={onDrop}
        customRequest={customRequest}
        // defaultFileList={reDefaultFileList}
        // fileList={reDefaultFileList}
        // {...props}
      >
        <Spin tip="正在上传文件..." spinning={loading}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
          <p className="ant-upload-hint">
            支持扩展名： .pdf, .mp4, .avi, .rmvb, .mkv, .m3u8
          </p>
        </Spin>
      </Dragger>
    </div>
  );
}

function UploadImg() {
  return <>UploadImg</>;
}

UploadVideo1.defaultProps = {
  // option: {},
  multiple: false,
};

UploadVideo1.propTypes = {
  // option: PropTypes.object,
  onUploadChange: PropTypes.func.isRequired,
  delectUrlChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  // defaultFileList: PropTypes.array.isRequired,
  uploadAllChange: PropTypes.func.isRequired,
};
const UploadVideo = React.memo(UploadVideo1);
export { UploadImg, UploadVideo };
