import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import config from '../../../../config';

const { applyFormTypeMap, applyFormStatusMap } = config.joinCompany;

const StyledStatusBox = styled.div`
  margin-top: 12px;
  border-radius: 2px;
  background-color: #fafafa;
  padding: 24px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;

  .avt {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 8px;
  }

  .name {
    margin-right: 8px;
  }
`;

function StatusBox({ name, avt, status, remark }) {
  return (
    <StyledStatusBox data-flex="main:left cross:center">
      {status === applyFormTypeMap.reset ? (
        '申请已撤销'
      ) : (
        <React.Fragment>
          <img
            className="avt"
            src={avt || config.defaultAvt}
            alt="xxx"
            data-flex-box="0"
          />
          <div className="info">
            <span className="name">{name}</span>
            {applyFormStatusMap[status]}
            该申请
            {status === applyFormTypeMap.reject && remark
              ? `，原因：${remark}`
              : null}
          </div>
        </React.Fragment>
      )}
    </StyledStatusBox>
  );
}

StatusBox.propTypes = {
  name: PropTypes.string,
  avt: PropTypes.string,
  status: PropTypes.string.isRequired,
  remark: PropTypes.string,
};

StatusBox.defaultProps = {
  name: '',
  avt: '',
  remark: '',
};

export default StatusBox;
