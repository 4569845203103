import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Popover, Drawer, Button, Tag, Switch, Select } from 'antd';
import { connect } from 'react-redux';

import req from '../../../req';
import config from '../../../config';
import { timestamp2dateTime } from '../../../utils/date';
import StyledInfoModal from '../InfoModal/StyledInfoModal';
import StatusBox from '../InfoModal/StatusBox';
import RejectModal from '../../Blacklist/RejectModal';
import UserCard from '../UserCard';
import UserCardSuper from '../UserCardSuper';

const { applyFormTypeMap } = config.joinCompany;

const {
  LOWER_LEVEL_TO_UPPER_LEVEL_ITEMS,
  UPPER_LEVEL_ITEMS_TO_LOWER_LEVEL,
} = config.message.typeMap;

const { Option } = Select;

function InfoModal({ id, visible, onCancel, onOk, changeMessageCount }) {
  const [update, setUpdate] = useState(0);
  const [info, setInfo] = useState({});
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [
    participantAddDraverVisible,
    setParticipantAddDraverVisible,
  ] = useState(false);
  const [isChooseProjectAuthority, setIsChooseProjectAuthority] = useState(
    false
  );
  const [isChooseParticipantTypes, setIsChooseParticipantTypes] = useState(
    false
  );
  const [participantTypes, setParticipantTypes] = useState([]);
  const [participantTypeMap, setParticipantTypeMap] = useState({});
  const [isCanChoose, setIsCanChoose] = useState([]);

  useEffect(() => {
    if (!visible) {
      setInfo({});
    }
  }, [visible]);

  useEffect(() => {
    if (!update) {
      return;
    }
    changeMessageCount();
  }, [update]);

  useEffect(() => {
    let didCancel = false;
    if (!visible || !id) {
      return () => {};
    }
    req.sys
      .getMessageDetails({
        messageId: id,
      })
      .then(res => {
        if (didCancel) {
          return;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        if (
          res.data.projectJoinApplyDTO &&
          res.data.projectJoinApplyDTO.status !== applyFormTypeMap.check &&
          !res.data.readStatus
        ) {
          changeMessageCount();
        }
        setInfo(res.data);
        console.log(res.data);
        // 当前用户所在项目是否已将管理权限授出
        req.project
          .checkProjectPermissionWasGaveOrNo({
            projectId: res.data.projectJoinApplyDTO.joinProjectInfo.id,
          })
          .then(r1 => {
            if (r1.code !== 0) {
              req.err.show(r1.msg);
              return;
            }
            setIsCanChoose(r1.data === '01');
            setIsChooseProjectAuthority(r1.data === '00');
          })
          .catch(req.err.show);
      })
      .catch(err => {
        if (didCancel) {
          return;
        }
        req.err.show(err);
      });
    return () => {
      didCancel = true;
    };
  }, [id, visible, update]);

  useEffect(() => {
    req.participantType.getDicts().then(r1 => {
      if (r1.code !== 0) {
        req.err.show(r1.msg);
        return;
      }
      setParticipantTypes(r1.data);
    });
    req.participantType
      .getDictsMap()
      .then(r2 => {
        if (r2.code !== 0) {
          req.err.show(r2.msg);
          return;
        }
        setParticipantTypeMap(r2.data);
        console.log('participantTypeMap', r2.data);
      })
      .catch(req.err.show);
  }, []);

  const onModalOk = useCallback(() => {
    setParticipantAddDraverVisible(true);
    setRejectModalVisible(false);
  }, []);

  const onModalCancel = useCallback(e => {
    if (!/cancel_btn/.test(e.currentTarget.className)) {
      onCancel();
      return;
    }
    setRejectModalVisible(true);
  }, []);

  const onRejectModelCancel = useCallback(() => {
    setRejectModalVisible(false);
  }, []);

  const onParticipantAddDrawerCancel = useCallback(() => {
    setParticipantAddDraverVisible(false);
  }, []);

  const onParticipantAddDrawerOk = useCallback(() => {
    setParticipantAddDraverVisible(false);
    let p = null;
    let content = '';
    if (info.messageType === LOWER_LEVEL_TO_UPPER_LEVEL_ITEMS) {
      // p = req.project.confirmAddChildProject;
      content = '同意后，该项目数据将汇总到您的项目，是否确定同意？';
    } else {
      // p = req.project.confirmAddParentProject;
      content = '同意后，项目将并入到公司中，是否确定同意？';
    }
    Modal.confirm({
      title: '提示',
      content,
      onOk() {
        if (info.messageType === LOWER_LEVEL_TO_UPPER_LEVEL_ITEMS) {
          p = req.project.confirmAddParentProject(
            {
              applyId: info.recordId,
              status: '01',
            },
            JSON.stringify({
              refuseReason: '',
            })
          );
        } else {
          p = req.project.confirmAddChildProject(
            {
              applyId: info.recordId,
              status: '01',
              syncPermission: isChooseProjectAuthority ? '01' : '00',
            },
            JSON.stringify({
              refuseReason: '',
            })
          );
        }
        p.then(res => {
          if (res.code !== 0) {
            req.err.show(res);
            return;
          }
          onOk();
          setUpdate(new Date().getTime());
        }).catch(req.err.show);
      },
    });
  }, [info.recordId]);

  const onRejectModelOk = useCallback(
    text => {
      let p = null;
      if (info.messageType === LOWER_LEVEL_TO_UPPER_LEVEL_ITEMS) {
        p = req.project.confirmAddParentProject(
          {
            applyId: info.recordId,
            status: '02',
          },
          JSON.stringify({
            refuseReason: text,
          })
        );
      } else {
        p = req.project.confirmAddChildProject(
          {
            applyId: info.recordId,
            status: '02',
          },
          JSON.stringify({
            refuseReason: text,
          })
        );
      }
      p.then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        setRejectModalVisible(false);
        setUpdate(new Date().getTime());
      }).catch(req.err.show);
    },
    [info.recordId]
  );

  const ChooseProjectAuthorityChange = useCallback(() => {
    if (isCanChoose) {
      setIsChooseProjectAuthority(false);
      Modal.confirm({
        title: '提示',
        content: '为避免管理紊乱，管理权限只可同步给一家上级参建',
      });
    } else {
      setIsChooseProjectAuthority(!isChooseProjectAuthority);
    }
  }, [isChooseProjectAuthority]);

  const isChooseParticipantTypesChange = useCallback(() => {
    setIsChooseParticipantTypes(true);
  }, [isChooseParticipantTypes]);

  const handleChange = useCallback(
    value => {
      console.log('value', value);
      setIsChooseParticipantTypes(false);
      // 参建类型改变
      req.project
        .update(
          {
            id: info.projectJoinApplyDTO.joinProjectInfo.id,
          },
          JSON.stringify({
            contractorType: value[0],
            projectName: info.projectJoinApplyDTO.joinProjectInfo.name,
          })
        )
        .then(res => {
          if (res.code !== 0) {
            req.err.show(res.msg);
            return;
          }
          setUpdate(new Date().getTime());
        })
        .catch(req.err.show);
    },
    [isChooseParticipantTypes]
  );

  if (!info.id || !visible) {
    return null;
  }
  const { projectJoinApplyDTO } = info;
  const options = {};
  if (projectJoinApplyDTO.status !== applyFormTypeMap.check) {
    options.footer = null;
  }

  const {
    status,
    applyUser,
    applyProjectInfo,
    approverList,
    refuseReason,
    joinProjectInfo,
  } = projectJoinApplyDTO;

  const { contractorType } = joinProjectInfo;

  const approver =
    status !== applyFormTypeMap.reset ? approverList[0] : applyUser;

  return (
    <React.Fragment>
      <Modal
        {...options}
        visible={visible}
        title={info.title || ''}
        cancelText="拒绝"
        okText="同意"
        onCancel={onModalCancel}
        onOk={onModalOk}
        cancelButtonProps={{ className: 'cancel_btn' }}
      >
        <StyledInfoModal>
          <div className="head">{info.content || '-'}</div>
          <div className="info_box">
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                申请人：
              </div>
              <img
                className="avt"
                src={applyUser.img || config.defaultAvt}
                alt="xxx"
                data-flex-box="0"
              />
              <div className="val">
                <Popover
                  content={<UserCard number={applyUser.mobile} />}
                  trigger={['click']}
                  placement="right"
                >
                  {applyUser.userName || applyUser.name || applyUser.nikeName}
                  <RightOutlined />
                </Popover>
              </div>
            </div>
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                参建单位：
              </div>
              <div className="val">{applyProjectInfo.companyName || '-'}</div>
            </div>
            {info.messageType === LOWER_LEVEL_TO_UPPER_LEVEL_ITEMS ? (
              <div className="row" data-flex="main:left">
                <div className="label" data-flex-box="0">
                  参建类型：
                </div>
                <div className="val">
                  {applyProjectInfo.contractorType || '-'}
                </div>
              </div>
            ) : null}
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                项目：
              </div>
              <div className="val">{applyProjectInfo.name || '-'}</div>
            </div>
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                申请时间：
              </div>
              <div className="val">
                {timestamp2dateTime(info.createdTime, '.')}
              </div>
            </div>
          </div>
          {status !== applyFormTypeMap.check ? (
            <StatusBox
              name={approver.userName || approver.nikeName}
              avt={approver.img}
              status={status}
              remark={refuseReason}
            />
          ) : null}
        </StyledInfoModal>
      </Modal>
      <RejectModal
        visible={rejectModalVisible}
        onCancel={onRejectModelCancel}
        onOk={onRejectModelOk}
      />
      <Drawer
        title={
          info.messageType === UPPER_LEVEL_ITEMS_TO_LOWER_LEVEL
            ? info.title || ''
            : '添加分包'
        }
        width={500}
        onClose={onParticipantAddDrawerCancel}
        visible={participantAddDraverVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {info.messageType === UPPER_LEVEL_ITEMS_TO_LOWER_LEVEL ? (
          <Form
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
          >
            <Form.Item>
              <div
                style={{
                  fontSize: '14px',
                }}
              >
                <Tag color="#1890ff">我的项目</Tag>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  项目:
                </div>
                <div>{info.projectJoinApplyDTO.joinProjectInfo.name}</div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  参建单位:
                </div>
                <div>
                  {info.projectJoinApplyDTO.joinProjectInfo.companyName}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  参建类型:
                </div>
                <div>
                  {participantTypeMap[contractorType]
                    ? participantTypeMap[contractorType].value
                    : '-'}
                </div>
                <div
                  style={{ color: '#1890ff', marginLeft: '10px' }}
                  onClick={isChooseParticipantTypesChange}
                >
                  编辑
                </div>
              </div>

              {isChooseParticipantTypes ? (
                <Select
                  mode="multiple"
                  placeholder="请选择参建类型"
                  allowClear
                  onChange={handleChange}
                >
                  {participantTypes &&
                    participantTypes.length &&
                    participantTypes.map(item => (
                      <Option value={item.code} key={item.code}>
                        {item.value}
                      </Option>
                    ))}
                </Select>
              ) : null}
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  fontSize: '14px',
                }}
              >
                <Tag color="#4ad1ad">加入</Tag>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  参建单位:
                </div>
                <div>
                  {info.projectJoinApplyDTO.applyProjectInfo.companyName}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  项目:
                </div>
                <div>{info.projectJoinApplyDTO.applyProjectInfo.name}</div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  管理员:
                </div>
                <div>
                  <Popover
                    content={
                      <UserCardSuper
                        number={
                          info.projectJoinApplyDTO.applyProjectInfo
                            .superAdmins[0].phoneNumber
                        }
                      />
                    }
                    // content={<UserCard number="458248" />}
                    trigger="click"
                    placement="rightBottom"
                  >
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div>
                        <img
                          style={{
                            height: '30px',
                            lineHeight: '30px',
                            marginRight: '10px',
                            borderRadius: '50%',
                          }}
                          src={
                            info.projectJoinApplyDTO.applyProjectInfo
                              .superAdmins[0].headImg
                          }
                          alt="logo"
                        />
                      </div>
                      <div
                        style={{
                          marginRight: '10px',
                        }}
                      >
                        {
                          info.projectJoinApplyDTO.applyProjectInfo
                            .superAdmins[0].userName
                        }
                      </div>
                      <div>
                        <RightOutlined />
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  fontSize: '14px',
                }}
              >
                同步权限
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  height: '60px',
                  marginRight: 0,
                  width: '120%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '60px',
                      lineHeight: '60px',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                        width: '100%',
                      }}
                    >
                      查看数据权限
                    </p>
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                        width: '100%',
                        color: 'rgba(33, 39, 50, 0.45)',
                        fontSize: '12px',
                      }}
                    >
                      上级参建单位可查看您项目的数据
                    </p>
                  </div>
                  <div
                    style={{
                      width: '80px',
                      height: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked
                      disabled
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  height: '60px',
                  marginRight: 0,
                  width: '120%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '60px',
                      lineHeight: '60px',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                        width: '100%',
                      }}
                    >
                      项目管理权限
                    </p>
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                        width: '100%',
                        color: 'rgba(33, 39, 50, 0.45)',
                        fontSize: '12px',
                      }}
                    >
                      上级参建单位可管理您的项目
                    </p>
                  </div>
                  <div
                    style={{
                      width: '80px',
                      height: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={isChooseProjectAuthority}
                      onChange={ChooseProjectAuthorityChange}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
          </Form>
        ) : (
          <Form
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
          >
            <Form.Item>
              <div
                style={{
                  fontSize: '14px',
                }}
              >
                <Tag color="#4ad1ad">分包项目</Tag>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  参建单位:
                </div>
                <div>
                  {info.projectJoinApplyDTO.joinProjectInfo.companyName}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  参建类型:
                </div>
                <div>
                  {participantTypeMap[contractorType]
                    ? participantTypeMap[contractorType].value
                    : '-'}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  项目:
                </div>
                <div>{info.projectJoinApplyDTO.joinProjectInfo.name}</div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  项目编号:
                </div>
                <div>
                  {info.projectJoinApplyDTO.joinProjectInfo.projectNumber}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  项目地址:
                </div>
                <div>
                  {info.projectJoinApplyDTO.joinProjectInfo.addressDetail}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  marginTop: '-25px',
                }}
              >
                <div
                  style={{
                    color: 'rgba (0, 0, 0, 0.85)',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    minWidth: '64px',
                    textAlign: 'right',
                  }}
                >
                  管理员:
                </div>
                <div>
                  <Popover
                    content={
                      <UserCardSuper
                        number={
                          info.projectJoinApplyDTO.joinProjectInfo
                            .superAdmins[0].phoneNumber
                        }
                      />
                    }
                    // content={<UserCard number="458248" />}
                    trigger="click"
                    placement="rightBottom"
                  >
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div>
                        <img
                          style={{
                            height: '30px',
                            lineHeight: '30px',
                            marginRight: '10px',
                            borderRadius: '50%',
                          }}
                          src={
                            info.projectJoinApplyDTO.joinProjectInfo
                              .superAdmins[0].headImg
                          }
                          alt="logo"
                        />
                      </div>
                      <div
                        style={{
                          marginRight: '10px',
                        }}
                      >
                        {
                          info.projectJoinApplyDTO.joinProjectInfo
                            .superAdmins[0].userName
                        }
                      </div>
                      <div>
                        <RightOutlined />
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  fontSize: '14px',
                }}
              >
                注：添加后可在参建单位管理中查看
              </div>
            </Form.Item>
          </Form>
        )}
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'center',
          }}
        >
          <Button
            onClick={onParticipantAddDrawerCancel}
            style={{ marginRight: 8 }}
          >
            取消
          </Button>
          <Button onClick={onParticipantAddDrawerOk} type="primary">
            确定
          </Button>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

InfoModal.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  changeMessageCount: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeMessageCount: () => ({ type: 'changeMessageCount' }),
};

export default connect(null, mapDispatchToProps)(InfoModal);
