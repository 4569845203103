import React from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';
import req from '../../req/index';

function Page() {
  const match = useRouteMatch();
  req.session.set(match.params.id);
  return <Redirect to="/" />;
}

export default Page;
