import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Row, Col, DatePicker, Input, Button, message } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

// const { TreeNode } = Tree;

const { Search } = Input;

const StyledDiv = styled.div`
  .columnStyle {
    display: flex;
    line-height: 30px;
  }
  .columnLabelStyle {
    flex-shrink: 0;
    font-size: 14px;
    // font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
  }
  .selectStyle {
    display: inline-block;
    border-radius: 4px;
    min-width: 100px;
    margin-right: 15px;
    .ant-select-selection--single {
      color: #fff;
      border-radius: 4px;
      background-color: #1890ff;
      border: none;
      .ant-select-arrow-icon {
        color: #fff;
      }
    }
  }
  .disableStyle {
    display: inline-block;
    border-radius: 4px;
    min-width: 100px;
    margin-right: 15px;
    .ant-select-selection--single {
      color: #fff;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.2);
      border: none;
      .ant-select-arrow-icon {
        color: #fff;
      }
    }
  }
  .normal {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 30px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px;
    white-space: nowrap;
    &.query {
      color: #fff;
      background-color: #1890ff;
    }
    &.output {
      color: #fff;
      background-color: #60c48e;
    }
  }
`;

function disabledDate(current) {
  return current && current >= moment().endOf('day');
}

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // startDate: moment().startOf('month'),
      // endDate: moment().endOf('day'),
      startDate: '',
      endDate: '',
      param: '',
    };
    this.timer = '';
    this.orderOrigin = '';
    this.onRangeChange = this.onRangeChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.setParam = this.setParam.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    console.log('match:', props.match);
  }

  componentDidUpdate(prevProps) {
    const { firstConditon } = this.props;
    console.log('firstConditon', firstConditon);
    // console.log('props', prevProps.firstConditon !== firstConditon);
    if (prevProps.firstConditon !== firstConditon) {
      const data = {};
      const filterObject = { ...firstConditon };
      data.startDate = moment(filterObject.startDate);
      data.endDate = moment(filterObject.endDate);
      delete filterObject.startDate;
      delete filterObject.endDate;
      Object.keys(filterObject).forEach(item => {
        if (item === 'confirmStatus') {
          data.accountType = filterObject[item];
        } else if (item === 'type') {
          data.payProject = filterObject[item];
        } else {
          data[item] = filterObject[item];
        }
      });
      console.log('监听到了seachBOX', firstConditon);
      this.saveState(data);
    }

    // this.getNavListFun();
  }

  // 发布时间查询
  onRangeChange(date) {
    const [startDate, endDate] = date;
    // const that = this;
    this.setState({
      startDate,
      endDate,
      // }, () => that.onSearch());
    });
  }

  /**
   * click查询
   */
  onSearch() {
    const {
      startDate,
      endDate,
      param, // 搜索
    } = this.state;
    const { onSearch } = this.props;
    const data = {};
    if (moment(startDate).isAfter(endDate, 'day')) {
      message.error('开始时间在结束时间后，请修改');
      return;
    }
    if (moment(endDate).isBefore(startDate, 'day')) {
      message.error('结束时间在开始时间前，请修改');
      return;
    }
    // 开始时间
    if (startDate) {
      data.startTime = startDate.format('YYYY-MM-DD');
    }
    // 结束时间
    if (endDate) {
      data.endTime = endDate.format('YYYY-MM-DD');
    }
    // 搜素文本
    if (param) {
      data.userName = param;
    }
    onSearch(data);
  }

  onStartDateChange(date) {
    this.setState({
      startDate: date,
    });
    // this.dealWidth(date);
  }

  onEndDateChange(date) {
    this.setState({
      endDate: date,
    });
    // this.dealWidth(null, date);
  }

  setParam(e) {
    this.setState({
      param: e.target.value,
    });
  }

  saveState(data) {
    this.setState(data);
  }

  render() {
    const { startDate, endDate, param } = this.state;
    const { loading } = this.props;
    // const commonLayout1 = {
    //   xs: 20,
    //   sm: 20,
    //   md: 20,
    //   lg: 20,
    //   xl: 20,
    // };

    // const commonLayout3 = {
    //   xs: 8,
    //   sm: 8,
    //   md: 8,
    //   lg: 8,
    //   xl: 8,
    // };

    return (
      <>
        <StyledDiv>
          <Row gutter={24} style={{ marginBottom: 24 }}>
            <Col className="columnStyle" md={6}>
              <span className="columnLabelStyle">用户名：</span>
              <Search
                placeholder="昵称或备注名查询"
                defaultValue={param}
                onChange={this.setParam}
                // onSearch={this.onSearch}
              />
            </Col>
            {/* <Col className="columnStyle" {...commonLayout1} xxl={6}>
              <div style={{ display: 'inline-block' }}>
                <span className="columnLabelStyle">时间段：</span>
                <DatePicker.RangePicker
                  value={[startDate, endDate]}
                  onChange={this.onRangeChange}
                  style={{ width: 256 }}
                  allowClear={false}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  placeholder={['请选择', '请选择']}
                />
              </div>
            </Col> */}
            <Col md={10} className="columnStyle">
              <div className="time_date" data-flex="main: left">
                <span data-flex-box="0" className="columnStyle">
                  时间：
                </span>
                <DatePicker
                  onChange={this.onStartDateChange}
                  disabledDate={disabledDate}
                  value={startDate}
                  style={{ width: 130 }}
                  allowClear={false}
                />
                <span
                  style={{ height: 32, lineHeight: '32px', padding: '0 6px' }}
                >
                  —
                </span>
                <DatePicker
                  onChange={this.onEndDateChange}
                  disabledDate={disabledDate}
                  value={endDate}
                  style={{ width: 130 }}
                  allowClear={false}
                />
                <Button
                  type="primary"
                  loading={loading}
                  onClick={this.onSearch}
                  style={{ marginLeft: 20 }}
                >
                  查询
                </Button>
              </div>
            </Col>
          </Row>
        </StyledDiv>
      </>
    );
  }
}
SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  firstConditon: PropTypes.object.isRequired,
  // startDate: PropTypes.string,
  match: PropTypes.object.isRequired,
};

SearchBox.defaultProps = {
  loading: false,
  // startDate: '',
};
export default withRouter(SearchBox);
