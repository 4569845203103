import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Modal, Avatar, Popover, Select, message } from 'antd';
import RejectModal from '../../Blacklist/RejectModal';
import StatusBox from '../InfoModal/StatusBox';
import req from '../../../req';
import config from '../../../config';

import { timestamp2dateTime, timestamp2date } from '../../../utils/date';

const { applyFormTypeMap } = config.joinCompany;

const { confirm } = Modal;
const { Option } = Select;

const StyledDiv = styled.div`
  padding-bottom: 15px;
  background-color: #fafafa;
  border-radius: 2px;
  .item {
    padding: 12px 12px 0 12px;
  }

  .item .lable {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
`;

function mapStateToProps(state) {
  return {
    unReadMessageCount: state.unReadMessageCount,
  };
}
const mapDispatchToProps = {
  receiveBlacklistCount: () => ({ type: 'changeBlacklistCount' }),
  changeMessageCount: () => ({ type: 'changeMessageCount' }),
};

function CommonArea({ infos, monthChange }) {
  const [month, setMonth] = useState(infos.blackMemberApply.months);
  // console.log('CommonArea', month);
  function timeSelect(value) {
    // console.log(`selected ${value}`);
    setMonth(value);
    monthChange(value);
  }
  const pickerMonth = (
    <>
      <Select
        value={month}
        style={{ width: 100, marginRight: 10 }}
        onChange={timeSelect}
      >
        <Option value={0}>永久</Option>
        {[1, 2, 3, 4, 5, 6].map(item => (
          <Option value={item} key={item}>
            {item}个 月
          </Option>
        ))}
        <Option value={12}>1年</Option>
      </Select>
    </>
  );
  const abnormal = () => {
    if (month === 0) {
      return <span>永久</span>;
    }
    if (month === 12) {
      return <span>一年</span>;
    }
    return <span>{month}月</span>;
  };

  return (
    <>
      <StyledDiv>
        <div className="item" data-flex="main:center cross:stretch box:first">
          <div className="lable">拉黑：</div>
          <div className="content">
            <Avatar
              size="small"
              src={infos.blackMemberApply.headImg}
              style={{
                color: '#6a8ec6',
                backgroundColor: '#d5e9ff',
                margin: '0 5px',
              }}
            >
              {infos.blackMemberApply.name.substring(
                infos.blackMemberApply.name.length - 2
              ) ||
                infos.blackMemberApply.name.substring(
                  infos.blackMemberApply.name.length - 1
                )}
            </Avatar>
            {infos.blackMemberApply.name}
          </div>
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          <div className="lable">身份证号：</div>
          <div className="content">{infos.blackMemberApply.idCard}</div>
        </div>
        <div className="item" data-flex="main:center cross:top box:first">
          <div className="lable">原因：</div>
          <div className="content">
            {infos.blackMemberApply.reason ? (
              <div>{infos.blackMemberApply.reason}</div>
            ) : (
              <div>-</div>
            )}
            {infos.blackMemberApply.images.map(item => (
              <Popover
                key={item}
                content={<Avatar size={400} shape="square" src={item} />}
                trigger="click"
              >
                <Avatar
                  src={item}
                  size="{50}"
                  shape="square"
                  style={{ margin: '10px 10px 10px 0' }}
                />
              </Popover>
            ))}
          </div>
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          <div className="lable">拉黑期限：</div>
          <div className="content">
            {infos.blackMemberApply.status === '00' ? pickerMonth : abnormal()}
            {infos.blackMemberApply.months !== 0 && (
              <span>
                ({timestamp2date(infos.blackMemberApply.startTime, '.')}-
                {timestamp2date(infos.blackMemberApply.endTime, '.')})
              </span>
            )}
          </div>
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          <div className="lable">项目：</div>
          <div className="content">
            {infos.blackMemberApply.projectName || '-'}
          </div>
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          <div className="lable">上报人：</div>
          <div className="content">
            {infos.blackMemberApply.submitter.userName ||
              infos.blackMemberApply.submitter.nikeName ||
              '-'}
          </div>
        </div>
        <div className="item" data-flex="main:center cross:center box:first">
          <div className="lable">时间：</div>
          <div className="content">
            {timestamp2dateTime(infos.createdTime, '.') || '-'}
          </div>
        </div>
      </StyledDiv>
      {infos.blackMemberApply.status !== applyFormTypeMap.check ? (
        <StatusBox
          name={
            infos.blackMemberApply.approver.userName ||
            infos.blackMemberApply.approver.nikeName
          }
          avt={infos.blackMemberApply.approver.img}
          status={infos.blackMemberApply.status}
          remark={infos.blackMemberApply.approveReason}
        />
      ) : null}
    </>
  );
}

function Details({
  id,
  toggleModel,
  modelStatus,
  listernerAgreeOrReject,
  receiveBlacklistCount,
  changeMessageCount,
  crtUserInfos,
}) {
  // console.log('详情里获取到了id', id);
  const [rejectModalStatus, setRejectModalStatus] = useState(false);
  const [infos, setInfos] = useState(null);
  const [month, setMonth] = useState(0);
  function toggleRejectModel() {
    console.log('点击了取消');
    setRejectModalStatus(!rejectModalStatus);
  }
  function getMsgDetails() {
    req.sys
      .getMessageDetails({
        messageId: id,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        const r1 = res.data.blackMemberApply;
        console.log('执行了黑名单详情');
        if (
          r1.submitter.id === crtUserInfos.id &&
          r1.status !== '00' &&
          !r1.readStatus
        ) {
          changeMessageCount();
          listernerAgreeOrReject();
        }
        setInfos(res.data);
        setMonth(res.data.months);
      })
      .catch(req.err.show);
  }

  function dealWith(obj) {
    // const { listernerAgreeOrReject } = this.props;
    req.company
      .approveAddBlacklist(JSON.stringify(obj))
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        getMsgDetails();
        changeMessageCount();
        receiveBlacklistCount();
        listernerAgreeOrReject();
        console.log('dealWith黑名单审批同意或者拒绝成功');
      })
      .catch(req.err.show);
  }

  useEffect(() => {
    if (!id || !modelStatus) {
      return;
    }
    setInfos(null);
    getMsgDetails();
  }, [modelStatus]);

  function callbackReject(text) {
    if (!/\S/.test(text)) {
      message.error('请输入拒绝原因');
      return;
    }
    dealWith({
      id: infos.recordId,
      agree: false,
      reason: text,
      monthNum: month,
    });
    toggleRejectModel();
  }
  function monthChange(time) {
    console.log('顶层接受到数据了', time);
    setMonth(time);
  }
  const options = {
    title: '黑名单添加申请',
    visible: modelStatus,
    onOk() {
      confirm({
        title: '提示',
        content:
          '拉黑后，工人将从公司中的所有项目退场，不可进行考勤打卡。若是班组长，则其班组全部工人退场，是否确定拉黑？',
        onOk() {
          console.log('点击了同意');
          dealWith({
            id: infos.recordId,
            agree: true,
            monthNum: parseInt(month, 10),
          });
        },
        // zIndex: 1050,
      });
    },
    onCancel(e) {
      if (/cancel_btn/g.test(e.currentTarget.className)) {
        console.log('点击了拒绝');
        // dealWith({
        //   agree: false,
        //   monthNum: parseInt(month, 10),
        // });
        setRejectModalStatus(true);
        return;
      }
      toggleModel();
    },
    destroyOnClose: true,
    cancelText: '拒绝',
    okText: '同意',
  };
  if (!infos) {
    return null;
  }
  if (infos.blackMemberApply.status !== '00') {
    options.footer = null;
  }
  const tipItem = () => {
    if (
      infos.blackMemberApply.approver &&
      infos.blackMemberApply.status === '01'
    ) {
      return (
        <p>
          {infos.blackMemberApply.approver.userName}
          已同意将
          {infos.blackMemberApply.name}
          添加到黑名单
        </p>
      );
    }
    if (
      infos.blackMemberApply.approver &&
      infos.blackMemberApply.status === '02'
    ) {
      return (
        <p>
          {infos.blackMemberApply.approver.userName}
          已拒绝将
          {infos.blackMemberApply.name}
          添加到黑名单
          {infos.blackMemberApply.approveReason && (
            <span>, ({infos.blackMemberApply.approveReason})</span>
          )}
        </p>
      );
    }
    return null;
  };

  return (
    <>
      <Modal {...options} cancelButtonProps={{ className: 'cancel_btn' }}>
        {crtUserInfos.id !== infos.blackMemberApply.submitter.id ? (
          <CommonArea infos={infos} monthChange={monthChange} />
        ) : (
          tipItem()
        )}
      </Modal>
      <RejectModal
        visible={rejectModalStatus}
        onCancel={toggleRejectModel}
        onOk={callbackReject}
        placeholder="请输入拒绝原因，必填"
      />
    </>
  );
}
Details.propTypes = {
  toggleModel: PropTypes.func.isRequired,
  modelStatus: PropTypes.bool.isRequired,
  receiveBlacklistCount: PropTypes.func.isRequired,
  id: PropTypes.string,
  changeMessageCount: PropTypes.func.isRequired,
  listernerAgreeOrReject: PropTypes.func.isRequired,
  crtUserInfos: PropTypes.object.isRequired,
};
Details.defaultProps = {
  id: '',
};
CommonArea.propTypes = {
  infos: PropTypes.object.isRequired,
  monthChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
