import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import styled from 'styled-components';
import moment from 'moment';
import { Breadcrumb, Button, Card, Image } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import req from '../../../req';
import config from '../../../config';
import progress0 from '../../../img/0@3x.png';
import progress1 from '../../../img/1@3x.png';
import progress2 from '../../../img/2@3x.png';
import HandlerModal from '../HandlerModal';

const processImgMap = {
  0: progress0,
  1: progress1,
  2: progress2,
};

const {
  company: companyAuthority,
  project: projectAuthority,
} = config.authority;

const StyledDiv = styled.div`
  .nav {
    padding: 16px 24px;
    background-color: #fff;
  }
  .content {
    padding: 24px;
    .info {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 17px;
      padding-bottom: 24px;
      .item {
        display: flex;
        .t {
          flex-shrink: 0;
        }
        &.desc {
          grid-column-start: 1;
          grid-column-end: 4;
        }
        .val {
          flex-grow: 1;
        }
        .val.status {
          display: flex;
          justify-content: space-between;
          .btn {
            width: 64px;
            height: 22px;
            background: rgba(26, 114, 255, 0.12);
            border-radius: 2px;
            font-weight: 400;
            font-size: 12px;
            color: #1a72ff;
            line-height: 22px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
    .evidence {
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: 1px solid #e9e9e9;
      .list_img {
        display: flex;
        /* grid-template-columns: repeat(auto, auto); */
        margin-top: 10px;
        .ant-image {
          margin-left: 10px;
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
    .additional {
      padding-top: 24px;
      border-top: 1px solid #e9e9e9;
      .t {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      .additional_item {
        margin-top: 24px;
        .title {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 18px;
        }
        .desc {
          font-size: 12px;
          color: #909399;
          line-height: 18px;
          margin-top: 4px;
        }
        .list_img {
          display: flex;
          /* grid-template-columns: repeat(auto, auto); */
          .ant-image {
            margin-top: 10px;
            margin-left: 10px;
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }
    .record_list {
      .record {
        display: flex;
        padding-bottom: 24px;
        position: relative;
        .split {
          position: absolute;
          top: 0;
          width: 16px;
          z-index: 1;
          bottom: -4px;
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 0;
            height: 100%;
            transform: translateX(-50%);
            border-left: 1px dashed #e6e8eb;
          }
        }
        .icon {
          width: 16px;
          height: 16px;
          margin-top: 2px;
          z-index: 2;
        }
        .record_info {
          margin-left: 10px;
          flex-grow: 1;
          flex-shrink: 1;
          .info_t {
            font-weight: 500;
            font-size: 14px;
            color: #252c3a;
            line-height: 20px;
          }
          .desc {
            font-size: 14px;
            color: #7f8388;
            line-height: 20px;
            margin-top: 4px;
          }
          .result {
            background: #f0f2f5;
            padding: 14px;
            margin-top: 12px;
            width: 100%;
            .result_t {
              font-weight: 400;
              font-size: 14px;
              color: #303133;
              line-height: 20px;
            }
            .result_desc {
              font-weight: 400;
              font-size: 12px;
              color: #909399;
              line-height: 18px;
              margin-top: 4px;
            }
            .result_img {
              display: flex;
              margin-top: 10px;
              .ant-image {
                margin-left: 10px;
                &:first-of-type {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const processStatusMap = {
  0: '未处理',
  1: '已处理',
  2: '已撤诉',
  3: '上级处理中',
};
function parseSearch(str) {
  const list = str.replace('?', '').split('&');
  const info = {};
  list.forEach(item => {
    const [prop, val] = item.split('=');
    info[prop] = val;
  });
  return info;
}

function formatProcessUnit(info) {
  if (!info) {
    return '-';
  }
  if (info.unitType === 2) {
    return `${info.projectName}（${info.contractorTypeName}）`;
  }
  return info.companyName;
}
const PreviewMask = () => {
  return (
    <div className="ant-image-mask">
      <div className="ant-image-mask-info">
        <EyeOutlined style={{ marginInlineEnd: 0 }} />
      </div>
    </div>
  );
};

const browserHistory = createBrowserHistory();

export default function Detail() {
  const history = useHistory();
  const params = useParams();
  const query = parseSearch(history.location.search);
  const [visible, setVisible] = useState(false);
  // const [text, setText] = useState('');
  const [info, setInfo] = useState({});
  const [flowList, setFlowList] = useState([]);
  const [canEditComplaint, setCanEditComplaint] = useState(false);

  const getDetail = useCallback(async () => {
    console.log(params, '路由参数', history, query);
    const prop = query.projectId ? 'projectId' : 'companyId';
    const result = await req.complaints.getComplaintDetail({
      [query.type || 'complaintId']: params.id,
      [prop]: query.projectId ? query.projectId : query.companyId || null,
    });
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    const data = result.data || {};
    setInfo(data);
    console.log('详情', result);
  }, []);
  const getFlowList = useCallback(async () => {
    const prop = query.projectId ? 'projectId' : 'companyId';
    const result = await req.complaints.getComplaintFlowEvent({
      [query.type || 'complaintId']: params.id,
      [prop]: query.projectId ? query.projectId : query.companyId || null,
    });
    console.log('处理节点', result);
    if (result.code !== 0) {
      req.err.show(result);
      return;
    }
    const { list } = result.data;
    setFlowList(list || []);
    console.log('处理节点', result);
  }, []);
  /**
   * 到处弹窗点击确认，跳转新页面导出
   */
  const jump2Export = useCallback(() => {
    const w = window.open('_black');
    const prop = query.projectId ? 'projectId' : 'companyId';
    const data = {
      complaintId: info.complaintId,
      [prop]: query.projectId ? query.projectId : query.companyId || null,
    };
    w.location.href = `${
      browserHistory.location.hash ? '#' : ''
    }/worker_complaints/export?data=${encodeURIComponent(
      JSON.stringify(data)
    )}`;
  }, [info]);

  useEffect(() => {
    if (!info.complaintId) {
      return;
    }
    const data = {};
    if (info.processProjectId) {
      data.projectId = info.processProjectId;
    } else {
      data.companyId = info.processCompanyId;
    }
    Promise.all([
      req.authority.getMap(data),
      req.complaints[
        info.processProjectId ? 'showProjectModule' : 'showCompanyModule'
      ](data),
    ])
      .then(([r1, r2]) => {
        if (r1.code !== 0) {
          req.err.show(r1.msg);
          return;
        }
        if (r2.code !== 0) {
          req.err.show(r2.msg);
          return;
        }
        const authMap = r1.data;
        let manageAuth = false;
        if (data.projectId) {
          manageAuth =
            authMap[projectAuthority.MANAGE_COMPLAINT] ||
            authMap.company[companyAuthority.MANAGE_COMPLAINT];
        } else {
          manageAuth = authMap[companyAuthority.MANAGE_COMPLAINT];
        }
        const canEdit = r2.data.show || !!manageAuth;
        setCanEditComplaint(canEdit);
      })
      .catch(req.err.show);
  }, [info]);
  useEffect(() => {
    getDetail();
    getFlowList();
  }, []);
  return (
    <StyledDiv>
      <div className="nav">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a onClick={() => history.go(-1)}>投诉统计</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>投诉明细</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content">
        <Card
          title="投诉信息"
          bordered={false}
          extra={
            <Button type="primary" onClick={jump2Export}>
              导出
            </Button>
          }
        >
          <div className="info">
            <div className="item">
              <div className="t">工人姓名：</div>
              <div className="val">
                {info.workerName}
                {!info.enterProject ? '（未录入系统）' : ''}
              </div>
            </div>
            <div className="item">
              <div className="t">手机号：</div>
              <div className="val">{info.mobile || '-'}</div>
            </div>
            <div className="item">
              <div className="t">公司名称：</div>
              <div className="val">{info.companyName}</div>
            </div>
            <div className="item">
              <div className="t">投诉项目：</div>
              <div className="val">{info.projectName}</div>
            </div>
            <div className="item">
              <div className="t">投诉班组：</div>
              <div className="val">{info.groupName || '-'}</div>
            </div>
            <div className="item">
              <div className="t">涉及总人数：</div>
              <div className="val">{info.involvedPeopleCounts || '-'}</div>
            </div>
            <div className="item">
              <div className="t">涉及金额（元）：</div>
              <div className="val">{info.involvedAmount || '-'}</div>
            </div>
            <div className="item">
              <div className="t">投诉时间：</div>
              <div className="val">
                {moment(info.complaintTime).format('YYYY-MM-DD HH:mm:ss')}
              </div>
            </div>
            <div className="item">
              <div className="t">处理状态：</div>
              <div className="val status">
                <span>{processStatusMap[info.processStatus]}</span>
                {info.processStatus === 0 && canEditComplaint ? (
                  <div
                    className="btn"
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    处理投诉
                  </div>
                ) : null}
              </div>
            </div>
            <div className="item">
              <div className="t">处理节点：</div>
              <div className="val">{info.eventName}</div>
            </div>
            {info.processStatus === 0 ? (
              <div className="item">
                <div className="t">处理单位：</div>
                <div className="val">{formatProcessUnit(info.processUnit)}</div>
              </div>
            ) : null}
            <div className="item desc">
              <div className="t">问题描述：</div>
              <div className="val">{info.issueDescription}</div>
            </div>
          </div>
          {info.fileUrls && info.fileUrls.length ? (
            <div
              className="evidence"
              style={{
                paddingBottom:
                  info.replenishEvidenceList &&
                  info.replenishEvidenceList.length
                    ? 24
                    : 0,
              }}
            >
              <div className="t">凭证材料</div>
              <div className="list_img">
                <Image.PreviewGroup>
                  {info.fileUrls
                    ? info.fileUrls.map((item, i) => (
                        <Image key={i} src={item} width={60} height={60} />
                      ))
                    : null}
                </Image.PreviewGroup>
              </div>
            </div>
          ) : null}
          {info.replenishEvidenceList && info.replenishEvidenceList.length ? (
            <div className="additional">
              <div className="t">补充证据</div>
              {info.replenishEvidenceList.map(item => (
                <div className="additional_item" key={item.createTime}>
                  <div className="title">{item.text}</div>
                  <div className="desc">
                    补充时间：
                    {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                  <div className="list_img">
                    <Image.PreviewGroup>
                      {item.fileUrls
                        ? item.fileUrls.map((url, i) => (
                            <Image key={i} src={url} width={60} height={60} />
                          ))
                        : null}
                    </Image.PreviewGroup>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </Card>
        <Card title="处理记录" bordered={false} style={{ marginTop: 24 }}>
          <div className="record_list">
            {flowList.map((item, index) => (
              <div className="record">
                <img
                  src={processImgMap[item.processStatus || 0]}
                  className="icon"
                  alt=""
                />
                <div className="record_info">
                  <div className="info_t">{item.eventName}</div>
                  {item.name ? (
                    <div className="desc">操作人：{item.name}</div>
                  ) : null}
                  {item.createTime ? (
                    <div className="desc">
                      操作时间：
                      {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  ) : null}
                  {(function test() {
                    if (item.processStatus === 0 && !item.text) {
                      return (
                        <div className="result">
                          <div className="result_t">处理结果</div>
                          <div className="result_desc">处理中</div>
                        </div>
                      );
                    }
                    if (item.processStatus !== 2 && item.text) {
                      return (
                        <div className="result">
                          <div className="result_t">处理结果</div>
                          {item.processStatus !== 2 ? (
                            <div className="result_desc">
                              {item.processStatus === 0 ? '处理中' : item.text}
                            </div>
                          ) : null}
                          <div className="result_img">
                            <Image.PreviewGroup>
                              {item.fileUrls
                                ? item.fileUrls.map((url, i) => (
                                    <Image
                                      key={i}
                                      width={48}
                                      height={48}
                                      src={url}
                                      preview={{ mask: <PreviewMask /> }}
                                    />
                                  ))
                                : null}
                            </Image.PreviewGroup>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })()}
                </div>
                {index !== flowList.length - 1 ? (
                  <div className="split" />
                ) : null}
              </div>
            ))}
          </div>
        </Card>
      </div>
      <HandlerModal
        visible={visible}
        setVisible={setVisible}
        complaintId={query.type ? info.complaintId : params.id}
        success={() => {
          getDetail();
          getFlowList();
        }}
      />
    </StyledDiv>
  );
}
