import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import QrcodeModal from './QrcodeModal/index';
import req from '../../req/index';
import { getLoginUrl, searchParser } from '../../utils/index';

function Login() {
  const location = useLocation();
  const history = useHistory();
  const hostnameRef = useRef(window.location.hostname);

  /**
   * 获取当前公司
   */
  const onSucc = useCallback(() => {
    if (location.search) {
      const urlData = searchParser(location.search) || {};
      if (urlData.url) {
        const { url } = urlData;
        if (/^http/.test(url)) {
          window.location.href = decodeURIComponent(url);
          return;
        }
        history.replace(decodeURIComponent(url));
        return;
      }
      history.replace('/');
    }

    req.company
      .getMyCrt()
      .then(res => {
        if (res.code === 0) {
          if (res.data && res.data.id) {
            history.push('/');
          } else {
            history.push('/register_company');
          }
        } else {
          req.err.show(res.msg);
        }
      })
      .catch(req.err.show);
  }, []);

  useEffect(() => {
    if (hostnameRef.current === 'localhost') {
      console.log('本地环境登录，不跳外部');
      return;
    }
    const { sessionId, url } = searchParser(location.search) || {};
    if (sessionId) {
      req.session.set(sessionId);
      onSucc();
    } else {
      let q = '?site=LABOR';
      if (url) {
        q += `&url=${url}`;
      }
      window.location.href = `${getLoginUrl()}${q}`;
    }
  }, []);

  if (hostnameRef.current === 'localhost') {
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        data-flex="main:center cross:center"
      >
        <QrcodeModal onSucc={onSucc} />
      </div>
    );
  }

  return null;
}

export default Login;
