const api = {
  getAttendRecordApproveInfo: {
    url: '/bi-api/api/process/getAttendanceApplyDetails',
  },
  dealWithAttendRecordApprove: {
    url: '/bi-api/api/process/auditAttendanceApply',
    method: 'POST',
  },
  getModifyAttendRecordInfo: {
    url: '/bi-api/api/process/getModifyAttendanceRecordApplyDetails',
  },
  dealWithModifyAttendRecord: {
    url: '/bi-api/api/process/approveModifyAttendanceRecordApply',
    method: 'POST',
  },
};

export default api;
