import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'antd';
// import navMap from '../../../config/nav_map';
import SeachBox from './SeachBox';
import req from '../../req';
// import { timestamp2dateTime } from '../../utils/date';

const StyledDiv = styled.div`
  margin: 24px;
  padding: 23px 32px;
  border-radius: 2px;
  background-color: #fff;
  .blue-color {
    color: #1890ff;
  }
  .red-color {
    color: #fb3a39;
  }
  .green-color {
    color: #60c48e;
  }
  .video-react .video-react-big-play-button {
    position: absolute;
    left: 100px;
    top: 100px;
  }
`;

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      tableLoading: false,
      firstConditon: {},
    };
    this.timer = '';
    this.searchOpt = null;
    this.onSearch = this.onSearch.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
  }

  componentDidMount() {
    this.onSearch({
      startTime: '',
      endTime: '',
      userName: '',
    });
    const { location } = this.props;
    if (location.search === '') {
      return;
    }
    const data = JSON.parse(new URLSearchParams(location.search).get('data'));
    if (data) {
      this.setState({
        firstConditon: data,
      });
      this.onSearch(data);
    }

    console.log(this.player);
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  componentDidUpdate() {}

  onSearch(options) {
    console.log('options', options);
    const that = this;
    this.searchOpt = options;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      that.setState(
        {
          page: 1,
          tableLoading: true,
        },
        () => {
          that.getList(options);
        }
      );
    }, 100);
  }

  /**
   * 表格变动
   */
  onTableChange(pagination) {
    const that = this;
    const { searchOpt } = this;
    this.setState(
      {
        page: pagination.current,
      },
      () => that.getList(searchOpt)
    );
  }

  getList(searchOpt) {
    console.log('searchOpt', searchOpt);
    const that = this;
    const { pageSize, page } = this.state;
    req.sys
      .searchOperationLogs(
        Object.assign(searchOpt, {
          page,
          size: pageSize,
        })
      )
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        const { records, total } = res.data;
        that.setState({
          list: records || [],
          total: total || 0,
          tableLoading: false,
        });
      })
      .catch(req.err.show);
  }

  render() {
    const {
      list,
      pageSize,
      total,
      page,
      tableLoading,
      firstConditon,
    } = this.state;
    const rowKey = row => row.id;
    const columns = [
      {
        title: '用户名',
        align: 'left',
        dataIndex: 'userName',
        key: 'userName',
        width: 100,
        render(txt) {
          return <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{txt}</div>;
        },
      },
      {
        title: '账号ID',
        align: 'left',
        dataIndex: 'userId',
        key: 'userId',
        width: 120,
        render(txt) {
          return <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{txt}</div>;
        },
      },
      {
        title: '操作时间',
        align: 'left',
        dataIndex: 'opTime',
        key: 'opTime',
        width: 120,
        render(txt) {
          return <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{txt}</div>;
        },
      },
      {
        title: '描述',
        align: 'left',
        dataIndex: 'description',
        key: 'description',
        width: 150,
        render(txt) {
          return (
            <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{txt || '-'}</div>
          );
        },
      },
      {
        title: 'IP地址',
        align: 'left',
        dataIndex: 'ipAddress',
        key: 'ipAddress',
        width: 100,
        render(txt) {
          return (
            <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{txt || '-'}</div>
          );
        },
      },
    ];

    return (
      <>
        <div
          style={{
            color: '#212732',
            backgroundColor: '#fff',
            lineHeight: '60px',
            paddingLeft: 20,
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          日志审计
        </div>
        <StyledDiv>
          <SeachBox onSearch={this.onSearch} firstConditon={firstConditon} />
          <Table
            dataSource={list}
            rowKey={rowKey}
            scroll={{
              x: columns
                .map(item => item.width || 0)
                .reduce((pV, nV) => pV + nV),
            }}
            style={{
              color: 'rgba(0, 0, 0, 0.65)',
            }}
            columns={columns}
            onChange={this.onTableChange}
            pagination={{
              current: page,
              total,
              pageSize,
            }}
            loading={tableLoading}
          />
        </StyledDiv>
      </>
    );
  }
}
Page.propTypes = {
  location: PropTypes.object.isRequired,
};
export default withRouter(Page);
