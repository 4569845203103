import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';

const AboutRole = loadable(() => import('./AboutRole'));
const AboutBlacklist = loadable(() => import('./AboutBlacklist'));
const AboutWorkerComplaints = loadable(() => import('./AboutWorkerComplaints'));

function Posts() {
  return (
    <Switch>
      <Route path="/posts/about_role" component={AboutRole} />
      <Route path="/posts/about_blacklist" component={AboutBlacklist} />
      <Route
        path="/posts/about_worker_complaints"
        component={AboutWorkerComplaints}
      />
      <Route render={() => <Redirect to="/404" />} />
    </Switch>
  );
}

export default Posts;
