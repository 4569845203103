class Store {
  constructor() {
    this.nextId = 0;
    this.data = {
      valueMap: {},
    };
  }

  getId() {
    this.nextId += 1;
    return this.nextId;
  }

  getInfo(id) {
    const { valueMap } = this.data;
    return valueMap[id];
  }

  update(id, val) {
    const { valueMap } = this.data;
    valueMap[id] = val;
    return true;
  }

  clear(id) {
    const { valueMap } = this.data;
    valueMap[id] = '';
  }

  delete(id) {
    const { valueMap } = this.data;
    delete valueMap[id];
  }
}
const store = new Store();
// module.exports = new Store();
export default store;
