import React, { useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';

const option = {
  title: {
    text: '处理率', // 主标题文本
    subtext: '', // 副标题文本
    x: 'center',
    y: 32,
    textStyle: {
      fontWeight: 400,
      fontSize: 12,
      color: '#909399',
      align: 'center',
      left: 10,
    },
    subtextStyle: {
      fontSize: 15,
      color: '#303133',
      align: 'center',
      verticalAlign: 'middle',
    },
  },
  grid: {
    left: '10%',
    right: '2%',
    bottom: '10%',
    containLabel: true,
  },
  // tooltip: {
  //   trigger: 'item',
  //   formatter: '{d}%',
  //   show: true,
  // },
  series: [
    {
      name: 'access',
      type: 'pie',
      radius: ['56%', '95%'],
      center: ['50%', '50%'],
      startAngle: 90, // 开始的角度
      avoidLabelOverlap: false,
      hoverAnimation: false,
      label: {
        normal: {
          show: false,
          position: 'center',
        },
        emphasis: {
          show: false,
        },
      },
      labelLine: {
        normal: {
          show: false,
        },
      },
      data: [
        {
          value: 80,
          name: 'processBar',
          selected: false,
          itemStyle: {
            color: '#1A72FF',
          },
          // label: {
          //   normal: {
          //     // 是显示标签
          //     show: true,
          //     position: 'center',
          //     fontSize: 16,
          //     // 标签内容格式器，支持字符串模板和回调函数两种形式，字符串模板与回调函数返回的字符串均支持用 \n 换行
          //     formatter:'{d}%\n\n完成百比分',
          //   }
          // },
        },
        {
          value: 100 - 80,
          name: 'processWrap',
          itemStyle: {
            color: '#F0F2F5',
          },
          label: {
            normal: {
              show: false,
            },
          },
        },
      ],
    },
  ],
};

export default function PieProgressChart({ signRate }) {
  const echartRef = useRef(null);
  useEffect(() => {
    console.log(signRate, '比例');
    echartRef.current.getEchartsInstance().setOption({
      title: {
        subtext: `${signRate}%`,
      },
      series: [
        {
          name: 'access',
          data: [
            {
              name: 'processBar',
              value: signRate,
              itemStyle: {
                color: '#1890FF',
              },
            },
            {
              name: 'processWrap',
              value: 100 - signRate,
              itemStyle: {
                color: '#CCE6FF',
              },
            },
          ],
        },
      ],
    });
  }, [signRate]);
  return (
    <div className="pie_progress_chart" style={{ width: 110, height: 110 }}>
      <ReactEcharts
        style={{ width: '100%', height: '100%' }}
        option={option}
        ref={echartRef}
      />
    </div>
  );
}

PieProgressChart.propTypes = {
  signRate: PropTypes.number.isRequired,
};
