import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ConfigProvider, message, Modal } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import Login from './pages/Login';
import LoginWithSession from './pages/LoginWithSession';
import ApplyTrail from './pages/ApplyTrail';
import dataExport from './pages/data_export';
import projectPages from './pages/project';
import FrameLayout from './pages/FrameLayout';
import NoMatch from './pages/404';
import Reload from './pages/Reload';
import Posts from './pages/Posts';
import history from './history';
import projectItemExport from './pages/project/ProjectItem/ProjectData/Export';
import companyOverviewExport from './pages/company/Overview/OverviewData/Export';
import companyOverviewExportContract from './pages/company/Overview/OverviewData/ExportContract';
import WorkerComplaintsExport from './pages/WorkerComplaints/Export';
import BlacklistExport from './pages/Blacklist/Export';

import 'flex.css/dist/data-flex.css';

moment.locale('zh-cn');

history.listen(() => {
  Modal.destroyAll();
  message.destroy();
  if (document.body.scrollTop || document.documentElement.scrollTop > 0) {
    window.scrollTo(0, 0);
  }
});

message.config({
  maxCount: 1,
});

const App = () => (
  <ConfigProvider locale={zhCN}>
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/login_with_session/:id" component={LoginWithSession} />
        <Route path="/posts" component={Posts} />
        <Route path="/apply_trail" component={ApplyTrail} />
        <Route
          path="/data_export/salary/export"
          component={dataExport.SalaryExport}
        />
        <Route
          path="/data_export/attend/export"
          component={dataExport.AttendExport}
        />
        <Route
          path="/data_export/roster/export"
          component={dataExport.RosterExport}
        />
        <Route
          path="/data_export/org/export"
          component={dataExport.OrgExport}
        />
        <Route
          path="/project/overview/export"
          component={projectPages.OverviewExport}
        />
        <Route
          path="/worker_complaints/export"
          component={WorkerComplaintsExport}
        />
        <Route path="/blacklist/export" component={BlacklistExport} />
        {/* <Route path="/project/overview" component={ProjectOverview} /> */}
        <Route
          path="/project/project_item/project_data/export"
          component={projectItemExport}
        />
        <Route
          path="/company/overview/overview_data/export"
          component={companyOverviewExport}
        />
        <Route
          path="/company/overview/overview_data/exportContract"
          component={companyOverviewExportContract}
        />
        <Route path="/404" component={NoMatch} />
        <Route path="/reload" component={Reload} />
        <Route path="/" component={FrameLayout} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
    </Router>
  </ConfigProvider>
);

export default App;
