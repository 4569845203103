import req from './req/index';

export const thunkMiddleware = store => next => action => {
  if (action.type === 'changeMessageCount') {
    req.sys
      .getMessageList({
        page: 1,
        size: 1,
        type: '01',
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        store.dispatch({
          type: 'assignMessageCount',
          unReadMessageCount: res.data.unReadCount,
        });
      })
      .catch(req.err.show);
  }
  if (action.type === 'changeBlacklistCount') {
    req.company
      .getProcessingBlackMemberApplyCounts()
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        store.dispatch({
          type: 'assignBlacklistCount',
          blacklistCount: res.data.total,
        });
      })
      .catch(req.err.show);
  }
  next(action);
};

export default {
  thunkMiddleware,
};
