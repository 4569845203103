import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Input, Button, Modal } from 'antd';
import styled from 'styled-components';

import config from '../../../../config';
import req from '../../../../req';

import AuthBox from '../AuthBox';
import PositionSelect from '../PositionSelect';

const posTypeMap = {
  COMPANY: '00',
  PROJECT: '01',
};

const { applyFormTypeMap, applyFormTipMap } = config.joinCompany;
const { JOIN_COMPANY } = config.message.typeMap;

const StyledManagerDrawer = styled(Drawer)`
  .ant-drawer-wrapper-body {
    overflow: hidden;
    height: 100%;
    padding-bottom: 110px;
  }
  .group_name {
    color: rgba(33, 39, 50, 0.85);
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
  }
  .footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: center;
  }
`;

const StyledUserItem = styled.div`
  .avt {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }

  .info {
    margin-left: 12px;
    line-height: normal;

    .name {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.65);
    }

    .code {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 4px;
    }
  }
`;

function UserItem({ info }) {
  return (
    <StyledUserItem data-flex="main:left cross:center">
      <img
        data-flex-box="0"
        className="avt"
        src={info.img || config.defaultAvt}
        alt="avt"
      />
      <div data-flex-box="1" className="info">
        <div className="name">{info.userName || '...'}</div>
        {info.workCode ? (
          <div className="code">工号：{info.workCode}</div>
        ) : null}
      </div>
    </StyledUserItem>
  );
}

UserItem.propTypes = {
  info: PropTypes.object.isRequired,
};

function ManagerDrawer({ aid, visible, onCancel, onFinish, form }) {
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [info, setInfo] = useState({});
  const [authData, setAuthData] = useState({
    superAdmin: false,
    authorities: [],
  });
  const initAuthDataRef = useRef({
    superAdmin: false,
    authorities: [],
  });
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };
  const onSubmit = useCallback(() => {
    validateFields((err, val) => {
      if (err) {
        console.log(err, val);
        return;
      }
      const companyPositionIds = [];
      const projectPositionIds = [];
      val.position.forEach(p => {
        if (p.positionType === posTypeMap.COMPANY) {
          companyPositionIds.push(p.id);
        } else {
          projectPositionIds.push(p.id);
        }
      });
      Modal.confirm({
        title: '提示',
        content: applyFormTipMap[applyFormTypeMap.agree],
        onOk() {
          req.sys
            .approveMyJoinApply(
              JSON.stringify({
                id: info.recordId,
                status: applyFormTypeMap.agree,
                userName: val.name,
                companyPositionIds,
                projectPositionIds,
                superAdmin: authData.superAdmin ? '01' : '00',
                permissionCodes: authData.superAdmin
                  ? []
                  : authData.authorities,
              })
            )
            .then(res => {
              if (res.code !== 0) {
                req.err.show(res.msg);
                return;
              }
              onFinish();
            })
            .catch(req.err.show);
        },
      });
    });
  }, [info, authData]);
  const onAuthBoxChange = useCallback(value => {
    setAuthData(value);
  }, []);
  useEffect(() => {
    let didCancel = false;
    req.sys
      .getMessageDetails({
        messageId: aid,
      })
      .then(res => {
        if (didCancel) {
          return;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        const { joinCompanyApplyDTO, messageType } = res.data;
        let codes = [];
        if (messageType === config.message.typeMap.JOIN_COMPANY) {
          codes = Array.prototype.concat.apply(
            codes,
            joinCompanyApplyDTO.companyPositionList.map(p => p.permissionCodes)
          );
        } else {
          codes = Array.prototype.concat.apply(
            codes,
            joinCompanyApplyDTO.projectPositionList.map(p => p.permissionCodes)
          );
          codes.push(config.authority.project.VIEW_DASHBOARD);
        }
        codes = [...new Set(codes)];
        initAuthDataRef.current.authorities = codes;
        setAuthData(s => ({
          ...s,
          authorities: codes,
        }));
        setInfo(res.data);
        setFieldsValue({
          name: joinCompanyApplyDTO.userName,
        });
      })
      .catch(err => {
        if (didCancel) {
          return;
        }
        req.err.show(err);
      });
    return () => {
      didCancel = true;
    };
  }, [aid]);
  const { joinCompanyApplyDTO, messageType } = info;
  if (!joinCompanyApplyDTO) {
    return null;
  }
  const {
    applicant,
    companyPositionList,
    projectPositionList,
  } = joinCompanyApplyDTO;
  const userInfo = { ...applicant, userName: joinCompanyApplyDTO.userName };
  const initialPos = companyPositionList.concat(projectPositionList);
  const posType =
    posTypeMap[messageType === JOIN_COMPANY ? 'COMPANY' : 'PROJECT'];
  return (
    <StyledManagerDrawer
      title="添加成员"
      width={424}
      visible={visible}
      onClose={onCancel}
      bodyStyle={{ overflow: 'auto', height: '100%' }}
    >
      <Form className="form" {...formItemLayout}>
        <div className="group_name">基础信息</div>
        <Form.Item label="  " colon={false}>
          <UserItem info={userInfo} />
        </Form.Item>
        {/* <Form.Item label="手机号/工号">
          {getFieldDecorator('phone', {
            required: true,
            rules: [
              {
                required: true,
                message: '请输入手机号/工号',
              },
            ],
            initialValue: '',
          })(<Input placeholder="请输入" />)}
        </Form.Item> */}
        <Form.Item label="姓名">
          {getFieldDecorator('name', {
            required: true,
            rules: [
              {
                required: true,
                message: '请输入姓名',
              },
            ],
            initialValue: '',
          })(<Input placeholder="请输入" />)}
        </Form.Item>
        <Form.Item label="职位">
          {getFieldDecorator('position', {
            initialValue: initialPos,
          })(<PositionSelect type={posType} />)}
        </Form.Item>
        <div className="group_name">权限</div>
        <AuthBox
          initValue={initAuthDataRef.current}
          onChange={onAuthBoxChange}
          type={posType}
        />
      </Form>
      <div className="footer">
        <Button style={{ marginRight: 8 }} onClick={onCancel}>
          取消
        </Button>
        <Button type="primary" onClick={onSubmit}>
          添加
        </Button>
      </div>
    </StyledManagerDrawer>
  );
}

ManagerDrawer.propTypes = {
  // cid: PropTypes.string,
  // pid: PropTypes.string,
  aid: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  form: PropTypes.object.isRequired,
};

ManagerDrawer.defaultProps = {
  // cid: '',
  // pid: '',
  aid: '',
  visible: false,
  onCancel: null,
  onFinish: null,
};

export default Form.create({ name: 'manager_drawer' })(ManagerDrawer);
