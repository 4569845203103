import React, { useState, useCallback } from 'react';
import { Modal, Form, Button, Upload, Input, message } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import req from '../../../req';
import reqConfig from '../../../req/config';
import session from '../../../req/api/session';

const StyledUpload = styled.div`
  .tips {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 8px;
    line-height: 20px;
    color: #7f8388;
  }
`;
const StyledDiv = styled.div`
  .ant-input-textarea-show-count {
    position: relative;
  }
  .ant-input-textarea-show-count::after {
    position: absolute;
    float: none;
    right: 12px;
    bottom: 25px;
  }
`;
const { TextArea } = Input;

const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

function beforeUpload(file) {
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图片大小不能超过2MB');
  }
  return isLt2M;
}

export default function HandlerModal({
  visible,
  setVisible,
  complaintId,
  success,
}) {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  );
  const [fileList, setFileList] = useState([
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url:
    //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-2',
    //   name: 'image.png',
    //   status: 'done',
    //   url:
    //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-3',
    //   name: 'image.png',
    //   status: 'done',
    //   url:
    //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-4',
    //   name: 'image.png',
    //   status: 'done',
    //   url:
    //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-xxx',
    //   percent: 50,
    //   name: 'image.png',
    //   status: 'uploading',
    //   url:
    //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-5',
    //   name: 'image.png',
    //   status: 'error',
    // },
  ]);
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    console.log(file);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };
  const handleChange = useCallback(({ fileList: newFileList }) => {
    // console.log(newFileList, 'setFileList(newFileList)');
    const filterList = newFileList
      .filter(f => f.status === 'done' || f.status === 'uploading')
      .map(f => {
        if (f.response && f.response.data && f.response.data.httpUrl) {
          return { url: f.response.data.httpUrl, status: f.status, uid: f.uid };
        }
        return f;
      });
    console.log(filterList, 'setFileList(newFileList)');
    setFileList(filterList);
  }, []);
  return (
    <>
      <Modal
        title="处理投诉"
        width={578}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            onClick={() => {
              form.resetFields();
              setFileList([]);
            }}
          >
            重置
          </Button>,
          <Button
            onClick={() => {
              form.resetFields();
              setFileList([]);
              setVisible(false);
            }}
          >
            取消
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              form.validateFields().then(async res => {
                const result = await req.complaints.handleWorkerComplaint(
                  JSON.stringify({
                    text: (res.text || '').slice(0, 500),
                    complaintId,
                    fileUrls: fileList.map(item => item.url),
                  })
                );
                if (result.code !== 0) {
                  req.err.show(result.msg);
                  return;
                }
                setVisible(false);
                // getDetail();
                message.success('处理成功');
                form.resetFields();
                setFileList([]);
                if (success) {
                  success();
                }
                console.log('处理投诉', result);
              });
            }}
          >
            确定
          </Button>,
        ]}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          layout="horizontal"
          form={form}
          initialValues={{ text: '' }}
        >
          <Form.Item
            name="text"
            label="处理说明"
            required
            rules={[
              {
                validator(rule, value) {
                  if (value) {
                    return Promise.resolve();
                  }
                  console.log(value, '错误0');
                  return Promise.reject(new Error('请填写处理说明'));
                },
              },
            ]}
          >
            <StyledDiv>
              <TextArea
                showCount
                maxLength={500}
                style={{ height: 120, resize: 'none' }}
                placeholder="问题的处理说明（不少于10字, 不超过500字）"
              />
            </StyledDiv>
          </Form.Item>
          <Form.Item name="fileList" label="上传图片">
            <StyledUpload>
              <div className="tips">
                最多上传10张（单张不能超过2MB，仅支持png、jpg、jpeg格式的图片）
              </div>
              <Upload
                action={reqConfig.uploadAction}
                headers={{ sessionId: session.get() }}
                multiple
                data={{ type: 8 }}
                maxCount={10}
                listType="picture-card"
                accept="image/png, image/jpeg, image/jpg"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
              >
                {fileList.length >= 10 ? null : uploadButton}
              </Upload>
            </StyledUpload>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
}

HandlerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  complaintId: PropTypes.string.isRequired,
  success: PropTypes.func.isRequired,
};
