const api = {
  getMyInfo: {
    url: '/bi-api/api/sys/currentUserInfo',
  },
  bindPhone: {
    url: '/bi-api/api/sys/bind/:userId',
    method: 'POST',
  },
  checkTrailAlailable: {
    url: '/bi-api/api/sys/isExperienceUser',
  },
  initTrail: {
    url: '/bi-api/api/sys/initExperienceCompanys',
  },
  exitTrail: {
    url: '/bi-api/api/sys/exitExperience',
    method: 'POST',
  },
};

export default api;
