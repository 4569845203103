export default {
  install(R) {
    /**
     * 获取权限列表
     */
    function getAuthorities(data) {
      const { projectId, companyId, userId } = data;
      let p = R[data.projectId ? 'project' : 'company'];
      p = p[data.userId ? 'getAuthorityList' : 'getCrtAuthorityList'];
      const d = {};
      if (companyId) {
        d.companyId = companyId;
      }
      if (userId) {
        d.userId = userId;
      }
      if (projectId) {
        d.projectId = projectId;
        d.childProjectId = projectId;
      }
      return p(d);
    }
    /**
     * 获取权限字典
     */
    function getAuthoritiesDict(data) {
      return R.cachify('sys.getCompanyAuthorityList')(data);
    }
    const r = R;
    r.authority = {
      getList(data = {}) {
        return getAuthorities(data).then(res => {
          if (res.code !== 0) {
            return res;
          }
          const list =
            res.data[data.projectId ? 'projectPermissions' : 'permissions'];
          return {
            code: 0,
            data: {
              list,
            },
          };
        });
      },
      getMap(data = {}) {
        return getAuthorities(data).then(res => {
          if (res.code !== 0) {
            return res;
          }
          let d = null;
          if (data.projectId) {
            const {
              superProjectAdmin,
              projectPermissions,
              companyPermissionInfo,
            } = res.data;
            const {
              superAdmin,
              topSupAdmin,
              permissions,
            } = companyPermissionInfo || {
              permissions: [],
            };
            d = projectPermissions.reduce(
              (pV, cV) => {
                const p = pV;
                p[cV.code] = true;
                return pV;
              },
              {
                superAdmin: superProjectAdmin === '01',
                company: permissions.reduce(
                  (pV, cV) => {
                    const p = pV;
                    p[cV.code] = true;
                    return pV;
                  },
                  {
                    superAdmin: superAdmin === '01',
                    topSupAdmin: topSupAdmin === '01',
                  }
                ),
              }
            );
          } else {
            const { superAdmin, topSupAdmin, permissions } = res.data;
            d = permissions.reduce(
              (pV, cV) => {
                const p = pV;
                p[cV.code] = true;
                return pV;
              },
              {
                superAdmin: superAdmin === '01',
                topSupAdmin: topSupAdmin === '01',
              }
            );
          }
          return {
            code: 0,
            data: d,
          };
        });
      },
      getDicts(data) {
        return getAuthoritiesDict(data);
      },
      getDictsMap(data) {
        return getAuthoritiesDict(data).then(res => {
          if (res.code !== 0) {
            return res;
          }
          return {
            code: 0,
            data: res.data.list.reduce((pV, cV) => {
              const p = pV;
              p[cV.code] = cV;
              return pV;
            }, {}),
          };
        });
      },
    };
  },
};
