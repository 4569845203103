import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import styled from 'styled-components';

import req from '../../../../req';

import PositionModal from '../../../organization/Overview/DataTable/PositionModal';

const posTypeMap = {
  COMPANY: '00',
  PROJECT: '01',
};

const StyledPositionSelect = styled.div`
  padding: 16px 0;

  .group {
    margin-bottom: 8px;
    .hd {
      font-size: 12px;
      color: #858b96;
      padding: 0 24px;
    }

    .list {
      margin-top: 8px;
      padding-left: 24px;
      flex-wrap: wrap;

      .pos,
      .add {
        width: 66px;
        height: 24px;
        line-height: 24px;
        border-radius: 2px;
        background-color: #f3f3f7;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        color: #858b96;
        padding: 0 2px;
        margin-right: 8px;
        margin-bottom: 8px;
        cursor: pointer;

        &.z-crt {
          background-color: rgba(55, 127, 238, 0.1);
          color: #377fee;
        }
      }

      .add {
        color: #377fee;
      }
    }
  }
`;

function PositionSelect({ type, onChange, value }) {
  const [companyPos, setCompanyPos] = useState([]);
  const [projectPos, setProjectPos] = useState([]);
  const [val, setVal] = useState(value);
  const [positionModalVisible, setPositionModalVisible] = useState(false);
  const [posType, setPosType] = useState('');
  useEffect(() => {
    let didCancel = false;
    if (positionModalVisible) {
      return null;
    }
    if (type === posTypeMap.COMPANY) {
      req.company
        .getPositionList({
          positionType: posTypeMap.COMPANY,
        })
        .then(res => {
          if (didCancel) {
            return;
          }
          if (res.code !== 0) {
            req.err.show(res);
            return;
          }
          setCompanyPos(res.data.list);
        })
        .catch(err => {
          if (didCancel) {
            return;
          }
          req.err.show(err);
        });
    }
    req.company
      .getPositionList({
        positionType: posTypeMap.PROJECT,
      })
      .then(res => {
        if (didCancel) {
          return;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        setProjectPos(res.data.list);
      })
      .catch(err => {
        if (didCancel) {
          return;
        }
        req.err.show(err);
      });
    return () => {
      didCancel = true;
    };
  }, [positionModalVisible]);
  const onPosTap = useCallback(
    p => {
      setVal(s => {
        const idx = s.findIndex(v => v.id === p.id);
        const list = idx >= 0 ? s.filter((v, i) => i !== idx) : s.concat([p]);
        onChange(list);
        return list;
      });
    },
    [onChange]
  );
  const add = useCallback(v => {
    setPosType(v);
    setPositionModalVisible(true);
  }, []);
  const dropdownRender = useCallback(() => {
    const valMap = val.reduce((pV, cV) => {
      const p = pV;
      p[cV.id] = true;
      return p;
    }, {});
    return (
      <StyledPositionSelect onMouseDown={e => e.preventDefault()}>
        {type === posTypeMap.COMPANY ? (
          <div className="group">
            <div className="hd">公司</div>
            <div data-flex="main:left" className="list">
              {companyPos.map(p => (
                <div
                  data-flex-box="0"
                  onClick={() => onPosTap(p)}
                  className={`pos${valMap[p.id] ? ' z-crt' : ''}`}
                  key={p.id}
                >
                  {p.name}
                </div>
              ))}
              <div
                data-flex-box="0"
                onClick={() => add(posTypeMap.COMPANY)}
                className="add"
              >
                <PlusOutlined className="i" /> 添加
              </div>
            </div>
          </div>
        ) : null}
        <div className="group">
          <div className="hd">项目</div>
          <div data-flex="main:left" className="list">
            {projectPos.map(p => (
              <div
                data-flex-box="0"
                onClick={() => onPosTap(p)}
                className={`pos${valMap[p.id] ? ' z-crt' : ''}`}
                key={p.id}
              >
                {p.name}
              </div>
            ))}
            <div
              data-flex-box="0"
              onClick={() => add(posTypeMap.PROJECT)}
              className="add"
            >
              <PlusOutlined className="i" /> 添加
            </div>
          </div>
        </div>
      </StyledPositionSelect>
    );
  }, [companyPos, projectPos, val, type]);
  const onDeselect = useCallback(
    v => {
      onPosTap({
        id: v.key,
      });
    },
    [onPosTap]
  );
  const onPositionModalCancel = useCallback(() => {
    setPositionModalVisible(false);
  }, []);
  const onPositionModalOk = useCallback(() => {
    setPositionModalVisible(false);
  }, []);
  return (
    <React.Fragment>
      <Select
        mode="multiple"
        placeholder="请选择职位"
        dropdownRender={dropdownRender}
        value={val.map(v => ({ label: v.name, key: v.id }))}
        labelInValue
        onDeselect={onDeselect}
      />
      <PositionModal
        visible={positionModalVisible}
        onCancel={onPositionModalCancel}
        onOk={onPositionModalOk}
        positionType={posType}
      />
    </React.Fragment>
  );
}

PositionSelect.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array,
};

PositionSelect.defaultProps = {
  type: null,
  onChange: null,
  value: [],
};

export default PositionSelect;
