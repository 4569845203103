import session from '../req/api/session';

export const isProd = window.location.host === 'labor.emodor.com';

export const shareLinkMap = {
  company: '01',
  project: '00',
};

/**
 * 生成分享链接
 */
export function makeShareLink(token) {
  const { protocol, host } = window.location;
  const link = `${protocol}//${host}/dashboard/#/${token}`;
  return link;
}

/**
 * 生成复制内容
 */
export function makeCopyData(info) {
  const { projectId, token, password } = info;
  const link = makeShareLink(
    token,
    projectId ? shareLinkMap.project : shareLinkMap.company
  );
  return {
    link,
    pwd: password,
    text: `【墨计数据星盘】链接:${link} 登录密码:${password}`,
  };
}

/**
 * query解析
 */
export function searchParser(search) {
  if (!search) {
    return null;
  }
  const urlArr = search.substr(1).split('&');
  const urlData = {};
  urlArr.map(item => {
    const [key, val] = item.split('=');
    urlData[key] = val;
    return item;
  });
  return urlData;
}

/**
 * 打开星盘
 */
export function prepareDashboard(info) {
  const { token, loginId } = info;
  console.log('show message', token, loginId);
  const link = `${makeShareLink(token)}?sessionId=${session.get()}`;
  const fromRouter = window.location.hash.split('#/')[1];
  localStorage.setItem('fromRouter', fromRouter);
  console.log('fromRouter', localStorage.getItem('fromRouter'));
  // setCookie(`loginId_${token}`, loginId);
  return link;
}

/**
 * 数字补全两位
 */
export function fix2(n) {
  return n > 9 ? n : `0${n}`;
}

/**
 * 时间文本正常化
 */
export function normalizeTimeStr(str, showPrefix = false) {
  if (!str) {
    return '';
  }
  const arr = str.split(':').map(s => s - 0);
  if (arr[0] < 24) {
    return str;
  }
  const h = arr[0] % 24;
  const m = arr[1];
  const prefix = showPrefix ? '次日' : '';
  return prefix + [fix2(h), fix2(m)].join(':');
}

/**
 * 百分比
 * @param {number} n
 * @param {number} toFixed
 */
export function percent(n, toFixed) {
  const fixed = toFixed || 0;
  if (!n) {
    return 0;
  }
  const p = n * 100;
  return p.toFixed(fixed) - 0;
}

/**
 * 浮点数操作
 * @param n1
 * @param n2
 * @param operate
 */
export function floatNumOperate(n1, n2, operate) {
  if ((!n1 && n1 !== 0) || (!n2 && n2 !== 0)) {
    return '';
  }
  const tail1 = n1.toString().split('.')[1] || '';
  const tail2 = n2.toString().split('.')[1] || '';
  let times = tail1.length > tail2.length ? tail1.length : tail2.length;
  let multiple = 1;
  while (times > 0) {
    multiple *= 10;
    times -= 1;
  }
  const num1 = n1 * multiple;
  const num2 = n2 * multiple;
  let res = 0;
  switch (operate) {
    case '+':
      res = num1 + num2;
      break;
    case '-':
      res = num1 - num2;
      break;
    default:
      break;
  }
  return res / multiple;
}

export function getLoginUrl() {
  const { hostname } = window.location;
  if (hostname === 'localhost') {
    return '/#/login';
  }
  const url = isProd
    ? 'https://www.emodor.com/login'
    : 'http://dev.emodor.com:3010/login';
  return url;
}
