import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import req from '../../../req';
import config from '../../../config';

const clientMap = {
  B: 'B',
  C: 'C',
};

function UserCard({ number, workerId, client }) {
  const [info, setInfo] = useState({});

  useEffect(() => {
    let didCancel = false;
    let p = null;
    if (client === clientMap.C) {
      p = req.sys.getWorkerInfo({
        workerId,
      });
    } else {
      p = req.sys.getManagerInfo({
        number,
      });
    }
    p.then(res => {
      if (didCancel) {
        return;
      }
      if (res.code !== 0) {
        req.err.show(res);
        return;
      }
      if (client === clientMap.C) {
        const { mobileNumber, workerCode, headImg } = res.data;
        Object.assign(res.data, {
          mobile: mobileNumber,
          workCode: workerCode,
          img: headImg,
        });
      }
      setInfo(res.data);
    }).catch(err => {
      if (didCancel) {
        return;
      }
      req.err.show(err);
    });
    return () => {
      didCancel = true;
    };
  }, [number]);

  const {
    name,
    userName,
    img,
    workCode,
    birthday,
    sex,
    nationality,
    place,
    mobile,
  } = info || {};
  const age = birthday
    ? moment().diff(moment(birthday, 'YYYY/MM/DD'), 'years')
    : '-';
  const rowStyle = {
    marginBottom: 10,
  };

  return (
    <div style={{ padding: '12px 8px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={img || config.defaultAvt}
          alt={name || userName}
          style={{
            display: 'block',
            width: 72,
            height: 72,
            borderRadius: '100%',
          }}
        />
        <div style={{ marginLeft: 24 }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: 'rgba(0, 0, 0, 0.85)',
            }}
          >
            {name || userName}
          </div>
          <div
            style={{
              fontSize: 14,
              marginTop: 4,
              color: 'rgba(0, 0, 0, 0.45)',
            }}
          >{`工号：${workCode}`}</div>
        </div>
      </div>
      <div
        style={{
          marginTop: 24,
          fontSize: 14,
          color: 'rgba(0, 0, 0, 0.65)',
        }}
      >
        <div style={rowStyle}>
          年龄
          <span style={{ marginLeft: 16 }}>{age || '-'}</span>
        </div>
        <div style={rowStyle}>
          性别
          <span style={{ marginLeft: 16 }}>{sex || '-'}</span>
        </div>
        <div style={rowStyle}>
          民族
          <span style={{ marginLeft: 16 }}>{nationality || '-'}</span>
        </div>
        <div style={rowStyle}>
          籍贯
          <span style={{ marginLeft: 16 }}>{place || '-'}</span>
        </div>
        <div>
          电话
          <span style={{ marginLeft: 16 }}>{mobile || '-'}</span>
        </div>
      </div>
    </div>
  );
}

UserCard.propTypes = {
  number: PropTypes.string.isRequired,
  workerId: PropTypes.string.isRequired,
  client: PropTypes.string,
};

UserCard.defaultProps = {
  client: clientMap.B,
};

export default UserCard;
