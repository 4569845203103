import React from 'react';
import { Layout } from 'antd';

const Footer = () => (
  <Layout.Footer
    style={{
      color: 'rgba(0, 0, 0, 0.45)',
      fontSize: 14,
      textAlign: 'center',
      lineHeight: 1.57,
    }}
  >
    400-004-0686（8:00-20:00） 广州市黄埔区澳门青年人创新部落15楼
    <br />
    墨斗科技Ⓒ2018-2022 粤ICP备18035980号
  </Layout.Footer>
);

export default Footer;
