import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Link } from 'react-router-dom';
import { Layout, Affix, Button } from 'antd';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import styled from 'styled-components';
import loadable from '@loadable/component';
import logoPath from '../../img/logo_black.svg';
import logoSimplePath from '../../img/logo_simple.svg';
import toIdCardPageIcon from '../../img/cardscan@3x.png';

import Root from './Root';
import organizationPages from '../organization';
import dataExport from '../data_export';
import companyPages from '../company';
import projectPages from '../project';
import Footer from './Footer';
import Header from './Header';
import Nav from './Nav';
import Unauthorized from '../Unauthorized';
import IdCardReader from '../IdCardReader';
import logAduit from '../logAduit';
import learningResource from '../learningResource';
import LoginSecuritySetting from '../LoginSecuritySetting';
import WorkerComplaints from '../WorkerComplaints';
import WorkerComplaintsDetail from '../WorkerComplaints/Detail';

import req from '../../req';
// import config from '../../config';

// const { VIEW_DASHBOARD } = config.authority.company;

const Dashboard = loadable(() => import('../Dashboard'));
const Message = loadable(() => import('../Message'));
const Blacklist = loadable(() => import('../Blacklist'));
const BlacklistApply = loadable(() => import('../Blacklist/Apply'));
const RegisterCompany = loadable(() => import('../RegisterCompany'));

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  .logo_wrapper {
    padding: 16px 0 16px 24px;
    border-bottom: solid 1px #e8e8e8;

    .logo {
      display: block;
      height: 32px;
    }
  }
`;

/**
 * 授权路由
 */
function PrivateRoute({ component, isAuthenticated, ...rest }) {
  if (isAuthenticated) {
    return <Route {...rest} component={component} />;
  }
  return <Route {...rest} component={Unauthorized} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

class FrameLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      userInfo: null,
      authorityMap: {},
      showFlag: false, // (无公司角色仅是项目成员)
    };
    this.toggle = this.toggle.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.getMyInfo();
    this.getAuthority();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onLogout() {
    const { history } = this.props;
    req.sys.logout();
    history.replace('/login');
  }

  /**
   * 获取当前个人信息
   */
  getMyInfo() {
    const that = this;
    return req
      .cachify('user.getMyInfo')()
      .then(res => {
        if (!that.mounted) {
          return;
        }
        if (res.code !== 0) {
          if (res.code === req.err.CODE.sessionExpired) {
            req.err.goLogin();
            return;
          }
          req.err.show(res);
          return;
        }
        that.setState({
          userInfo: res.data,
        });
        const cid = res.data.currentTopCompany
          ? res.data.currentTopCompany.id
          : null;
        if (cid) {
          that.showCompanyOrgDataSummaryOrNo(cid);
        }
      })
      .catch(req.err.show);
  }

  /**
   * 获取权限
   */
  getAuthority() {
    const that = this;
    req.authority
      .getMap()
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        that.setState({
          authorityMap: res.data,
        });
      })
      .catch(req.err.show);
  }

  /**
   * 是否展示公司数据总览以及数据看板(无公司角色仅是项目成员)
   */
  showCompanyOrgDataSummaryOrNo(tid) {
    const that = this;
    req.company
      .showCompanyOrgDataSummaryOrNo({
        companyId: tid,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        that.setState({
          showFlag: res.data.flag,
        });
      })
      .catch(req.err.show);
  }

  /**
   * 导航
   */
  toggle() {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  }

  render() {
    const { collapsed, userInfo, authorityMap, showFlag } = this.state;
    if (!userInfo || !userInfo.id) {
      return null;
    }
    return (
      <Layout>
        <StyledSider
          trigger={null}
          collapsible
          collapsed={collapsed}
          theme="light"
          style={{
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%',
            position: 'fixed',
            left: 0,
            zIndex: 11,
          }}
        >
          <div className="logo_wrapper">
            <img
              src={collapsed ? logoSimplePath : logoPath}
              alt="logo"
              className="logo"
            />
          </div>
          <Nav collapsed={collapsed} {...this.props} />
          <div
            style={{
              position: 'absolute',
              bottom: 32,
              left: 0,
              width: '100%',
              textAlign: 'center',
            }}
          >
            {!collapsed ? (
              <Button type="primary" style={{ width: 116 }}>
                <Link to="/idcard_reader">身份证读卡器</Link>
              </Button>
            ) : (
              <div
                style={{
                  width: 32,
                  padding: 4,
                  borderRadius: 4,
                  backgroundColor: '#1890ff',
                  boxSizing: 'border-box',
                  margin: '0 auto',
                }}
              >
                <Link to="/idcard_reader">
                  <img
                    src={toIdCardPageIcon}
                    alt="icon"
                    style={{ width: 24, height: 16 }}
                  />
                </Link>
              </div>
            )}
          </div>
        </StyledSider>
        <Layout
          style={{
            marginLeft: collapsed ? 80 : 200,
            transition: 'margin-left .2s',
          }}
        >
          <Affix offsetTop={0}>
            <Header
              collapsed={collapsed}
              toggle={this.toggle}
              {...this.props}
            />
          </Affix>
          <CacheSwitch onUpdate={() => window.scrollTo(0, 0)}>
            <Route exact path="/" component={Root} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/message" render={props => <Message {...props} />} />
            <Route
              exact
              path="/blacklist"
              render={props => <Blacklist {...props} />}
            />
            <Route path="/blacklist/apply" component={BlacklistApply} />
            <Route
              path="/organization/join/:id"
              component={organizationPages.Join}
            />
            <Route
              path="/organization/append/:id"
              component={organizationPages.Append}
            />
            <Route
              path="/organization/overview"
              component={organizationPages.Overview}
            />
            <Route path="/register_company" component={RegisterCompany} />
            <Route path="/data_export/attend" component={dataExport.Attend} />
            <Route path="/data_export/salary" component={dataExport.Salary} />
            <Route path="/data_export/roster" component={dataExport.Roster} />
            <Route path="/data_export/org" component={dataExport.Org} />

            <PrivateRoute
              isAuthenticated={!!authorityMap.superAdmin}
              path="/log_aduit"
              component={logAduit}
            />
            {/* <Route path="/company/overview" component={companyPages.Overview} />
            <Route
              path="/company/worker_data"
              component={companyPages.WorkerData}
            />
            <Route path="/company/data" component={companyPages.Data} /> */}

            <PrivateRoute
              isAuthenticated={showFlag}
              path="/company/overview"
              component={companyPages.Overview}
            />
            <PrivateRoute
              isAuthenticated={showFlag}
              path="/company/worker_data"
              component={companyPages.WorkerData}
            />
            <PrivateRoute
              isAuthenticated={showFlag}
              path="/company/data"
              component={companyPages.Data}
            />
            <Route
              exact
              path="/project/overview"
              component={projectPages.Overview}
            />
            <Route
              path="/project/projectItem/:id"
              component={projectPages.ProjectItem}
            />
            <Route
              path="/project/WorkePortrait/:id"
              component={projectPages.WorkePortrait}
            />
            <Route exact path="/idcard_reader" component={IdCardReader} />
            <Route path="/learning_resource" component={learningResource} />
            <PrivateRoute
              isAuthenticated={!!authorityMap.superAdmin}
              path="/login_security_setting"
              component={LoginSecuritySetting}
            />
            <Route
              path="/worker_complaints/detail/:id"
              component={WorkerComplaintsDetail}
            />
            <CacheRoute
              path="/worker_complaints"
              component={WorkerComplaints}
            />
            <Route render={() => <Redirect to="/404" />} />
          </CacheSwitch>
          <Footer />
        </Layout>
      </Layout>
    );
  }
}

FrameLayout.propTypes = {
  history: PropTypes.object.isRequired,
};

export default FrameLayout;
