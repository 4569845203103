import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  // Upload,
  // Icon,
  message,
  Radio,
  // Button,
  Checkbox,
  Row,
  Col,
  TreeSelect,
  Input,
  Cascader,
} from 'antd';
import { Form } from '@ant-design/compatible';
import styled from 'styled-components';
import req from '../../../req';

import { UploadVideo } from '../component/UploadVideo';

const StyledDiv = styled.div`
  .ant-legacy-form-item-label > label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

// const { Dragger } = Upload;

function list2tree(list, parent = null) {
  // const that = this;
  return list.map(item => ({
    title: item.value,
    key: item.value,
    value: item.value,
    code: item.code,
    parent,
    children: list2tree(item.children, item),
  }));
}

function list2tree1(list, parent = null) {
  // const that = this;
  return list.map(item => ({
    label: item.value,
    value: item.value,
    code: item.code,
    parent,
    children: list2tree1(item.children, item),
  }));
}

let detailVideo1 = [];
let allVideo1 = [];
let uploadAll = false;
let submitUpload = false;

function UploadResourceModal({ id, visible, onClose, crtItem }) {
  console.log('UploadResourceModal', id, visible, crtItem);
  const [data, setData] = useState({
    categoryCodeList: [], // 工种集合
    isAllCategory: '01', // 是否不限工种 00：限定工种 01：不限工种
    videoList: [], // 视频集合
    videoType: [], // 视频类型
    // 发布范围 00：上传到平台视频库 01：仅上传到公司/项目视频库
    publishRange: '', // 是否共享到平台 00 否 01 是
    description: '', // 文件名
    defaultCategoryCodeList: [], // 详情默认
    defaultCategoryCodeList1: [], // 详情默认
  });
  // let itemDetail = crtItem;
  // const [crtCategoryCode, setCrtCategoryCode] = useState([]);
  // const [crtCategoryCodeName, setCrtCategoryCodeName] = useState([]);
  const [categoryTree, setCategoryTree] = useState([]);
  const [categoryTree1, setCategoryTree1] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [destroyOnClose, setDestroyOnClose] = useState(true);
  // const [fileList, setFileList] = useState([]);

  // eslint-disable-next-line no-unused-vars
  function getTree() {
    req.education
      .listSysCategoryDic()
      .then(r1 => {
        if (r1.code !== 0) {
          req.err.show(r1);
          return;
        }
        const reList = list2tree(r1.data.list);
        const reList1 = list2tree1(r1.data.list);
        let rebuildList = [];
        rebuildList = reList;
        console.log('getTree rebuildList', rebuildList);
        setCategoryTree(reList);
        setCategoryTree1(reList1);
      })
      .catch(req.err.show);
  }

  const validateParams = _data => {
    console.log('validateParams', _data);

    if (!_data.videoList.length && !id) {
      message.error('上传的视频不能为空');
      return true;
    }

    if (!_data.videoType.length) {
      message.error('请选择标签');
      return true;
    }

    if (!_data.description.length && id) {
      message.error('资源名称不能为空');
      return true;
    }

    if (!_data.categoryCodeList.length && _data.isAllCategory === '00') {
      message.error('请选择工种集合');
      return true;
    }

    if (!_data.publishRange.length) {
      message.error('共享到平台不能为空');
      return true;
    }

    return false;
  };

  function onSubmit() {
    if (validateParams(data)) return;
    console.log('onSubmit', data, submitUpload);
    if (!uploadAll && !id) {
      message.error('正在上传，请稍候');
      return;
    }
    if (submitUpload) {
      return;
    }
    submitUpload = true;
    let p = null;
    if (id) {
      p = req.education.updateResource(
        JSON.stringify({ ...data, videoId: id })
      );
    } else {
      p = req.education.publishResource(JSON.stringify({ ...data }));
    }
    p.then(res => {
      if (res.code !== 0) {
        req.err.show(res);
        return;
      }
      onClose();
      setDestroyOnClose(true);
      message.success(`${id ? '编辑' : '添加'}成功`);
      setTimeout(() => {
        submitUpload = false;
      }, 300);
    }).catch(err => {
      submitUpload = false;
      req.err.show(err);
    });
  }

  const handleFormChange = useCallback((key, value) => {
    // console.log('key', key, value);
    setData(_data => ({
      ..._data,
      [key]: value,
    }));
  }, []);

  function onRadioRemindChange(key, e) {
    console.log('onRadioChange', key, e);
    handleFormChange(key, e.target.value);
  }

  // 工种类型
  function onCategoryCodeTreeChange(value, label, extra) {
    const hasItemIsAll = value.find(item => item === '全部工种');
    console.log('hasItemIsAll', hasItemIsAll);
    const { allCheckedNodes } = extra;
    const lastCategoryCodeItem = allCheckedNodes[allCheckedNodes.length - 1];
    console.log(
      'onCategoryCodeTreeChange',
      value,
      label,
      extra,
      lastCategoryCodeItem
    );
    if (
      lastCategoryCodeItem &&
      lastCategoryCodeItem.node &&
      lastCategoryCodeItem.node.props &&
      !lastCategoryCodeItem.node.props.parent
    ) {
      return;
    }
    const crtCategoryCodeList = allCheckedNodes.map(
      item => (item.props && item.props.code) || item.node.props.code
    );
    // setCrtCategoryCode(triggerNode.props.eventKey);
    // setCrtCategoryCodeName(value);
    handleFormChange('categoryCodeList', crtCategoryCodeList);
    handleFormChange(
      'defaultCategoryCodeList',
      value.filter(ele => ele !== '全部工种')
    );
    if (crtCategoryCodeList.length) {
      handleFormChange('isAllCategory', '00');
    } else {
      handleFormChange('isAllCategory', '01');
    }
    console.log('crtCategoryCode', crtCategoryCodeList);
  }

  function onCategoryCodeTreeChange1(value, selectedOptions) {
    // const key = 'defaultCategoryCodeList1';
    console.log(value, selectedOptions);
    const reSetionList = selectedOptions
      // .map(ele => {
      .map((ele, i) => {
        if (!ele[0]) {
          return value[i];
        }
        if (ele.length > 1) {
          const lastItem = ele[1];
          // const reLastItem = [lastItem.parent.value, lastItem.value];
          // return reLastItem;
          return lastItem.code;
        }
        const lastItem = ele[0].children;
        // const reLastItem = lastItem.map(item => [ele[0].value, item.label]);
        // return reLastItem;
        return lastItem.map(item => item.code);
      })
      .join()
      .split(',')
      .filter(ele => ele !== '全部工种');
    console.log('reSetionList', reSetionList);
    handleFormChange('categoryCodeList', reSetionList);
    handleFormChange(
      'defaultCategoryCodeList1',
      value.filter(ele => ele[0] !== '全部工种')
    );
    console.log(
      '1111',
      value.filter(ele => ele[0] !== '全部工种')
    );
    if (reSetionList.length && reSetionList[0]) {
      handleFormChange('isAllCategory', '00');
    } else {
      handleFormChange('isAllCategory', '01');
    }
    // setData(_data => ({
    //   ..._data,
    //   [key]: reSetionList,
    // }));
  }

  const uploadAllChange = useCallback(uploadAllFlag => {
    console.log('uploadAllChange', uploadAllFlag);
    uploadAll = !!uploadAllFlag;
  });

  function onCancelClose() {
    setDestroyOnClose(true);
    onClose();
  }

  const handleVideoChange = useCallback(
    (key, value, duration, width, height, uid, fileName) => {
      console.log(
        'handleVideoChange',
        key,
        value,
        width,
        height,
        uid,
        fileName
      );
      // console.log('44848', detailVideo, allVideo);
      setDestroyOnClose(true);
      setDestroyOnClose(false);
      const nameArr = fileName ? fileName.split('.') : [];
      nameArr.pop();
      if (value) {
        allVideo1.push({
          url: key,
          desc: '',
          httpUrl: value || '',
        });
        detailVideo1.push({
          videoCover: key || '',
          videoCoverHeight: width,
          videoCoverWidth: height,
          videoTimes: duration,
          videoUrl: value || '',
          description: nameArr.join('.'),
          uid,
        });
        handleFormChange('videoList', detailVideo1);
      }
    }
  );

  function onLabelChange(e) {
    handleFormChange('description', e.target.value);
  }

  const delectUrlChange = useCallback((uid, length) => {
    console.log('delectUrlChange', uid, length);
    if (length) {
      const detailist = detailVideo1.filter(item => item.uid !== uid);
      console.log('delectUrlChange', detailist);
      handleFormChange('videoList', detailist);
    } else {
      const detailist = [];
      detailVideo1 = [];
      allVideo1 = [];
      console.log('delectUrlChange', detailist);
      handleFormChange('videoList', detailist);
    }
    // console.log('delectUrlChange', arr, detailist);
  });

  useEffect(() => {
    if (!visible) {
      console.log('我init进来了');
      setData({
        categoryCodeList: [], // 工种集合
        isAllCategory: '01', // 是否不限工种 00：限定工种 01：不限工种
        videoList: [], // 视频集合
        videoType: [], // 视频类型
        // 发布范围 00：上传到平台视频库 01：仅上传到公司/项目视频库
        publishRange: '', // 是否共享到平台 00 否 01 是
        description: '', // 文件名
        defaultCategoryCodeList: [],
        defaultCategoryCodeList1: [],
      });
      setCategoryTree([]);
      detailVideo1 = [];
      allVideo1 = [];
    } else if (!id) {
      setData({
        categoryCodeList: [], // 工种集合
        isAllCategory: '01', // 是否不限工种 00：限定工种 01：不限工种
        videoList: [], // 视频集合
        videoType: [], // 视频类型
        // 发布范围 00：上传到平台视频库 01：仅上传到公司/项目视频库
        publishRange: '', // 是否共享到平台 00 否 01 是
        description: '', // 文件名
        defaultCategoryCodeList: [],
        defaultCategoryCodeList1: [],
      });
      setShowModal(true);
    } else {
      console.log('我进来了', crtItem);
      const Arr = crtItem.workerCategoryList.map(item => [
        item.parentValue || '',
        item.value,
      ]);
      console.log('arr', Arr);
      // const map = {};
      setData({
        defaultCategoryCodeList:
          crtItem.isAllCategory === '01'
            ? ['全部工种']
            : crtItem.workerCategoryList.map(item => item.value), // 详情默认工种集合
        categoryCodeList:
          crtItem.isAllCategory === '01'
            ? ['全部工种']
            : crtItem.workerCategoryList.map(item => item.code), // 详情默认工种集合
        defaultCategoryCodeList1:
          crtItem.isAllCategory === '01' ? ['全部工种'] : Arr, // 工种集合
        isAllCategory: crtItem.isAllCategory, // 是否不限工种 00：限定工种 01：不限工种
        videoList: [], // 视频集合
        videoType: crtItem.educationTypeList.map(item => item.code), // 视频类型
        // 发布范围 00：上传到平台视频库 01：仅上传到公司/项目视频库
        publishRange: '', // 是否共享到平台 00 否 01 是
        description: crtItem.name, // 文件名
      });
      console.log('我进来了 data', data);
      setShowModal(true);
    }
    // getTree();
  }, [visible]);

  useEffect(() => {
    if (visible) {
      getTree();
    }
  }, [visible]);

  console.log('我是data----', data);
  // console.log('destroyOnClose', destroyOnClose);
  return (
    <React.Fragment>
      <Modal
        visible={visible && showModal}
        title={id ? '编辑' : '上传资料'}
        // footer={null}
        okText={id ? '确定' : '上传'}
        cancelText="取消"
        onCancel={onCancelClose}
        onOk={onSubmit}
        width={560}
        destroyOnClose={destroyOnClose}
      >
        <StyledDiv>
          <Form
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 17,
            }}
            onSubmit={onSubmit}
          >
            {!id ? (
              <div>
                {showModal ? (
                  <Form.Item label="学习资料" required>
                    <UploadVideo
                      url=""
                      multiple
                      onUploadChange={handleVideoChange}
                      delectUrlChange={delectUrlChange}
                      // defaultFileList={detailVideo1}
                      uploadAllChange={uploadAllChange}
                    />
                  </Form.Item>
                ) : null}
              </div>
            ) : (
              <Form.Item label="名称" wrapperCol={{ span: 14 }}>
                <Input
                  placeholder="输入文件资源名称"
                  value={data.description}
                  onChange={onLabelChange}
                />
              </Form.Item>
            )}

            <Form.Item label="标签" required>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={data.videoType}
                onChange={value => handleFormChange('videoType', value)}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox value="00">安全教育</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="01">施工技术</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="02">工伤疾病预防</Checkbox>
                  </Col>
                  <Col span={8} style={{ marginTop: 10 }}>
                    <Checkbox value="03">工器具</Checkbox>
                  </Col>
                  <Col span={8} style={{ marginTop: 10 }}>
                    <Checkbox value="04">工作生活</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <div style={{ display: 'none' }}>
              <Form.Item label="适用工种：">
                <div>
                  {categoryTree.length ? (
                    <div
                    // style={{
                    //   padding: 100,
                    //   height: 1000,
                    //   background: '#eee',
                    //   position: 'relative',
                    // }}
                    // id="area"
                    >
                      <TreeSelect
                        treeData={categoryTree}
                        placeholder="请选择"
                        // style={{
                        //   flexGrow: 1,
                        //   overflow: 'hidden',
                        //   marginTop: 6,
                        //   // position: 'absolute',
                        //   // left: 0,
                        //   // bottom: 0,
                        // }}
                        treeDefaultExpandAll
                        onChange={onCategoryCodeTreeChange}
                        defaultValue={data.defaultCategoryCodeList || []}
                        value={data.defaultCategoryCodeList}
                        // showSearch
                        multiple
                        dropdownStyle={{
                          maxHeight: '50vh',
                        }}
                        // getPopupContainer={() =>
                        //   document.getElementById('area')
                        // }
                        getPopupContainer={node => {
                          console.log('triggerNode', node);
                          return node;
                        }}
                      />
                      <div
                        style={{
                          width: 400,
                          color: 'rgba(0, 0, 0 , 0.45)',
                          fontSize: 14,
                          marginTop: -10,
                        }}
                      >
                        若不限工种，则不需要选择
                      </div>
                    </div>
                  ) : null}
                </div>
              </Form.Item>
            </div>

            <div style={{ display: 'block' }}>
              <Form.Item label="适用工种：">
                <div>
                  {categoryTree1.length ? (
                    <div>
                      <Cascader
                        style={{ width: '100%' }}
                        options={categoryTree1}
                        onChange={onCategoryCodeTreeChange1}
                        defaultValue={data.defaultCategoryCodeList1 || []}
                        value={data.defaultCategoryCodeList1 || []}
                        expandTrigger="hover"
                        multiple
                      />
                      <div
                        style={{
                          width: 400,
                          color: 'rgba(0, 0, 0 , 0.45)',
                          fontSize: 14,
                          marginTop: -10,
                        }}
                      >
                        若不限工种，则不需要选择
                      </div>
                    </div>
                  ) : null}
                </div>
              </Form.Item>
            </div>

            <div>
              {!id ? (
                <div style={{ position: 'relative' }}>
                  <Form.Item
                    label="共享到平台"
                    required
                    style={{ position: 'relative' }}
                  >
                    <Radio.Group
                      defaultValue={data.publishRange}
                      onChange={value =>
                        onRadioRemindChange('publishRange', value)
                      }
                    >
                      <Radio value="00" defaultChecked>
                        共享
                      </Radio>
                      <Radio value="01">不共享</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div
                    style={{
                      width: 400,
                      color: 'rgba(0, 0, 0 , 0.45)',
                      fontSize: 14,
                      position: 'absolute',
                      left: 130,
                      top: 36,
                    }}
                  >
                    共享后，其他公司也可以看到您上传的学习资料
                  </div>
                </div>
              ) : null}
            </div>

            {/* <Form.Item wrapperCol={{ offset: 3 }} style={{ marginTop: 60 }}>
              <Button type="primary" htmlType="submit" style={{ width: 200 }}>
                {id ? '确定' : '上传'}
              </Button>
            </Form.Item> */}
          </Form>
        </StyledDiv>
      </Modal>
    </React.Fragment>
  );
}

UploadResourceModal.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  crtItem: PropTypes.object,
};

UploadResourceModal.defaultProps = {
  crtItem: {},
};

export default Form.create({ name: 'upload_resource_modal' })(
  UploadResourceModal
);
