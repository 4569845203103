import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Spin, Button } from 'antd';

import logoPath from '../../../img/logo_black.svg';
import pdfPath from '../../../img/pdf@2x.png';
import zipPath from '../../../img/zip@3x.png';
import req from '../../../req';

const StyledPage = styled(Layout)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .header {
    padding: 16px 23px;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    background-color: #fff;

    .logo {
      display: block;
      height: 32px;
    }
  }
  .footer {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    text-align: center;
    line-height: 1.57;
  }

  .info_box {
    width: 600px;
    height: 300px;
    background-color: #fff;
    border-radius: 4px;

    .loading {
      padding-top: 88px;
      text-align: center;
    }

    .excel {
      display: block;
      margin-top: 65px;
      margin-left: auto;
      margin-right: auto;
      height: 48px;
    }

    .name {
      margin-top: 16px;
      font-size: 16px;
      font-weight: bold;
      color: #212732;
      text-align: center;
    }

    .remark {
      margin-top: 12px;
      font-size: 14px;
      line-height: 2;
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
    }

    .download_btn {
      display: block;
      width: 102px;
      margin: 12px auto 0;
    }
  }
`;

class Page extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      file: {},
      zipFileType: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    console.log(this.props);
    const data = new URLSearchParams(location.search).get('data');
    console.log('data', data);
    this.exportReport(data);
  }

  getFileStatus(fileKey) {
    const that = this;
    req.sys
      .checkExportReport({
        fileKey,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        switch (res.data.status) {
          case '00':
            setTimeout(() => {
              that.getFileStatus(fileKey);
            }, 2000);
            break;
          case '01':
            that.setState({
              loading: false,
              file: res.data.file,
              zipFileType: /\.(zip)$/i.test(res.data.file.fileName),
            });
            break;
          case '02':
            req.err.show('生成失败！');
            break;
          default:
            break;
        }
      })
      .catch(err => {
        req.err.show(err);
      });
  }

  exportReport(data) {
    const that = this;
    req.complaints
      .exportWorkerComplaint(data)
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        that.getFileStatus(res.data.fileKey);
      })
      .catch(err => {
        req.err.show(err);
      });
  }

  render() {
    const { loading, file, zipFileType } = this.state;
    return (
      <StyledPage>
        <Layout.Header className="header">
          <Link to="/">
            <img src={logoPath} className="logo" alt="logo" />
          </Link>
        </Layout.Header>
        <Layout.Content data-flex="main:center cross:center">
          {loading ? (
            <div className="info_box">
              <div className="loading">
                <Spin size="large" />
              </div>
              <div className="remark">文件生成可能需要几分钟，请耐心等待～</div>
              <Button type="primary" className="download_btn" disabled>
                下载
              </Button>
            </div>
          ) : (
            <div className="info_box">
              {zipFileType ? (
                <img className="excel" src={zipPath} alt="压缩包" />
              ) : (
                <img className="excel" src={pdfPath} alt="表格" />
              )}
              <div className="name">{file.fileName}</div>
              <div className="remark">文件已生成！请点击按钮下载。</div>
              <Button type="primary" className="download_btn">
                <a href={file.url} rel="noopener noreferrer" target="_blank">
                  下载
                </a>
              </Button>
            </div>
          )}
        </Layout.Content>
        <Layout.Footer className="footer">
          400-004-0686（8:00-20:00） 广州市黄埔区澳门青年人创新部落15楼
          <br />
          墨斗科技Ⓒ2018-2020 粤ICP备18035980号
        </Layout.Footer>
      </StyledPage>
    );
  }
}

Page.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Page;
