const api = {
  // 3.8.0 上传学习资料文件API,返回文件id
  // uploadEducationResource: {
  //   url: '/bi-api/api/file/uploadEducationResource',
  //   method: 'post',
  // },
  // 3.8.0 发布资源
  publishResource: {
    url: '/bi-api/api/education/resource/publishResource',
    method: 'post',
  },
  // 3.8.0 删除资源
  deleteResource: {
    url: '/bi-api/api/education/resource/deleteResource/:videoId',
    method: 'post',
  },
  // 3.8.0 资源列表
  listResource: {
    url: '/bi-api/api/education/resource/listResource',
  },
  // 3.8.0 编辑资源
  updateResource: {
    url: '/bi-api/api/education/resource/updateResource',
    method: 'post',
  },
  // 3.8.0 资源类型字典接口
  listFileCategory: {
    url: '/bi-api/api/education/resource/listFileCategory',
  },
  // 系统工种列表
  listSysCategoryDic: {
    url: '/bi-api/api/sys/listSysCategoryDic',
  },
  // 视频格式转化为mp4
  videoProcessMedia: {
    url: '/bi-api/api/file/videoProcessMedia',
    method: 'POST',
  },
};

export default api;
