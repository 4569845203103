import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import req from '../../../req/index';

const PhoneLoginFnStyle = styled.div`
  margin-bottom: 58px;
  padding: 24px;
  .submit_btn {
    height: 40px;
    background: #1890ff;
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    margin-top: 30px;
    cursor: pointer;
  }
  .phone_item {
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 12px;
    box-sizing: border-box;
    &.phone_err {
      border-color: #ff7575;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      &::placeholder {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .error_msg {
    margin-top: 14px;
    font-size: 14px;
    color: #ff7575;
    line-height: 20px;
  }
  .verify_item {
    height: 40px;
    margin-top: 30px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    position: relative;
    padding-right: 96px;
    box-sizing: border-box;
    &.verify_err {
      border-color: #ff7575;
    }
    .input_wrap {
      flex-grow: 1;
      height: 100%;
      padding: 8px 0px 8px 12px;
      box-sizing: border-box;
      input {
        height: 100%;
        outline: none;
        border: none;
        &::placeholder {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    .submit_verify {
      position: absolute;
      right: -1px;
      top: -1px;
      /* border-top: 1px solid rgba(0, 0, 0, 0.15);
      border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
      width: 94px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #1890ff;
      border-radius: 0px 4px 4px 0px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      &.ver_dis {
        background: #b4bac6;
      }
    }
  }
`;

function ErrMsg({ errMsg }) {
  return <div className="error_msg">{errMsg}</div>;
}
ErrMsg.defaultProps = {
  errMsg: '',
};
ErrMsg.propTypes = {
  errMsg: PropTypes.string,
};

const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

class PhoneLoginFn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errMsg: '',
      phoneNumVal: '',
      verify: '',
      countDown: 60,
      verifyDisabled: false,
      showErrType: '',
      isRequest: true,
    };
    this.countDownTimer = null;
  }

  componentDidMount() {
    console.log(this.props);
  }

  handleLogin() {
    const { phoneNumVal, verify, isRequest } = this.state;
    const { history } = this.props;
    if (!phoneNumVal.replace(' ', '')) {
      this.setState({ errMsg: '请输入手机号' });
      return;
    }
    if (!reg.test(phoneNumVal)) {
      this.setState({ errMsg: '手机号格式有误' });
      return;
    }
    if (!verify.replace(' ', '')) {
      this.setState({ errMsg: '请输入验证码' });
      return;
    }
    if (!isRequest) {
      return;
    }
    this.setState({ isRequest: false });
    req.sys
      .loginMobile(
        JSON.stringify({
          mobileNumber: phoneNumVal,
          validateCode: verify,
        })
      )
      .then(res => {
        // "3100"; //账号不存在，请使用微信扫码登录
        // "3101";//尝试次数过多，请10分钟后重试
        // "3102"; //验证码错误
        // "3103"; //验证码错误，您还有{}次尝试机会
        const code = Number(res.code);
        if (code === 3100) {
          this.setState({
            errMsg: res.msg,
            showErrType: 'phone_err',
          });
        } else if (code === 3102 || code === 3103) {
          this.setState({
            errMsg: res.msg,
            showErrType: 'verify_err',
          });
        } else if (code === 3101) {
          this.setState({
            errMsg: res.msg,
          });
        } else if (code === 0) {
          req.session.set(res.data.sessionId);
          setTimeout(() => {
            history.push('/');
          }, 1000);
        } else {
          req.err.show(res.msg);
        }
        this.setState({ isRequest: true });
      })
      .catch(err => {
        this.setState({ isRequest: true });
        req.err.show(err);
      });
    console.log(phoneNumVal, verify);
  }

  sendVerifyCode() {
    const { phoneNumVal, verifyDisabled } = this.state;
    if (!verifyDisabled) {
      return;
    }
    if (!reg.test(phoneNumVal)) {
      this.setState({ errMsg: '手机号格式有误' });
    }
    req.sys
      .getLoginValidateCode({ mobileNumber: phoneNumVal })
      .then(res => {
        const code = Number(res.code);
        if (code === 0) {
          this.setState({ verifyDisabled: false });
          this.countDownTimer = setInterval(() => {
            const { countDown } = this.state;
            if (countDown === 0) {
              clearInterval(this.countDownTimer);
              this.setState({
                verifyDisabled: reg.test(phoneNumVal),
                countDown: 60,
              });
            } else {
              this.setState({ countDown: countDown - 1 });
            }
          }, 1000);
        } else if (code === 3100) {
          this.setState({
            errMsg: res.msg,
            showErrType: 'phone_err',
          });
        } else if (code === 3101) {
          this.setState({
            errMsg: res.msg,
          });
        } else {
          req.err.show(res.msg);
        }
      })
      .catch(req.err.show);
  }

  render() {
    console.log(this.props);
    const {
      errMsg,
      phoneNumVal,
      verify,
      countDown,
      verifyDisabled,
      showErrType,
    } = this.state;
    // function getFieldDecorator() {}
    // const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return (
      <PhoneLoginFnStyle>
        <div
          className={
            showErrType === 'phone_err' ? 'phone_item phone_err' : 'phone_item'
          }
        >
          <input
            type="text"
            placeholder="请输入手机号码"
            value={phoneNumVal}
            onChange={e => {
              const { value } = e.target;
              const { countDown: countDownNum } = this.state;
              if (value.length === 11 && countDownNum === 60) {
                this.setState({ verifyDisabled: true, errMsg: '' });
              } else {
                this.setState({ verifyDisabled: false });
              }
              this.setState({ phoneNumVal: value });
            }}
          />
        </div>
        <div
          className={
            showErrType === 'verify_err'
              ? 'verify_item verify_err'
              : 'verify_item'
          }
        >
          <div className="input_wrap">
            <input
              type="text"
              placeholder="输入验证码"
              value={verify}
              onChange={e => {
                const { value } = e.target;
                this.setState({ verify: value });
              }}
            />
          </div>
          <div
            className={
              verifyDisabled ? 'submit_verify' : 'submit_verify ver_dis'
            }
            onClick={() => this.sendVerifyCode()}
          >
            {countDown === 60 && countDown !== 0
              ? '发送验证码'
              : `${countDown}s重试`}
          </div>
        </div>
        {errMsg && <ErrMsg errMsg={errMsg} />}
        <div className="submit_btn" onClick={() => this.handleLogin()}>
          登录
        </div>
      </PhoneLoginFnStyle>
    );
  }
}

// const PhoneLogin = React.memo(PhoneLoginFn);
// PhoneLoginFn.defaultProps = {
//   form: {},
// };
PhoneLoginFn.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PhoneLoginFn);
