const api = {
  // v3.0.2 星盘管理列表(受权限限制)
  getList: {
    url: '/bi-api/api/dashboardManage/share/v2/listDashboard',
    method: 'POST',
  },
  getCompanyShareRecord: {
    url: '/bi-api/api/dashboardManage/share/listCompanySharedRecord',
  },
  getProjectShareRecord: {
    url: '/bi-api/api/dashboardManage/share/listProjectSharedRecord',
  },
  createShareLink: {
    url: '/bi-api/api/dashboardManage/share/generateShareLink',
  },
  invalidateShareLink: {
    url: '/bi-api/api/dashboardManage/share/deleteShareLink',
  },
  invalidateAllShareLink: {
    url: '/bi-api/api/dashboardManage/share/deleteAllShareLink',
  },
};

export default api;
