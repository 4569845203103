import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Input, Button } from 'antd';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  .box {
    margin-bottom: 20px;
  }
`;

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      param: '',
    };
    this.onSearch = this.onSearch.bind(this);
  }

  /**
   * click查询
   */
  onSearch(e) {
    console.log('e', e);
    this.setState({
      param: e,
    });
    const { onSearch } = this.props;
    const data = {};
    // 搜素文本
    if (e) {
      data.searchText = e;
    }
    onSearch(data);
  }

  render() {
    const { param } = this.state;
    const { loading, uploadResourceChange } = this.props;

    return (
      <>
        <StyledDiv>
          <div className="box" data-flex="main:center cross:center box:first">
            <div className="button-group">
              <Button type="primary" onClick={uploadResourceChange}>
                上传资料
              </Button>
            </div>
            <div style={{ textAlign: 'right' }} data-flex-box="1">
              <Input.Search
                placeholder="资料名称/标签"
                loading={loading}
                defaultValue={param}
                onSearch={this.onSearch}
                style={{ marginBottom: 10, width: 275 }}
              />
            </div>
          </div>
        </StyledDiv>
      </>
    );
  }
}
SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  uploadResourceChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SearchBox.defaultProps = {
  loading: false,
};
export default withRouter(SearchBox);
