import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import { Modal, Popover, message } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import req from '../../../req';
import config from '../../../config';
import { ruleDisplay, otRuleDisplay } from '../../../utils/attend';
import StyledInfoModal from '../InfoModal/StyledInfoModal';
import StatusBox from '../InfoModal/StatusBox';
import RejectModal from '../../Blacklist/RejectModal';
import UserCard from '../UserCard';

const { applyFormTypeMap } = config.message;

// const { ATTEND_MAKEUP } = config.message.typeMap;

function InfoModal({ id, visible, onCancel, onOk, changeMessageCount }) {
  const [update, setUpdate] = useState(0);
  const [info, setInfo] = useState({});
  const [record, setRecord] = useState({});
  const [rejectModalVisible, setRejectModalVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      setInfo({});
    }
  }, [visible]);

  useEffect(() => {
    if (!update) {
      return;
    }
    changeMessageCount();
  }, [update]);

  useEffect(() => {
    let didCancel = false;
    if (!visible || !id) {
      return () => {};
    }
    req.sys
      .getMessageDetails({
        messageId: id,
      })
      .then(r1 => {
        if (didCancel) {
          return;
        }
        if (r1.code !== 0) {
          req.err.show(r1);
          return;
        }
        const { recordId, readStatus } = r1.data;
        req.message
          .getAttendRecordApproveInfo({
            recordId,
          })
          .then(r2 => {
            if (didCancel) {
              return;
            }
            if (r2.code !== 0) {
              req.err.show(r2);
              return;
            }
            if (
              r2.data.approveStatus !== applyFormTypeMap.CHECK &&
              !readStatus
            ) {
              changeMessageCount();
            }
            setRecord(r2.data);
          })
          .catch(err => {
            if (didCancel) {
              return;
            }
            req.err.show(err);
          });
        setInfo(r1.data);
      })
      .catch(err => {
        if (didCancel) {
          return;
        }
        req.err.show(err);
      });
    return () => {
      didCancel = true;
    };
  }, [id, visible, update]);

  const onModalOk = useCallback(() => {
    Modal.confirm({
      title: '提示',
      content: '是否确定同意？',
      onOk() {
        req.message
          .dealWithAttendRecordApprove(
            JSON.stringify({
              applyId: record.id,
              status: applyFormTypeMap.AGREE,
            })
          )
          .then(res => {
            if (res.code !== 0) {
              req.err.show(res);
              return;
            }
            onOk();
            setUpdate(new Date().getTime());
          })
          .catch(req.err.show);
      },
    });
  }, [record.id]);

  const onModalCancel = useCallback(e => {
    if (!/cancel_btn/.test(e.currentTarget.className)) {
      onCancel();
      return;
    }
    setRejectModalVisible(true);
  }, []);

  const onRejectModelCancel = useCallback(() => {
    setRejectModalVisible(false);
  }, []);

  const onRejectModelOk = useCallback(
    text => {
      if (!/\S/.test(text)) {
        message.error('请输入拒绝原因');
        return;
      }
      if (text.length > 30) {
        message.error('拒绝原因不能大于30字');
        return;
      }
      req.message
        .dealWithAttendRecordApprove(
          JSON.stringify({
            applyId: record.id,
            rejectReason: text,
            status: applyFormTypeMap.REJECT,
          })
        )
        .then(res => {
          if (res.code !== 0) {
            req.err.show(res);
            return;
          }
          setRejectModalVisible(false);
          setUpdate(new Date().getTime());
        })
        .catch(req.err.show);
    },
    [record.id]
  );

  if (!info.id || !record.id || !visible) {
    return null;
  }
  const {
    applyType,
    clockType,
    approveStatus,
    applyWorker,
    approveWorker,
    approveReason,
    remark,
    imageUrls,
    projectName,
    groupName,
    attendanceRule,
    attendanceTime,
  } = record;
  const options = {};
  if (approveStatus !== applyFormTypeMap.CHECK) {
    options.footer = null;
  }

  const approver =
    approveStatus === applyFormTypeMap.RESET ? applyWorker : approveWorker;

  return (
    <React.Fragment>
      <Modal
        {...options}
        visible={visible}
        title={info.title || ''}
        cancelText="拒绝"
        okText="同意"
        onCancel={onModalCancel}
        onOk={onModalOk}
        cancelButtonProps={{ className: 'cancel_btn' }}
      >
        <StyledInfoModal>
          <div className="head">{info.content || '-'}</div>
          <div className="info_box">
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                申请人：
              </div>
              <img
                className="avt"
                src={applyWorker.headImg || config.defaultAvt}
                alt="xxx"
                data-flex-box="0"
              />
              <div className="val">
                <Popover
                  content={
                    <UserCard
                      client="C"
                      workerId={applyWorker.id}
                      number={applyWorker.mobileNumber}
                    />
                  }
                  trigger={['click']}
                  placement="right"
                >
                  {applyWorker.userName ||
                    applyWorker.name ||
                    applyWorker.nikeName}
                  <RightOutlined />
                </Popover>
              </div>
            </div>
            <div className="row" data-flex="main:left cross: center">
              <div className="label" data-flex-box="0">
                {`${applyType === '01' ? '补' : '误打'}${
                  clockType === '00' ? '开' : '收'
                }工卡：`}
              </div>
              <div className="val" style={{ color: '#f5a623' }}>
                {moment(attendanceTime).format('YYYY.MM.DD HH:mm')}
              </div>
            </div>
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                项目：
              </div>
              <div className="val">{projectName || '-'}</div>
            </div>
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                班组：
              </div>
              <div className="val">{groupName || '-'}</div>
            </div>
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                考勤时间：
              </div>
              <div className="val">
                <div>{attendanceRule.name || '-'}</div>
                <div>{ruleDisplay(attendanceRule, true, true)}</div>
                {attendanceRule.otSalaryType ? (
                  <div>加班：{otRuleDisplay(attendanceRule, true)}</div>
                ) : null}
              </div>
            </div>
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                原因：
              </div>
              <div className="val">
                <div>{remark || '无'}</div>
                {imageUrls && imageUrls.length ? (
                  <div style={{ marginTop: 4 }}>
                    {imageUrls.map(p => (
                      <Popover
                        content={
                          <img
                            alt=""
                            className="pic"
                            src={p}
                            style={{
                              display: 'block',
                              backgroundColor: '#e9e9e9',
                              maxWidth: 500,
                              maxHeight: 500,
                            }}
                          />
                        }
                        key={p}
                      >
                        <img
                          alt=""
                          className="pic"
                          src={p}
                          style={{
                            display: 'block',
                            backgroundColor: '#e9e9e9',
                            width: 70,
                            height: 70,
                          }}
                        />
                      </Popover>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {approveStatus !== applyFormTypeMap.CHECK ? (
            <StatusBox
              name={approver.userName || approver.nikeName}
              avt={approver.headImg}
              status={approveStatus}
              remark={approveReason}
            />
          ) : null}
        </StyledInfoModal>
      </Modal>
      <RejectModal
        visible={rejectModalVisible}
        onCancel={onRejectModelCancel}
        onOk={onRejectModelOk}
        placeholder="请输入拒绝原因，30字以内"
      />
    </React.Fragment>
  );
}

InfoModal.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  changeMessageCount: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeMessageCount: () => ({ type: 'changeMessageCount' }),
};

export default connect(null, mapDispatchToProps)(InfoModal);
