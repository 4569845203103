import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Input, Row, Col, Button } from 'antd';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import reqwest from 'reqwest';
import moment from 'moment';
import req from '../../req';
import avatar from '../../img/avatar-card@3x.png';
import tipImage from '../../img/pic-card@3x.png';
import isMacImage from '../../img/computed@3x.png';

import SearchBox from './search';

const StyledDiv = styled.div`
  // padding: 23px 0 32px;
  min-width: 952px;
  margin: 24px;
  background-color: #fff;
  border-radius: 2px;
  .left_section {
    width: 278px;
    height: 473px;
    padding: 40px 24px;
    border-radius: 2px;
    .tip_img {
      border-radius: 14px;
      height: 100%;
      width: 100%;
    }
  }
  .warn {
    height: 32px;
    padding: 5px 16px;
    border-radius: 2px;
    background-color: #fffbe6;
    font-size: 14px;
    margin-bottom: 49px;
    .t {
      margin-left: 8px;
    }
    .opera {
      margin-left: 20px;
      color: #1890ff;
    }
  }
  .row {
    .label {
      width: 70px;
      text-align: right;
      margin-top: -5px;
    }
  }
  .limit_width {
    padding-right: 161px;
  }
  .limit_border {
    padding-right: 21px;
  }
  .btn {
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    border-radius: 2px;
    color: #fff;
    background-color: #1890ff;
    cursor: pointer;
  }
  .opacity_low {
    opacity: 0.6;
  }
`;

const readStatusMap = {
  notDownLoadPlug: '00',
  notOpenPlug: '01',
  check: '02',
  loading: '03',
  success: '04',
  error: '05',
  upLoadSuccess: '06',
  upLoadError: '07',
  checkDownLoadPlug: '08',
};

const sexMap = {
  '1': '男',
  '2': '女',
};

const readCardTypeMap = {
  one: '00',
  circle: '01',
  stopCircle: '02',
};

const cacheSuccListStoreKey = 'uploadSuccList';
const cacheErrListStoreKey = 'uploadErrList';

function dataFormmat(str) {
  const year = str.slice(0, 4);
  const month = str.slice(4, 6);
  const day = str.slice(6);
  return [year, month, day];
}

function data2str(str, separator = '.') {
  return dataFormmat(str).join(separator);
}

// function debounce(event, time, flag) {
//   let timer = null;
//   return (...args) => {
//     clearTimeout(timer);
//     if (flag && !timer) {
//       event.apply(this, args);
//     }
//     timer = setTimeout(() => {
//       event.apply(this, args);
//     }, time);
//   };
// }

function Header() {
  return (
    <Layout.Header
      style={{
        backgroundColor: '#fff',
        padding: '16px 32px',
        height: 'auto',
        lineHeight: 'normal',
      }}
    >
      <div
        style={{
          fontSize: 20,
          fontWeight: 'bold',
        }}
      >
        身份证读卡器
      </div>
    </Layout.Header>
  );
}

function TipText({ status, readSuccCount, readErrCount }) {
  const showIcon = useCallback(type => {
    switch (true) {
      case status === readStatusMap.notDownLoadPlug:
      case status === readStatusMap.notOpenPlug: {
        return <InfoCircleFilled style={{ color: '#faad14' }} />;
      }
      case type === readStatusMap.loading:
      case type === readStatusMap.checkDownLoadPlug: {
        return (
          <LoadingOutlined
            style={{
              color: 'rgba(0, 0, 0, 0.65)',
              marginTop: 4,
              marginRight: 10,
            }}
          />
        );
      }
      case type === readStatusMap.check: {
        return (
          <InfoCircleFilled
            style={{ color: '#1890ff', marginTop: 4, marginRight: 10 }}
          />
        );
      }
      case type === readStatusMap.success:
      case type === readStatusMap.upLoadSuccess: {
        return (
          <CheckCircleFilled
            style={{ color: '#52c41a', marginTop: 4, marginRight: 10 }}
          />
        );
      }
      case type === readStatusMap.error:
      case type === readStatusMap.upLoadError: {
        return (
          <CloseCircleFilled
            style={{ color: '#f52c36', marginTop: 4, marginRight: 10 }}
          />
        );
      }
      default:
        break;
    }
    return null;
  });
  const showReadText = useCallback(type => {
    switch (true) {
      case type === readStatusMap.checkDownLoadPlug: {
        return '控件检测中';
      }
      case type === readStatusMap.loading: {
        return '读卡中';
      }
      case type === readStatusMap.check: {
        return '请将身份证放置在读卡器上，点击读卡按钮';
      }
      case type === readStatusMap.success: {
        return '读卡成功！';
      }
      case type === readStatusMap.error: {
        return '读卡失败，请重试！';
      }
      case type === readStatusMap.upLoadSuccess: {
        return '录入成功！';
      }
      case type === readStatusMap.upLoadError: {
        return '录入失败！';
      }
      case type === readStatusMap.notDownLoadPlug: {
        return '请检查控件是否正常安装，安装完成后刷新页面';
      }
      case type === readStatusMap.notOpenPlug: {
        return '未检测到设备，请将设备通过USB连接到电脑，连接后重新刷新页面';
      }
      default:
        break;
    }
    return null;
  });
  if (
    status === readStatusMap.checkDownLoadPlug ||
    status === readStatusMap.notDownLoadPlug ||
    status === readStatusMap.notOpenPlug
  ) {
    return (
      <div className="warn" data-flex="main:left cross:center">
        {showIcon(status)}
        <span className="t">{showReadText(status)}</span>
        {status === readStatusMap.notDownLoadPlug ||
        status === readStatusMap.checkDownLoadPlug ? (
          <span className="opera" data-flex-box=" 0">
            <Button
              type="link"
              href="http://cdn.emodor.com/idcardreader/invs-windows-ocx(全浏览器支持-带指纹和身份证比对-带超时-ab卡-端口记忆-分卡类型-设备热拔插-dt20210114).exe"
            >
              下载控件
            </Button>
          </span>
        ) : (
          ''
        )}
        {status !== readStatusMap.checkDownLoadPlug && (
          <span className="opera" data-flex-box="0">
            <Button type="link" onClick={() => window.location.reload()}>
              刷新
            </Button>
          </span>
        )}
      </div>
    );
  }
  return (
    <div data-flex="dir:left main:left cross:top" style={{ marginTop: 24 }}>
      <div style={{ width: 70 }} />
      {showIcon(status)}
      <div>
        <div style={{ fontSize: 14 }}>{showReadText(status)}</div>
        <div style={{ fontSize: 12, margin: '4px 0 10px 0' }}>
          累计读卡成功<span style={{ color: '#1890ff' }}>{readSuccCount}</span>
          次，读卡失败
          <span style={{ color: '#f52c36' }}>{readErrCount}</span>次
        </div>
      </div>
    </div>
  );
}

TipText.propTypes = {
  status: PropTypes.string.isRequired,
  readSuccCount: PropTypes.number.isRequired,
  readErrCount: PropTypes.number.isRequired,
};

function FormInfo({
  succCount,
  errCount,
  clickFn,
  info,
  form,
  isCircleRead,
  status,
}) {
  const { getFieldDecorator, setFieldsValue, resetFields } = form;
  const [showSearchBoxFlag, setShowSearchBoxFlag] = useState(false);
  const oneReadLastTimerRef = useRef(null);
  /**
   * 判断是否在有效期内
   */
  const isInDuring = useCallback((startData, endData) => {
    return endData - startData && moment().isBefore(data2str(endData, '-'));
  });
  const limitClickInterval = useCallback(type => {
    const startTime = new Date().getTime();
    const interval = 400;
    console.log('limitClickInterval', type, readCardTypeMap.one);
    if (type === readCardTypeMap.one) {
      if (
        !oneReadLastTimerRef.current ||
        startTime - oneReadLastTimerRef.current > interval
      ) {
        console.log('执行了', type, readCardTypeMap.one);

        oneReadLastTimerRef.current = new Date().getTime();
        clickFn(type);
      }
      return;
    }
    clickFn(type);
  });
  useEffect(() => {
    if (info && info.idCard) {
      const {
        realName,
        sex,
        nationality,
        birthday,
        address,
        idCard,
        idAgency,
        effectiveDate,
        expiryDate,
      } = info;
      setTimeout(() => {
        setFieldsValue({
          realName,
          sex,
          nationality,
          birthday,
          address,
          idCard,
          idAgency,
          during: `${data2str(effectiveDate)} — ${data2str(expiryDate)}`,
        });
      }, 300);
    } else {
      resetFields();
    }
  }, [status]);
  const showBtn = useCallback(() => {
    if (isCircleRead) {
      return (
        <>
          <span className="btn opacity_low">单次读卡</span>
          <span
            className="btn"
            icon="loading"
            style={{ marginLeft: 25 }}
            onClick={() => limitClickInterval(readCardTypeMap.stopCircle)}
          >
            <LoadingOutlined
              style={{
                color: '#fff',
                marginTop: 4,
                marginRight: 10,
              }}
            />
            停止读卡
          </span>
        </>
      );
    }
    switch (true) {
      case status === readStatusMap.checkDownLoadPlug:
      case status === readStatusMap.notDownLoadPlug:
      case status === readStatusMap.notOpenPlug:
      case status === readStatusMap.loading: {
        return (
          <>
            <span className="btn opacity_low">单次读卡</span>
            <span className="btn opacity_low" style={{ marginLeft: 25 }}>
              循环读卡
            </span>
          </>
        );
      }
      case status === readStatusMap.check:
      case status === readStatusMap.success:
      case status === readStatusMap.error:
      case status === readStatusMap.upLoadSuccess:
      case status === readStatusMap.upLoadError: {
        return (
          <>
            <span
              className="btn"
              onClick={() => limitClickInterval(readCardTypeMap.one)}
            >
              单次读卡
            </span>
            <span
              className="btn"
              style={{ marginLeft: 25 }}
              onClick={() => limitClickInterval(readCardTypeMap.circle)}
            >
              循环读卡
            </span>
          </>
        );
      }
      default:
        break;
    }
    return null;
  }, [isCircleRead, status]);
  const checkIdCard =
    !info || !info.idCard
      ? {}
      : {
          validateStatus: isInDuring(info.effectiveDate, info.expiryDate)
            ? 'success'
            : 'error',
          help: isInDuring(info.effectiveDate, info.expiryDate)
            ? ''
            : '身份证已过期',
        };
  return (
    <>
      <Form style={{ position: 'relative' }}>
        <Form.Item>
          <div className="row limit_width" data-flex="main:left cross: center">
            <div className="label" data-flex-box="0">
              姓名：
            </div>
            {getFieldDecorator('realName')(<Input data-flex-box="1" />)}
          </div>
        </Form.Item>
        <Form.Item>
          <div className="row limit_width" data-flex="main:left cross: center">
            <div className="label" data-flex-box="0">
              身份证号：
            </div>
            {getFieldDecorator('idCard')(<Input data-flex-box="1" />)}
          </div>
        </Form.Item>
        <Form.Item>
          <div className="row limit_width" data-flex="main:left cross: center">
            <div className="label" data-flex-box="0">
              出生日期：
            </div>
            {getFieldDecorator('birthday')(<Input data-flex-box="1" />)}
          </div>
        </Form.Item>
        <img
          src={info && info.electronicPhoto ? info.electronicPhoto : avatar}
          alt="avt"
          style={{
            position: 'absolute',
            right: 21,
            top: 0,
            width: 114,
            height: 144,
            border: '1px solid #d9d9d9',
          }}
        />
        <Row className="limit_border" gutter={37}>
          <Col span={12}>
            <Form.Item>
              <div className="row" data-flex="main:left cross: center">
                <div className="label" data-flex-box="0">
                  性别：
                </div>
                {getFieldDecorator('sex')(<Input data-flex-box="1" />)}
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...checkIdCard}>
              <div className="row" data-flex="main:left cross: center">
                <div className="label" data-flex-box="0">
                  有效期：
                </div>
                {getFieldDecorator('during')(<Input data-flex-box="1" />)}
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row className="limit_border" gutter={37}>
          <Col span={12}>
            <Form.Item>
              <div className="row" data-flex="main:left cross: center">
                <div className="label" data-flex-box="0">
                  民族：
                </div>
                {getFieldDecorator('nationality')(<Input data-flex-box="1" />)}
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <div className="row" data-flex="main:left cross: center">
                <div className="label" data-flex-box="0">
                  签发机关：
                </div>
                {getFieldDecorator('idAgency')(<Input data-flex-box="1" />)}
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div className="row limit_border" data-flex="main:left cross: center">
            <div className="label" data-flex-box="0">
              住址：
            </div>
            {getFieldDecorator('address')(<Input data-flex-box="1" />)}
          </div>
        </Form.Item>
        <Form.Item>
          <div className="row limit_border" data-flex="main:left cross: center">
            <div className="label" data-flex-box="0" />
            {showBtn()}
            {succCount || errCount ? (
              <div
                style={{
                  marginLeft: 32,
                  marginTop: -4,
                  color: 'rgba(0, 0, 0, 0.65)',
                }}
              >
                成功录入<span style={{ color: '#1890ff' }}>{succCount}</span>
                张身份证，其中
                <span style={{ color: '#f52c36' }}>{errCount}</span>
                张身份证过期
                <Button
                  type="link"
                  onClick={() => setShowSearchBoxFlag(!showSearchBoxFlag)}
                >
                  点击查看
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </Form.Item>
      </Form>
      <SearchBox
        visible={showSearchBoxFlag}
        succCount={succCount}
        errCount={errCount}
        toggleShowFn={() => setShowSearchBoxFlag(!showSearchBoxFlag)}
      />
    </>
  );
}
FormInfo.propTypes = {
  succCount: PropTypes.number.isRequired,
  errCount: PropTypes.number.isRequired,
  clickFn: PropTypes.func.isRequired,
  info: PropTypes.object,
  form: PropTypes.object,
  isCircleRead: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

FormInfo.defaultProps = {
  info: {},
  form: null,
};

const FormTemplate = Form.create({ name: 'form_info' })(FormInfo);

function Page() {
  // 读取成功/失败
  const [readSuccCount, setReadSuccCount] = useState(0);
  const [readErrCount, setReadErrCount] = useState(0);
  // 录入成功/失败
  const [succCount, setSuccCount] = useState(0);
  const [errCount, setErrCount] = useState(0);
  const [status, setStatus] = useState(readStatusMap.notDownLoadPlug);
  const [isCircleRead, setIsCircleRead] = useState(false);
  const [isMac, setIsMac] = useState(false);
  const mountedRef = useRef(false);
  const userFormRef = useRef(null);
  const timeRef = useRef(0);
  const readCrtSuccRef = useRef(0);
  const readCrtErrRef = useRef(0);
  const isCircleReadRef = useRef(false);
  /**
   * 判断是否在有效期内
   */
  const isInDuring = useCallback((startData, endData) => {
    return endData - startData && moment().isBefore(data2str(endData, '-'));
  });
  /**
   * 处理数据
   */
  const formatForm = useCallback(
    ({
      partyName,
      gender,
      nation,
      bornDay,
      certAddress,
      certNumber,
      certOrg,
      effDate,
      expDate,
      identityPic,
      cardAimg,
      cardBimg,
      cardFimg,
    }) => {
      const obj = {
        realName: partyName,
        sex: sexMap[gender],
        nationality: nation,
        birthday: data2str(bornDay),
        address: certAddress,
        idCard: certNumber,
        idAgency: certOrg,
        effectiveDate: effDate,
        expiryDate: expDate,
        electronicPhoto: `data:image/jpg;base64,${identityPic}`,
        idcardPhoto: `data:image/jpg;base64,${cardAimg}`,
        idcardBackPhoto: `data:image/jpg;base64,${cardBimg}`,
        idcardFrontPhoto: `data:image/jpg;base64,${cardFimg}`,
      };
      console.table(obj);
      return obj;
    }
  );
  const isInCachSuccList = useCallback(data => {
    const cachSuccList =
      JSON.parse(sessionStorage.getItem(cacheSuccListStoreKey)) || [];
    console.log('isInCachSuccList', data, cachSuccList);
    return !!cachSuccList.find(item => item.idCard === data.idCard);
  });
  /**
   * 存储和过滤掉重复的数据
   * flag: boolean, 代表成功和失败
   */
  const formatCachList = useCallback((data, flag) => {
    const cachSuccList =
      JSON.parse(sessionStorage.getItem(cacheSuccListStoreKey)) || [];
    const cachErrList =
      JSON.parse(sessionStorage.getItem(cacheErrListStoreKey)) || [];
    const isInCachErrList = cachErrList.find(
      item => item.idCard === data.idCard
    );
    // 失败
    if (!flag) {
      if (!isInCachErrList) {
        cachErrList.unshift({
          ...data,
          isInDuring: false,
        });
        sessionStorage.setItem(
          cacheErrListStoreKey,
          JSON.stringify(cachErrList)
        );
      }
      return [cachSuccList.length, cachErrList.length];
    }
    // 成功
    if (isInCachErrList) {
      cachErrList.filter(item => item.idCard !== data.idCard);
      sessionStorage.setItem(cacheErrListStoreKey, JSON.stringify(cachErrList));
    }
    if (!isInCachSuccList(data)) {
      cachSuccList.unshift(data);
      sessionStorage.setItem(
        cacheSuccListStoreKey,
        JSON.stringify(cachSuccList)
      );
    }
    return [cachSuccList.length, cachErrList.length];
  });
  const toSaveWorkerBase = useCallback(() => {
    const { effectiveDate, expiryDate } = userFormRef.current;
    setStatus(readStatusMap.loading);
    if (isInCachSuccList(userFormRef.current)) {
      setStatus(readStatusMap.upLoadSuccess);
      return;
    }
    req.sys
      .saveWorkerBaseByCardReader(
        JSON.stringify({
          ...userFormRef.current,
          effectiveDate: data2str(effectiveDate),
          expiryDate: data2str(expiryDate),
        })
      )
      .then(res => {
        if (!mountedRef.current) {
          return;
        }
        if (res.code !== 0) {
          setStatus(readStatusMap.upLoadError);
          req.err.show(res);
          return;
        }
        if (isInDuring(effectiveDate, expiryDate)) {
          setStatus(readStatusMap.upLoadSuccess);
          setSuccCount(formatCachList(userFormRef.current, true)[0]);
        } else {
          setStatus(readStatusMap.upLoadError);
        }
        console.log('toSaveWorkerBase succ', res.data);
      })
      .catch(err => {
        setStatus(readStatusMap.upLoadError);
        req.err.show(err);
      });
  });
  const budgeSysIsWindows = useCallback(() => {
    return new Promise(res => {
      const isMacSys = /macintosh|mac os x/i.test(navigator.userAgent);
      if (isMacSys) {
        setIsMac(true);
      }
      return !isMacSys ? res() : '';
    });
  });
  /**
   * 首次加载检测是否装了驱动，默认3S没返回就是没装驱动
   */
  const firstCheckHasDownLoad = useCallback(async () => {
    const interval = 1000 * 3;
    let isSucc = false;

    setStatus(readStatusMap.checkDownLoadPlug);

    reqwest({
      url:
        'http://localhost:8099/?cmd=readcardnomal&charset=utf8?cmd=readcardnomal&charset=utf8',
      type: 'jsonp',
      cache: false,
      dataType: 'jsonp',
    })
      .then(r1 => {
        const { resultFlag, errorMsg } = r1;
        isSucc = true;
        if (resultFlag !== 0) {
          console.log(' firstCheckHasDownLoad 读卡失败', errorMsg);
          switch (errorMsg) {
            case 'find card error': {
              setStatus(readStatusMap.notDownLoadPlug);
              break;
            }
            case 'no idcard device': {
              setStatus(readStatusMap.notOpenPlug);
              break;
            }
            case 'read card error': {
              setStatus(readStatusMap.check);
              break;
            }
            default:
              break;
          }
          return;
        }
        setStatus(readStatusMap.check);
      })
      .catch(err => {
        console.log('err', err);
        req.err.show(err);
      });
    setTimeout(() => {
      console.log('firstCheckHasDownLoad', isSucc);
      if (!isSucc) {
        setStatus(readStatusMap.notDownLoadPlug);
      }
    }, interval);
  }, []);
  /**
   * 获取读卡信息
   */
  const getCardInfo = useCallback(() => {
    userFormRef.current = {};
    setStatus(readStatusMap.loading);
    reqwest({
      url:
        'http://localhost:8099/?cmd=readcardnomal&charset=utf8?cmd=readcardnomal&charset=utf8',
      type: 'jsonp',
      cache: false,
      dataType: 'jsonp',
    })
      .then(r1 => {
        console.log('getCardInfo sucess', r1);
        const { resultFlag, errorMsg, resultContent } = r1;
        if (resultFlag !== 0) {
          console.log('读卡失败', errorMsg);
          switch (errorMsg) {
            case 'find card error': {
              console.log('装了驱动，没连接设备');
              setStatus(readStatusMap.notDownLoadPlug);
              break;
            }
            case 'no idcard device': {
              setStatus(readStatusMap.notOpenPlug);
              break;
            }
            case 'read card error': {
              setStatus(readStatusMap.check);
              readCrtErrRef.current += 1;
              setReadErrCount(readCrtErrRef.current);
              break;
            }
            default:
              break;
          }
          return;
        }
        setStatus(readStatusMap.success);
        userFormRef.current = formatForm(resultContent);
        readCrtSuccRef.current = readSuccCount + 1;
        setReadSuccCount(readCrtSuccRef.current);
        toSaveWorkerBase();
      })
      .catch(err => {
        console.log('err', err);
        req.err.show(err);
      });
  }, [readSuccCount]);

  /**
   * 循环调用-获取读卡信息
   */
  const getCardInfoRes = useCallback(() => {
    console.log('getCardInfoRes', readCrtSuccRef.current);
    userFormRef.current = {};
    setStatus(readStatusMap.loading);
    return new Promise(res => {
      reqwest({
        url:
          'http://localhost:8099/?cmd=readcardnomal&charset=utf8?cmd=readcardnomal&charset=utf8',
        type: 'jsonp',
        cache: false,
        dataType: 'jsonp',
      })
        .then(r1 => {
          console.log('getCardInfoRes sucess', r1);
          const { resultFlag, errorMsg, resultContent } = r1;
          if (resultFlag !== 0) {
            switch (errorMsg) {
              case 'find card error': {
                setStatus(readStatusMap.notDownLoadPlug);
                clearInterval(timeRef.current);
                break;
              }
              case 'no idcard device': {
                setStatus(readStatusMap.notOpenPlug);
                clearInterval(timeRef.current);
                break;
              }
              case 'read card error': {
                readCrtErrRef.current += 1;
                setReadErrCount(readCrtErrRef.current);
                res(false);
                break;
              }
              default:
                break;
            }
            return;
          }
          setStatus(readStatusMap.success);
          userFormRef.current = formatForm(resultContent);
          readCrtSuccRef.current += 1;
          console.log('readCrtSuccRef.current', readCrtSuccRef.current);
          setReadSuccCount(readCrtSuccRef.current);
          toSaveWorkerBase();
          res(true);
        })
        .catch(err => {
          console.log('err', err);
          req.err.show(err);
        });
    });
  }, []);
  const circleErrDealWith = useCallback(() => {
    const entryTime = new Date().getTime();
    const interval = 1500;
    const timeOutTime = 1000 * 3;
    let timer = null;
    let crtTime = new Date().getTime();
    let isSucc = false;
    getCardInfoRes().then(flag => {
      if (!flag) {
        clearInterval(timeRef.current);
        return;
      }
      isSucc = true;
    });
    timer = setInterval(() => {
      if (isSucc) {
        clearInterval(timer);
        return;
      }
      if (crtTime - entryTime < timeOutTime) {
        crtTime = new Date().getTime();
        getCardInfoRes().then(flag => {
          if (!flag) {
            clearInterval(timeRef.current);
            if (!isCircleReadRef.current) {
              clearInterval(timer);
              setStatus(readStatusMap.check);
            }
            return;
          }
          clearInterval(timer);
          // eslint-disable-next-line no-use-before-define
          circleGetCardInfo();
        });
      } else {
        clearInterval(timer);
        setStatus(readStatusMap.error);
        console.log('isCircleRead', isCircleRead);
        if (isCircleReadRef.current) {
          setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            circleGetCardInfo();
          }, 400);
        }
      }
    }, interval);
  });

  const circleGetCardInfo = useCallback(() => {
    circleErrDealWith();
    clearInterval(timeRef.current);
    timeRef.current = setInterval(() => {
      circleErrDealWith();
    }, 3000);
  });

  const clickFnCallBack = useCallback(type => {
    console.log('clickFnCallBack 点击了', type);
    switch (true) {
      case type === readCardTypeMap.one: {
        getCardInfo();
        break;
      }
      case type === readCardTypeMap.circle: {
        setIsCircleRead(true);
        isCircleReadRef.current = true;
        circleGetCardInfo();
        break;
      }
      case type === readCardTypeMap.stopCircle: {
        clearInterval(timeRef.current);
        setIsCircleRead(false);
        isCircleReadRef.current = false;
        break;
      }
      default:
        break;
    }
  });

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
    }
    const cachSuccList =
      JSON.parse(sessionStorage.getItem(cacheSuccListStoreKey)) || [];
    const cachErrList =
      JSON.parse(sessionStorage.getItem(cacheErrListStoreKey)) || [];
    if (cachSuccList.length) {
      setSuccCount(cachSuccList.length);
    }
    if (cachErrList.length) {
      setErrCount(cachErrList.length);
    }
    budgeSysIsWindows().then(async () => {
      firstCheckHasDownLoad();
    });
    return () => {
      mountedRef.current = false;
      sessionStorage.setItem(cacheErrListStoreKey, JSON.stringify([]));
      sessionStorage.setItem(cacheSuccListStoreKey, JSON.stringify([]));
    };
  }, []);
  return (
    <React.Fragment>
      <Header />
      <StyledDiv data-flex="main: center">
        <div className="left_section" data-flex-box="0">
          <img className="tip_img" src={tipImage} alt="gif" />
        </div>
        <div style={{ width: 24 }} />
        {isMac ? (
          <div data-flex-box="1" data-flex="dir:top main:center cross:center">
            <img
              src={isMacImage}
              alt="img"
              style={{
                width: 72,
                height: 67,
              }}
            />
            <span style={{ marginTop: 22, color: '#858b96', fontSize: 14 }}>
              请在windows系统下使用，暂不支持mac系统
            </span>
          </div>
        ) : (
          <div data-flex-box="1">
            <TipText
              readSuccCount={readSuccCount}
              readErrCount={readErrCount}
              status={status}
            />
            <FormTemplate
              status={status}
              succCount={succCount}
              readSuccCount={readSuccCount}
              errCount={errCount}
              clickFn={clickFnCallBack}
              info={userFormRef.current}
              isCircleRead={isCircleRead}
            />
          </div>
        )}
      </StyledDiv>
    </React.Fragment>
  );
}
export default Page;
