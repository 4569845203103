const applyFormTypeMap = {
  check: '00',
  agree: '01',
  reject: '02',
  reset: '03',
};

const applyFormStatusMap = {
  [applyFormTypeMap.check]: '审批中',
  [applyFormTypeMap.agree]: '已同意',
  [applyFormTypeMap.reject]: '已拒绝',
  [applyFormTypeMap.reset]: '已撤销',
};

const applyFormTipMap = {
  [applyFormTypeMap.agree]: '是否确定同意申请？',
  [applyFormTypeMap.reject]: '是否确定拒绝申请？',
  [applyFormTypeMap.reset]: '是否确定撤销申请？',
};

const joinType = {
  company: {
    label: '公司',
    value: '00',
  },
  project: {
    label: '项目',
    value: '01',
  },
};

const applyFormResultClass = {
  [applyFormTypeMap.agree]: 'agreed',
  [applyFormTypeMap.reject]: 'reject',
  [applyFormTypeMap.reset]: 'reset',
};

export default {
  applyFormTypeMap,
  applyFormStatusMap,
  applyFormTipMap,
  joinType,
  applyFormResultClass,
};
