const navMap = {
  home: {
    path: '/',
    label: '首页',
  },
  registerCompany: {
    path: '/register_company',
    label: '注册公司',
  },
  dashboard: {
    path: '/dashboard',
    label: '数据星盘',
  },
  blacklist: {
    path: '/blacklist',
    label: '黑名单',
  },
  blacklistAuditing: {
    path: '/blacklist_auditing',
    label: '黑名单待审核',
  },
  blacklistApply: {
    path: '/blacklist/apply',
    label: '黑名单审批',
  },
  // company: {
  //   path: '/company',
  //   label: '公司概况',
  // },
  companyData: {
    path: '/company/data',
    label: '公司总览',
  },
  companyOverview: {
    path: '/company/overview',
    label: '数据看板',
  },
  companyWorkerData: {
    path: '/company/worker_data',
    label: '工人画像',
  },
  // project: {
  //   path: '/project',
  //   label: '项目数据',
  // },
  projectOverview: {
    path: '/project/overview',
    label: '项目管理',
  },
  dataExport: {
    path: '/data_export',
    label: '数据报表导出',
  },
  dataExportAttend: {
    path: '/data_export/attend',
    label: '考勤数据',
  },
  dataExportSalary: {
    path: '/data_export/salary',
    label: '工钱数据',
  },
  dataExportRoster: {
    path: '/data_export/roster',
    label: '花名册',
  },
  dataExportOrg: {
    path: '/data_export/org',
    label: '树状图',
  },
  organization: {
    path: '/organization/overview',
    label: '组织架构',
  },
  logAduit: {
    path: '/log_aduit',
    label: '日志审计',
  },
  learningResource: {
    path: '/learning_resource',
    label: '学习资源管理',
  },
  loginSecuritySetting: {
    path: '/login_security_setting',
    label: '登录安全设置',
  },
  workerComplaints: {
    path: '/worker_complaints',
    label: '工人投诉',
  },
};

export default navMap;
