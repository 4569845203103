import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Result } from 'antd';

const Container = styled.div`
  border-radius: 2px;
  background-color: #fff;
  margin: 24px;
  padding: 24px;
`;

const cases = {
  unauthorited: {
    title: '没有权限',
    subTitle: '抱歉，您没有该页面的访问权限',
  },
  prohibit: {
    title: '账号已停用',
    subTitle: '抱歉，您的账号已被管理员停用',
  },
};

function Page({ prohibit, title, subTitle }) {
  const infos = prohibit ? cases.prohibit : cases.unauthorited;
  return (
    <Container>
      <Result
        status="403"
        title={title || infos.title}
        subTitle={subTitle || infos.subTitle}
      />
    </Container>
  );
}

Page.propTypes = {
  prohibit: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

Page.defaultProps = {
  prohibit: false,
  title: null,
  subTitle: null,
};

export default Page;
