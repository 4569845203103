import React from 'react';
import { Modal, Avatar } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import req from '../../../req';
import { timestamp2dateTime } from '../../../utils/date';
import './index.css';

const StyledDiv = styled.div`
  .item {
    background-color: rgb(250, 250, 250);
    padding: 12px 12px 0 12px;
  }
  .item .lable {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
`;
function mapStateToProps(state) {
  return {
    unReadMessageCount: state.unReadMessageCount,
  };
}
const mapDispatchToProps = {
  changeMessageCount: () => ({ type: 'changeMessageCount' }),
};

function BottomInfos({ status, name, avatar }) {
  const budgeStatus = {
    '00': '审核中',
    '01': '已同意',
    '02': '已拒绝',
    '03': '撤回',
  };
  return (
    <div
      data-flex="main:left cross:center box:first"
      style={{
        marginTop: 20,
        backgroundColor: '#fafafa',
        padding: '15px 0',
        borderRadius: 2,
      }}
    >
      <div style={{ display: 'inline-block', width: 100, textAlign: 'right' }}>
        <Avatar
          src={avatar}
          size="small"
          style={{ color: '#6a8ec6', backgroundColor: '#d5e9ff' }}
        >
          {name.substring(name.length - 2) || name.substring(name.length - 1)}
        </Avatar>
      </div>
      <div className="content">
        <span style={{ margin: '0 10px' }}>{name}</span>
        {status === '01' || status === '02' ? (
          <>
            {budgeStatus[status]}
            该申请
          </>
        ) : (
          <div>申请已撤销</div>
        )}
      </div>
    </div>
  );
}

function Applayinfos({ messageInfos }) {
  const applyName = messageInfos.companyJoinApply.applicant.userName;
  return (
    <StyledDiv>
      <div className="item" data-flex="main:center cross:center box:first">
        <div className="lable">申请人：</div>
        <div className="content">
          <Avatar
            src={messageInfos.companyJoinApply.applicant.img}
            style={{ color: '#6a8ec6', backgroundColor: '#d5e9ff' }}
            size="small"
          >
            {applyName.substring(applyName.length - 2) ||
              applyName.substring(applyName.length - 1)}
          </Avatar>
          <span style={{ marginLeft: 10 }}>{applyName || '-'}</span>
        </div>
      </div>
      <div className="item" data-flex="main:center cross:center box:first">
        <div className="lable">联系电话：</div>
        <div className="content">
          {messageInfos.companyJoinApply.applicant.mobile}
        </div>
      </div>
      <div className="item" data-flex="main:center cross:center box:first">
        <div className="lable">申请公司：</div>
        <div className="content">
          {messageInfos.companyJoinApply.applyCompanyName}
        </div>
      </div>
      <div className="item" data-flex="main:center cross:center box:first">
        <div className="lable">
          申请
          {messageInfos.title.slice(2, 4) === '添加' ||
          messageInfos.title.slice(2, 4) === '加入'
            ? messageInfos.title.slice(2, 4)
            : ''}
          ：
        </div>
        <div className="content">
          {messageInfos.companyJoinApply.joinCompanyName}
        </div>
      </div>
      <div
        className="item"
        data-flex="main:center cross:center box:first"
        style={{ padding: 12 }}
      >
        <div className="lable">申请时间：</div>
        <div className="content">
          {timestamp2dateTime(messageInfos.companyJoinApply.applyTime, '.')}
        </div>
      </div>
      {messageInfos.companyJoinApply.status !== '00' && (
        <BottomInfos
          status={messageInfos.companyJoinApply.status}
          name={messageInfos.companyJoinApply.approver.userName}
          avatar={messageInfos.companyJoinApply.approver.img}
        />
      )}
    </StyledDiv>
  );
}

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageInfos: null,
    };
    this.handleOk = this.handleOk.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { id, flag } = this.props;
    if (prevProps.flag !== flag && id && flag) {
      this.getMsgDetails();
    }
  }

  getMsgDetails() {
    const {
      listernerAgreeOrReject,
      changeMessageCount,
      crtUserInfos,
      id,
    } = this.props;
    const that = this;
    req.sys
      .getMessageDetails({
        messageId: id,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        console.log('执行了getMsgDetails');
        const { companyJoinApply, readStatus } = res.data;
        if (
          companyJoinApply.applicant.id === crtUserInfos.id &&
          companyJoinApply.status !== '00' &&
          !readStatus
        ) {
          console.log('符合条件');
          changeMessageCount();
          listernerAgreeOrReject();
        }
        that.setState({
          messageInfos: res.data,
        });
      })
      .catch(req.err.show);
  }

  handleOk(type, id) {
    const {
      // history,
      // location,
      listernerAgreeOrReject,
      changeMessageCount,
    } = this.props;
    const that = this;
    req.sys
      .agreeOrRejectRight(
        JSON.stringify({
          recordId: id,
          approvedType: type,
        })
      )
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        that.getMsgDetails();
        changeMessageCount();
        listernerAgreeOrReject();
        console.log('对消息进行了处理，同意或者拒绝');
      })
      .catch(req.err.show);
  }

  render() {
    const that = this;
    const { flag, crtUserInfos, hideStatus } = this.props;
    const { messageInfos } = this.state;
    if (!messageInfos) {
      return null;
    }
    const options = {
      title: messageInfos.title,
      visible: flag,
      onOk(e) {
        that.handleOk('01', messageInfos.id, e);
      },
      onCancel(e) {
        if (/cancel_btn/g.test(e.currentTarget.className)) {
          that.handleOk('00', messageInfos.id);
          return;
        }
        hideStatus();
      },
      destroyOnClose: true,
      wrapClassName: 'limit_box_wrap_change_zindex',
      maskStyle: { zIndex: 1035 },
      className: 'limit_model_height_width',
      cancelText: '拒绝',
      okText: '同意',
    };
    if (messageInfos.companyJoinApply.status !== '00') {
      options.footer = null;
    }
    // console.log(this.props.location);
    return (
      <Modal {...options} cancelButtonProps={{ className: 'cancel_btn' }}>
        <p style={{ fontSize: '16px', color: '#535a64' }}>
          {messageInfos.content}
        </p>
        {messageInfos.companyJoinApply.applicant.id !== crtUserInfos.id ? (
          <Applayinfos messageInfos={messageInfos} />
        ) : (
          <p>
            {timestamp2dateTime(
              messageInfos.companyJoinApply.approvedTime,
              '.'
            )}
          </p>
        )}
      </Modal>
    );
  }
}
Details.propTypes = {
  flag: PropTypes.bool.isRequired,
  hideStatus: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  listernerAgreeOrReject: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  crtUserInfos: PropTypes.object.isRequired,
  changeMessageCount: PropTypes.func,
};

Details.defaultProps = {
  listernerAgreeOrReject: () => {},
  changeMessageCount: () => {},
};
BottomInfos.propTypes = {
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
};

Applayinfos.propTypes = {
  messageInfos: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Details));
