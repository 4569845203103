const typeMap = {
  ADD_CHILD_COMPANY: '00',
  ADD_PARENT_COMPANY: '01',
  ADD_BLACKLIST: '02',
  JOIN_COMPANY: '03',
  JOIN_PROJECT: '04',
  PROJECT_ADD_COMPANY: '11',
  PROJECT_MIGRATE_COMPANY: '12',
  SYSTEM_NOTICE: '30',
  UPPER_LEVEL_ITEMS_TO_LOWER_LEVEL: '05',
  LOWER_LEVEL_TO_UPPER_LEVEL_ITEMS: '06',
  AGREE_TO_MANAGE_PERMISSION: '07',
  CANCEL_TO_MANAGE_PERMISSION: '08',
  ADD_COMPANYMEMBERS: '09',
  ADD_PROJECTMEMBERS: '10',
  ADD_COMPANY_MEMBER_PERMISSION: '13',
  ADD_PROJECT_MEMBER_PERMISSION: '14',
  ATTEND_MAKEUP: '15',
  ATTEND_MISTAKE: '16',
  MODIFY_ATTEND_RECORD: '17',
  WORKER_COMPLAINTS: '85', // 工人投诉
};

const applyFormTypeMap = {
  CHECK: '00',
  AGREE: '01',
  REJECT: '02',
  RESET: '03',
};

export default {
  typeMap,
  applyFormTypeMap,
};
