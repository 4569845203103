import loadable from '@loadable/component';

const Overview = loadable(() => import('./Overview/index'));
const WorkerData = loadable(() => import('./WorkerData/index'));
const Data = loadable(() => import('./Data/index'));

export default {
  Overview,
  WorkerData,
  Data,
};
