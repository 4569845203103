import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import { Modal, Popover } from 'antd';
import { connect } from 'react-redux';

import req from '../../../req';
import config from '../../../config';
import { timestamp2dateTime } from '../../../utils/date';
import StyledInfoModal from '../InfoModal/StyledInfoModal';
import StatusBox from '../InfoModal/StatusBox';
import RejectModal from '../../Blacklist/RejectModal';
import UserCard from '../UserCard';

const { applyFormTypeMap } = config.joinCompany;

const { PROJECT_MIGRATE_COMPANY } = config.message.typeMap;

function InfoModal({ id, visible, onCancel, onOk, changeMessageCount }) {
  const [update, setUpdate] = useState(0);
  const [info, setInfo] = useState({});
  const [rejectModalVisible, setRejectModalVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      setInfo({});
    }
  }, [visible]);

  useEffect(() => {
    if (!update) {
      return;
    }
    changeMessageCount();
  }, [update]);

  useEffect(() => {
    let didCancel = false;
    if (!visible || !id) {
      return () => {};
    }
    req.sys
      .getMessageDetails({
        messageId: id,
      })
      .then(res => {
        if (didCancel) {
          return;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        if (
          res.data.projectJoinApplyDTO &&
          res.data.projectJoinApplyDTO.status !== applyFormTypeMap.check &&
          !res.data.readStatus
        ) {
          changeMessageCount();
        }
        setInfo(res.data);
        console.log(res.data);
      })
      .catch(err => {
        if (didCancel) {
          return;
        }
        req.err.show(err);
      });
    return () => {
      didCancel = true;
    };
  }, [id, visible, update]);

  const onModalOk = useCallback(() => {
    Modal.confirm({
      title: '提示',
      content: '同意后，项目将并入到公司中，是否确定同意？',
      onOk() {
        req.project
          .confirmTransfer(
            {
              id: info.recordId,
              status: '01',
            },
            JSON.stringify({})
          )
          .then(res => {
            if (res.code !== 0) {
              req.err.show(res);
              return;
            }
            onOk();
            setUpdate(new Date().getTime());
          })
          .catch(req.err.show);
      },
    });
  }, [info.recordId]);

  const onModalCancel = useCallback(e => {
    if (!/cancel_btn/.test(e.currentTarget.className)) {
      onCancel();
      return;
    }
    setRejectModalVisible(true);
  }, []);

  const onRejectModelCancel = useCallback(() => {
    setRejectModalVisible(false);
  }, []);

  const onRejectModelOk = useCallback(
    text => {
      req.project
        .confirmTransfer(
          {
            id: info.recordId,
            status: '02',
          },
          JSON.stringify({
            refuseReason: text,
          })
        )
        .then(res => {
          if (res.code !== 0) {
            req.err.show(res);
            return;
          }
          setRejectModalVisible(false);
          setUpdate(new Date().getTime());
        })
        .catch(req.err.show);
    },
    [info.recordId]
  );

  if (!info.id || !visible) {
    return null;
  }
  const { projectJoinApplyDTO } = info;
  const options = {};
  if (projectJoinApplyDTO.status !== applyFormTypeMap.check) {
    options.footer = null;
  }

  const {
    status,
    applyUser,
    approverList,
    refuseReason,
    applyProjectInfo,
    joinCompanyInfo,
  } = projectJoinApplyDTO;

  const approver =
    status !== applyFormTypeMap.reset ? approverList[0] : applyUser;

  return (
    <React.Fragment>
      <Modal
        {...options}
        visible={visible}
        title={info.title || ''}
        cancelText="拒绝"
        okText="同意"
        onCancel={onModalCancel}
        onOk={onModalOk}
        cancelButtonProps={{ className: 'cancel_btn' }}
      >
        <StyledInfoModal>
          <div className="head">{info.content || '-'}</div>
          <div className="info_box">
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                申请人：
              </div>
              <img
                className="avt"
                src={applyUser.img || config.defaultAvt}
                alt="xxx"
                data-flex-box="0"
              />
              <div className="val">
                <Popover
                  content={<UserCard number={applyUser.mobile} />}
                  trigger={['click']}
                  placement="right"
                >
                  {applyUser.userName || applyUser.name || applyUser.nikeName}
                  <RightOutlined />
                </Popover>
              </div>
            </div>
            {info.messageType === PROJECT_MIGRATE_COMPANY ? (
              <div className="row" data-flex="main:left">
                <div className="label" data-flex-box="0">
                  项目：
                </div>
                <div className="val">{applyProjectInfo.name}</div>
              </div>
            ) : (
              <div className="row" data-flex="main:left">
                <div className="label" data-flex-box="0">
                  公司：
                </div>
                <div className="val">{joinCompanyInfo.name}</div>
              </div>
            )}
            <div className="row" data-flex="main:left">
              <div className="label" data-flex-box="0">
                申请时间：
              </div>
              <div className="val">
                {timestamp2dateTime(info.createdTime, '.')}
              </div>
            </div>
          </div>
          {status !== applyFormTypeMap.check ? (
            <StatusBox
              name={approver.userName || approver.nikeName}
              avt={approver.img}
              status={status}
              remark={refuseReason}
            />
          ) : null}
        </StyledInfoModal>
      </Modal>
      <RejectModal
        visible={rejectModalVisible}
        onCancel={onRejectModelCancel}
        onOk={onRejectModelOk}
      />
    </React.Fragment>
  );
}

InfoModal.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  changeMessageCount: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeMessageCount: () => ({ type: 'changeMessageCount' }),
};

export default connect(null, mapDispatchToProps)(InfoModal);
