import transform from './transform';
import session from './session';
import sys from './sys';
import company from './company';
import user from './user';
import project from './project';
import team from './team';
import dashboard from './dashboard';
import message from './message';
import education from './education';
import security from './loginSecuritySetting';
import complaints from './complaints';

export default {
  install(req) {
    req.session = session;
    req.sys = transform(sys);
    req.company = transform(company);
    req.user = transform(user);
    req.project = transform(project);
    req.team = transform(team);
    req.dashboard = transform(dashboard);
    req.message = transform(message);
    req.education = transform(education);
    req.security = transform(security);
    req.complaints = transform(complaints);
  },
};
