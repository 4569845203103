import companyRoleCode from './company_role_code';
import projectRoleCode from './project_role_code';
import authority from './authority';
import baseAuthority from './baseAuthority';
import joinCompany from './join_company';
import message from './message';
import roleType from './roleType';
import healthCode from './health_code';

import defaultLogo from '../img/default_company@3x.png';
import defaultAvt from '../img/default-face.png';

const config = {
  defaultLogo,
  defaultAvt,
  companyRoleCode,
  projectRoleCode,
  authority,
  baseAuthority,
  joinCompany,
  message,
  roleType,
  healthCode,
};

export default config;
