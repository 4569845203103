const api = {
  getMonthAttendData: {
    url: '/bi-api/api/worker/month/attendances',
    method: 'post',
  },
  getDateAttendRecord: {
    url: '/bi-api/api/worker/getDayAttendanceRecords',
    method: 'post',
  },
  exportAttendData: {
    url: '/bi-api/api/group/exportAttendanceReport',
  },
  exportSalaryData: {
    url: '/bi-api/api/group/exportPayReport',
    method: 'post',
  },
  getWorkerInfo: {
    url: '/bi-api/api/worker/getWorkerDetails',
  },
  updateSalaryType: {
    url: '/bi-api/api/group/setGroupSalaryType/:groupId/:salaryTypeCode',
    method: 'post',
  },
  exportSalaryReport: {
    url: '/bi-api/api/group/v2/exportPayReport',
  },
  exportAttendReport: {
    url: '/bi-api/api/group/v2/exportAttendanceReport',
    method: 'POST',
  },
  getWorkerListZip: {
    url: '/bi-api/api/group/v2/exportWorkerRoster',
  },
  // v3.1.0 异步生成农民工工资支付表
  exportWorkerPayScheduleReport: {
    url: '/bi-api/api/report/export/exportWorkerPayScheduleReport',
    method: 'POST',
  },
};

export default api;
