import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
// import { Link } from 'react-router-dom';
import { Input, Switch, Modal, message } from 'antd';
// import styled from 'styled-components';
import config from '../../../../../config';
import req from '../../../../../req';
import manager from '../../../../../img/manager@3x.png';
import manage from '../../../../../img/manage@3x.png';
import daily from '../../../../../img/daily@3x.png';
import units from '../../../../../img/units@3x.png';
import money from '../../../../../img/money@3x.png';

const { authority } = config;

class positionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getCompanyPositionList: [],
      getProjectPositionList: [],
      companyAuthority: Object.keys(config.authority.company)
        .map(k => config.authority.company[k])
        .reduce((pV, cV) => {
          const p = pV;
          p[`${cV}`] = false;
          return p;
        }, {}),
      projectAuthority: Object.keys(config.authority.project)
        .map(k => config.authority.project[k])
        .reduce((pV, cV) => {
          const p = pV;
          p[`${cV}`] = cV === config.authority.project.VIEW_DASHBOARD;
          return p;
        }, {}),
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onAuthorityChange = this.onAuthorityChange.bind(this);
  }

  componentDidMount() {
    this.getPositionList('00');
    this.getPositionList('01');
  }

  onSubmit() {
    const that = this;
    const {
      getCompanyPositionList,
      getProjectPositionList,
      companyAuthority,
      projectAuthority,
    } = this.state;
    const companyAuthorityList = Object.keys(companyAuthority).filter(
      k => companyAuthority[k]
    );
    const projectAuthorityList = Object.keys(projectAuthority).filter(
      k => projectAuthority[k]
    );
    const { form, onOk, positionType } = this.props;
    const { validateFields } = form;
    const companyNameList = [];
    const projectNameList = [];
    getCompanyPositionList.forEach(item => {
      companyNameList.push(item.name);
    });
    getProjectPositionList.forEach(item => {
      projectNameList.push(item.name);
    });
    const positionNameList = positionType ? projectNameList : companyNameList;
    const type = positionType ? '01' : '00';
    const positionAuthorityList = positionType
      ? projectAuthorityList
      : companyAuthorityList;
    validateFields((error, val) => {
      if (error) {
        console.log(error, val);
        return;
      }
      // 判断职位名是否存在
      const isExistPosition = positionNameList.some(
        item => item === val.positionName
      );
      const data = {
        name: val.positionName,
        permissionCodes: positionAuthorityList,
        positionType: type,
      };
      req.company
        .addPosition(JSON.stringify(data))
        .then(res => {
          if (val.positionName.replace(/\s+/g, '') === '') {
            req.err.show('职位名称不能为空');
            return;
          }
          if (isExistPosition) {
            req.err.show('该职位名称已存在');
            return;
          }
          if (res.code !== 0) {
            req.err.show(res);
          } else {
            message.success('新增职位成功');
            onOk();
            that.setState({
              companyAuthority: Object.keys(config.authority.company)
                .map(k => config.authority.company[k])
                .reduce((pV, cV) => {
                  const p = pV;
                  p[`${cV}`] = false;
                  return p;
                }, {}),
              projectAuthority: Object.keys(config.authority.project)
                .map(k => config.authority.project[k])
                .reduce((pV, cV) => {
                  const p = pV;
                  p[`${cV}`] = cV === config.authority.project.VIEW_DASHBOARD;
                  return p;
                }, {}),
            });
          }
        })
        .catch(err => {
          req.err.show(err);
        });
    });
  }

  onAuthorityChange(value, code) {
    const that = this;
    console.log('value', value, code);
    const { companyAuthority, projectAuthority } = this.state;
    that.setState({
      [`companyAuthority.${code}`]: !companyAuthority[code],
      [`projectAuthority.${code}`]: !projectAuthority[code],
    });
    that.setState({
      companyAuthority: {
        ...companyAuthority,
        [`${code}`]: !companyAuthority[code],
      },
      projectAuthority: {
        ...projectAuthority,
        [`${code}`]: !projectAuthority[code],
      },
    });
  }

  getPositionList(type) {
    const that = this;
    // const { getCompanyPositionList, getProjectPositionList } = this.state;
    req.company
      .getPositionList({
        positionType: type,
      })
      .then(res => {
        if (res.code !== 0) {
          console.error(res.msg);
        }
        if (type === '00') {
          that.setState({
            getCompanyPositionList: res.data.list,
          });
        } else {
          that.setState({
            getProjectPositionList: res.data.list,
          });
        }
      })
      .catch(req.err.show);
  }

  render() {
    const { visible, positionType, form, onCancel } = this.props;
    const { getFieldDecorator } = form;
    const { companyAuthority, projectAuthority } = this.state;
    return (
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
        }}
        title="添加职位"
        okButtonProps={{
          onClick: this.onSubmit,
        }}
        destroyOnClose
        onOk={this.onSubmit}
        okText="确认"
        cancelText="取消"
      >
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          // onSubmit={this.handleSubmit}
          // onFinish={this.onFinishSubmine}
        >
          <Form.Item
            label="名称"
            name="positionName"
            // rules={[{ required: true, message: '请输入姓名' }]}
          >
            {getFieldDecorator('positionName', {
              rules: [
                {
                  required: true,
                  message: '请输入职位名称,16字以内',
                  max: 15,
                },
              ],
            })(<Input autoComplete="false" placeholder="如‘项目经理’" />)}
          </Form.Item>
          {!positionType ? (
            <>
              <Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '120%',
                  }}
                >
                  <p
                    style={{
                      display: 'block',
                      width: '80px',
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.65)',
                    }}
                  >
                    权限模板:
                  </p>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.45)',
                      fontSize: '13px',
                      width: '400px',
                    }}
                  >
                    选填，设置后，添加成员时，选择职位后即可快速完成权限配置
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="VIEW_DASHBOARD" valuepropname="checked">
                <div
                  style={{
                    width: '110%',
                    height: '60px',
                    marginRight: 0,
                    marginTop: '-25px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      borderBottom: '1px solid #e8e8e8',
                      marginLeft: '80px',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: '60px',
                        lineHeight: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                        }}
                      >
                        查看公司数据星盘
                      </p>
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                          color: '#666',
                          fontSize: '12px',
                        }}
                      >
                        拥有查看公司项目汇总数据权限
                      </p>
                    </div>
                    <div
                      style={{
                        width: '80px',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getFieldDecorator('VIEW_DASHBOARD', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={
                            companyAuthority[authority.company.VIEW_DASHBOARD]
                          }
                          onChange={value => {
                            this.onAuthorityChange(
                              value,
                              authority.company.VIEW_DASHBOARD
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="EDIT_MEMBER" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '40px',
                    marginRight: 0,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: '60px',
                        lineHeight: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                        }}
                      >
                        公司成员管理
                      </p>
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                          color: '#666',
                          fontSize: '12px',
                        }}
                      >
                        公司成员添加、删除，权限分配
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '80px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {getFieldDecorator('EDIT_MEMBER', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={
                          companyAuthority[authority.company.EDIT_MEMBER]
                        }
                        onChange={value => {
                          this.onAuthorityChange(
                            value,
                            authority.company.EDIT_MEMBER
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="CREATE_PROJECT" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '40px',
                    marginRight: 0,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                        width: '100%',
                      }}
                    >
                      创建项目
                    </p>
                  </div>
                  <div
                    style={{
                      width: '80px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {getFieldDecorator('CREATE_PROJECT', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={
                          companyAuthority[authority.company.CREATE_PROJECT]
                        }
                        onChange={value => {
                          this.onAuthorityChange(
                            value,
                            authority.company.CREATE_PROJECT
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="VIEW_WORKER_TALENT_POOL" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '40px',
                    marginRight: 0,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                        width: '100%',
                      }}
                    >
                      查看工人库
                    </p>
                  </div>
                  <div
                    style={{
                      width: '80px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {getFieldDecorator('VIEW_WORKER_TALENT_POOL', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={
                          companyAuthority[
                            authority.company.VIEW_WORKER_TALENT_POOL
                          ]
                        }
                        onChange={value => {
                          this.onAuthorityChange(
                            value,
                            authority.company.VIEW_WORKER_TALENT_POOL
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="VIEW_LEADER_TALENT_POOL" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '40px',
                    marginRight: 0,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                        width: '100%',
                      }}
                    >
                      查看班组库
                    </p>
                  </div>
                  <div
                    style={{
                      width: '80px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {getFieldDecorator('VIEW_LEADER_TALENT_POOL', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={
                          companyAuthority[
                            authority.company.VIEW_LEADER_TALENT_POOL
                          ]
                        }
                        onChange={value => {
                          this.onAuthorityChange(
                            value,
                            authority.company.VIEW_LEADER_TALENT_POOL
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '120%',
                  }}
                >
                  <p
                    style={{
                      display: 'block',
                      width: '80px',
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.65)',
                    }}
                  >
                    权限模板:
                  </p>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.45)',
                      fontSize: '13px',
                      width: '400px',
                    }}
                  >
                    选填，当给管理员设置职位时，快速匹配相应权限
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="VIEW_DASHBOARD" valuepropname="checked">
                <div
                  style={{
                    width: '110%',
                    height: '60px',
                    marginRight: 0,
                    marginTop: '-25px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      marginLeft: '80px',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: '60px',
                        lineHeight: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                        }}
                      >
                        查看项目数据星盘
                      </p>
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                          color: 'rgba(33, 39, 50, 0.45)',
                          fontSize: '12px',
                        }}
                      >
                        拥有查看项目汇总数据的权限
                      </p>
                    </div>
                    <div
                      style={{
                        width: '80px',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getFieldDecorator('VIEW_DASHBOARD', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={
                            projectAuthority[authority.project.VIEW_DASHBOARD]
                          }
                          disabled
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="VIEW_BALANCE" valuepropname="checked">
                <div
                  style={{
                    width: '110%',
                    height: '60px',
                    marginRight: 0,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      borderBottom: '1px solid #e8e8e8',
                      marginLeft: '80px',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: '60px',
                        lineHeight: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                        }}
                      >
                        查看项目工钱数据
                      </p>
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                          color: 'rgba(33, 39, 50, 0.45)',
                          fontSize: '12px',
                        }}
                      >
                        项目数据星盘中展示工钱数据模块
                      </p>
                    </div>
                    <div
                      style={{
                        width: '80px',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getFieldDecorator('VIEW_BALANCE', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={
                            projectAuthority[authority.project.VIEW_BALANCE]
                          }
                          onChange={value => {
                            this.onAuthorityChange(
                              value,
                              authority.project.VIEW_BALANCE
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    width: '110%',
                    height: '40px',
                    marginRight: 0,
                    // marginTop: '-20px',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          marginRight: '10px',
                        }}
                        src={manage}
                        alt="logo"
                      />
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                        }}
                      >
                        人员管理
                      </p>
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="EDIT_ADMIN" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                    height: '40px',
                    marginLeft: '40px',
                    marginTop: '-20px',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '60px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                        }}
                      >
                        项目成员管理
                      </p>
                      <p
                        style={{
                          height: '20px',
                          lineHeight: '20px',
                          margin: 0,
                          color: 'rgba(33, 39, 50, 0.45)',
                          fontSize: '12px',
                        }}
                      >
                        项目成员添加、删除，权限分配
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '60px',
                      height: '40px',
                    }}
                  >
                    {getFieldDecorator('EDIT_ADMIN', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={projectAuthority[authority.project.EDIT_ADMIN]}
                        onChange={value => {
                          this.onAuthorityChange(
                            value,
                            authority.project.EDIT_ADMIN
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="EDIT_TEAM" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                    height: '40px',
                    marginLeft: '40px',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                      }}
                    >
                      班组管理
                    </p>
                  </div>
                  <div
                    style={{
                      width: '60px',
                      height: '40px',
                    }}
                  >
                    {getFieldDecorator('EDIT_TEAM', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={projectAuthority[authority.project.EDIT_TEAM]}
                        onChange={value => {
                          this.onAuthorityChange(
                            value,
                            authority.project.EDIT_TEAM
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="ADD_OR_REMOVE_WORKER" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                    height: '40px',
                    marginLeft: '40px',
                    // marginTop: '20px',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        height: '30px',
                        lineHeight: '30px',
                        margin: 0,
                      }}
                    >
                      工人管理
                    </p>
                  </div>
                  <div
                    style={{
                      width: '60px',
                      height: '60px',
                    }}
                  >
                    {getFieldDecorator('ADD_OR_REMOVE_WORKER', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={
                          projectAuthority[
                            authority.project.ADD_OR_REMOVE_WORKER
                          ]
                        }
                        onChange={value => {
                          this.onAuthorityChange(
                            value,
                            authority.project.ADD_OR_REMOVE_WORKER
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="MANAGE_ATTEND_RECORD" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '60px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      marginLeft: '80px',
                      borderBottom: '1px solid #e8e8e8',
                      borderTop: '1px solid #e8e8e8',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: '60px',
                        lineHeight: '60px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          marginRight: '10px',
                        }}
                        src={manager}
                        alt="logo"
                      />
                      <div>
                        <p
                          style={{
                            height: '30px',
                            lineHeight: '30px',
                            margin: 0,
                            width: '100%',
                          }}
                        >
                          考勤管理
                        </p>
                        <p
                          style={{
                            height: '20px',
                            lineHeight: '20px',
                            margin: 0,
                            width: '110%',
                            color: 'rgba(33, 39, 50, 0.45)',
                            fontSize: '12px',
                          }}
                        >
                          修改工人异常考勤记录，审批补卡、误打卡申请
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '80px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getFieldDecorator('MANAGE_ATTEND_RECORD', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={
                            projectAuthority[
                              authority.project.MANAGE_ATTEND_RECORD
                            ]
                          }
                          onChange={value => {
                            this.onAuthorityChange(
                              value,
                              authority.project.MANAGE_ATTEND_RECORD
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="MANAGE_CONSTRUCTION_LOG" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '60px',
                  }}
                >
                  <div
                    data-flex-box="1"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      marginLeft: '80px',
                      borderBottom: '1px solid #e8e8e8',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: '60px',
                        lineHeight: '60px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          marginRight: '10px',
                        }}
                        src={daily}
                        alt="logo"
                      />
                      <div>
                        <p
                          style={{
                            height: '30px',
                            lineHeight: '30px',
                            margin: 0,
                            width: '100%',
                          }}
                        >
                          施工日志管理
                        </p>
                        <p
                          style={{
                            height: '20px',
                            lineHeight: '20px',
                            margin: 0,
                            width: '110%',
                            color: 'rgba(33, 39, 50, 0.45)',
                            fontSize: '12px',
                          }}
                        >
                          施工日志后台管理、导出权限
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '80px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getFieldDecorator('MANAGE_CONSTRUCTION_LOG', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={
                            projectAuthority[
                              authority.project.MANAGE_CONSTRUCTION_LOG
                            ]
                          }
                          onChange={value => {
                            this.onAuthorityChange(
                              value,
                              authority.project.MANAGE_CONSTRUCTION_LOG
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form.Item>
              {/* <Form.Item name="MANAGE_CONSTRUCTION_LOG" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '60px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      marginLeft: '80px',
                      borderBottom: '1px solid #e8e8e8',
                      borderTop: '1px solid #e8e8e8',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: '60px',
                        lineHeight: '60px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          marginRight: '10px',
                        }}
                        src={daily}
                        alt="logo"
                      />
                      <div>
                        <p
                          style={{
                            height: '30px',
                            lineHeight: '30px',
                            margin: 0,
                            width: '100%',
                          }}
                        >
                          施工日志管理
                        </p>
                        <p
                          style={{
                            height: '20px',
                            lineHeight: '20px',
                            margin: 0,
                            width: '110%',
                            color: 'rgba(33, 39, 50, 0.45)',
                            fontSize: '12px',
                          }}
                        >
                          施工日志后台管理、导出权限
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '80px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getFieldDecorator('MANAGE_ATTEND_RECORD', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Switch
                          checkedChildren={<Icon type="check" />}
                          unCheckedChildren={<Icon type="close" />}
                          checked={
                            projectAuthority[
                              authority.project.MANAGE_CONSTRUCTION_LOG
                            ]
                          }
                          onChange={value => {
                            this.onAuthorityChange(
                              value,
                              authority.project.MANAGE_CONSTRUCTION_LOG
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form.Item> */}
              <Form.Item name="MANAGE_PARTICIPANT" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '40px',
                    marginRight: 0,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid #e8e8e8',
                      }}
                    >
                      <img
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          marginRight: '10px',
                        }}
                        src={units}
                        alt="logo"
                      />
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                        }}
                      >
                        参建单位管理
                      </p>
                      <div
                        style={{
                          width: '110px',
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {getFieldDecorator('MANAGE_PARTICIPANT', {
                          rules: [{ required: false, message: '' }],
                        })(
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={
                              projectAuthority[
                                authority.project.MANAGE_PARTICIPANT
                              ]
                            }
                            onChange={value => {
                              this.onAuthorityChange(
                                value,
                                authority.project.MANAGE_PARTICIPANT
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="MANAGE_WORKER_SALARY" valuepropname="checked">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '110%',
                    height: '40px',
                    marginRight: 0,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: '40px',
                      lineHeight: '40px',
                      marginLeft: '80px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid #e8e8e8',
                      }}
                    >
                      <img
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          marginRight: '10px',
                        }}
                        src={money}
                        alt="logo"
                      />
                      <p
                        style={{
                          height: '30px',
                          lineHeight: '30px',
                          margin: 0,
                          width: '100%',
                        }}
                      >
                        工人工钱管理
                      </p>
                      <div
                        style={{
                          width: '110px',
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {getFieldDecorator('MANAGE_WORKER_SALARY', {
                          rules: [{ required: false, message: '' }],
                        })(
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={
                              projectAuthority[
                                authority.project.MANAGE_WORKER_SALARY
                              ]
                            }
                            onChange={value => {
                              this.onAuthorityChange(
                                value,
                                authority.project.MANAGE_WORKER_SALARY
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    );
  }
}

positionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  positionType: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default Form.create({ name: 'position_modal' })(positionModal);
