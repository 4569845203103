const healthCodeMap = {
  unknown: '00', // 未知
  green: '01', // 绿码
  yellow: '02', // 黄码
  red: '03', // 红码
};

const healthCodeLabel = {};
// healthCodeLabel[healthCodeMap.unknown] = '未知';
healthCodeLabel[healthCodeMap.green] = '绿码';
healthCodeLabel[healthCodeMap.yellow] = '黄码';
healthCodeLabel[healthCodeMap.red] = '红码';

const healthCodeLabelArr = [
  {
    value: '绿码',
    code: '01',
  },
  {
    value: '黄码',
    code: '02',
  },
  {
    value: '红码',
    code: '03',
  },
];

const latestNatResultMap = {
  unknown: '00', // 未知
  safe: '01', // 阴性
  danger: '02', // 阳性
};

const latestNatResultLabel = {};
// healthCodeLabel[healthCodeMap.unknown] = '未知';
latestNatResultLabel[latestNatResultMap.safe] = '阴性';
latestNatResultLabel[latestNatResultMap.danger] = '阳性';

const latestNatResultArr = [
  {
    value: '阴性',
    code: '01',
  },
  {
    value: '阳性',
    code: '02',
  },
];

const epidemicRiskAreaMap = {
  green: '10300001', // 绿色
  yellow: '10300002', // 黄码
  orange: '10300003', // 橙码
  red: '10300004', // 红码
};

export default {
  healthCodeMap,
  healthCodeLabel,
  healthCodeLabelArr,
  latestNatResultMap,
  latestNatResultLabel,
  latestNatResultArr,
  epidemicRiskAreaMap,
};
