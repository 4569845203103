import req from './req';
import api from './api/index';
import err from './fn/err';
import cachify from './fn/cachify';
import authority from './fn/authority';
import participantType from './fn/participant_type';

req.use(api);
req.use(err);
req.use(cachify);
req.use(authority);
req.use(participantType);

export default req;
