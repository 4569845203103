const company = {
  manager: [
    {
      code: 1,
      label: '班组管理',
    },
    {
      code: 2,
      label: '项目考勤管理',
    },
  ],
};

const team = {
  leader: [
    {
      code: 1,
      label: '班组工人管理',
    },
    {
      code: 2,
      label: '查看班组打卡记录',
    },
    {
      code: 3,
      label: '查看班组工钱',
    },
  ],
  manager: [
    {
      code: 1,
      label: '班组工人管理',
    },
    {
      code: 2,
      label: '查看工人打卡记录',
    },
  ],
};

export default {
  company,
  team,
};
