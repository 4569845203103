const api = {
  getQrcode: {
    url: '/bi-api/api/sys/getMiniQrCode',
  },
  getQrcodeStatus: {
    url: '/bi-api/api/sys/checkLogin',
  },
  logout: {
    url: '/bi-api/api/sys/loginOut',
  },
  getTeamSalaryTypeList: {
    url: '/bi-api/api/project2/listSalaryTypes',
  },
  sendSMS: {
    url: '/bi-api/api/sys/mobile/validateCode',
    method: 'POST',
  },
  // 获取消息列表
  getMessageList: {
    url: '/bi-api/api/sys/getMessageList',
  },
  // 获取消息详情
  getMessageDetails: {
    url: '/bi-api/api/sys/getMessageDetailByMessageId',
  },
  // 超管审批加入、添加公司
  agreeOrRejectRight: {
    url: '/bi-api/api/sys/approveJoinCompany',
    method: 'POST',
  },
  getManagerInfo: {
    url: '/bi-api/api/worker/getUserByNumber/:number',
  },
  getWorkerInfo: {
    url: '/bi-api/api/worker/getBaseWorkerDetails',
  },
  checkExportReport: {
    url: '/bi-api/api/sys/checkFileExist/:fileKey',
  },
  approveMyJoinApply: {
    url: '/bi-api/api/sys/approveJoinCompanyApply',
    method: 'POST',
  },
  // 获取公司权限
  getCompanyAuthorityList: {
    url: '/bi-api/api/company/listCompanyPermissionDicts',
  },
  // 获取系统字典
  getDicts: {
    url: '/bi-api/api/sys/dict/fetch/:key',
  },
  saveWorkerBaseByCardReader: {
    url: '/bi-api/api/worker/saveWorkerBaseByCardReader',
    method: 'POST',
  },
  // 登录 - 获取手机号登录验证码
  getLoginValidateCode: {
    url: '/bi-api/api/sys/getLoginValidateCode',
  },
  // 手机号+验证码登录
  loginMobile: {
    url: '/bi-api/api/sys/loginByMobileNumber',
    method: 'POST',
  },
  // 获取操作日志列表查询
  searchOperationLogs: {
    url: '/bi-api/api/operation/logs/searchOperationLogs',
  },
  // 4.16 获取施工日志、质量管理、安全管理等模块情况
  getModuleBetaInfo: {
    url: '/bi-api/api/sys/getModuleBetaInfo',
  },
};

export default api;
