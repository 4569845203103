import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Switch,
  Radio,
  Button,
  Modal,
  Alert,
  Table,
  Input,
  message,
} from 'antd';
import req from '../../req/index';
import store from '../../store/index';

const StyledDiv = styled.div`
  .title {
    height: 60px;
    line-height: 60px;
    font-weight: 500;
    font-size: 20px;
    color: #000000d9;
    padding-left: 30px;
    background-color: #fff;
  }
  .content {
    margin: 24px;
    padding: 24px;
    background-color: #fff;
    .company_name {
      display: flex;
      line-height: 24px;
      .t {
        font-size: 14px;
        color: #00000073;
      }
      .v {
        font-size: 14px;
        color: #000000a6;
      }
    }
    .login_limit {
      display: flex;
      align-items: center;
      margin-top: 24px;
      .t {
        font-weight: 500;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        margin-right: 6px;
      }
    }
    .tips {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 8px;
    }
    .limit_wrap {
      display: flex;
      margin-top: 20px;
      .limit_title {
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        font-size: 14px;
        width: 96px;
        line-height: 30px;
      }
      .limit_switch {
        .limit_tips {
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 8px;
        }
      }
      .ip_list {
        .btn {
          margin-bottom: 10px;
          margin-right: 8px;
        }
      }
    }
  }
`;

const ListTips = styled.div`
  display: flex;
  margin-top: 16px;
  .tips_t {
    margin-left: 8px;
    line-height: 24px;
  }
`;

const reg = /^(?:(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])$/;
console.log(reg);
function validateMessages(val, ipList) {
  if (val && ipList.includes(val)) {
    return `IP地址冲突 ${val}`;
  }
  if (!val) {
    return 'IP地址不能为空';
  }
  if (!reg.test(val)) {
    return 'IP地址格式错误，请重新输入';
  }
  return false;
}
const contrastIpMap = {};

export default function LoginSecuritySetting() {
  // eslint-disable-next-line
  const [open, setOpen] = useState(true);
  // eslint-disable-next-line
  const [limit, setLimit] = useState('0');
  const [IPType, setIPType] = useState('0');
  // eslint-disable-next-line
  const [IPList, setIPList] = useState([]);
  // eslint-disable-next-line
  const [visible, setVisible] = useState(false);
  // const form = Form.useForm();
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [editType, setEditType] = useState(null);

  const columns = [
    {
      title: '序号',
      dataIndex: 'num',
      key: 'num',
      width: 60,
    },
    {
      title: 'IP地址',
      dataIndex: 'ip',
      key: 'ip',
      render(val, record) {
        return (
          <>
            <Input
              defaultValue={val}
              placeholder="请输入，如118.24.224.175"
              onBlur={e => {
                const msg = validateMessages(
                  e.currentTarget.value,
                  Object.values(contrastIpMap)
                );
                console.log(contrastIpMap, 'contrastIpMap');
                if (
                  msg &&
                  contrastIpMap[record.key] !== e.currentTarget.value
                ) {
                  e.currentTarget.style.borderColor = '#ff4d4f';
                  e.currentTarget.nextElementSibling.innerHTML = msg;
                  e.currentTarget.nextElementSibling.style.marginTop = '2px';
                } else {
                  e.currentTarget.style.borderColor = '#d9d9d9';
                  e.currentTarget.nextElementSibling.innerHTML = '';
                  e.currentTarget.nextElementSibling.style.marginTop = '0px';
                  contrastIpMap[record.key] = e.currentTarget.value;
                }
                // eslint-disable-next-line
                record.ip = e.currentTarget.value;
                // eslint-disable-next-line
                record.flag = !!msg && !contrastIpMap[record.key];
                console.log(e.currentTarget, msg);
              }}
            />
            <div
              className="error_info"
              style={{
                color: '#ff4d4f',
                lineHeight: '17px',
              }}
            />
          </>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'edit',
      key: 'edit',
      width: 60,
      render(value, record) {
        console.log(record);
        return (
          <Button
            type="link"
            size="small"
            onClick={() => {
              delete contrastIpMap[record.key];
              setData(
                data
                  .filter(item => item.key !== record.key)
                  .map((item, index) => ({ ...item, num: index + 1 }))
              );
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];
  const getCompanyDengBaoSettingInfo = useCallback(async () => {
    const result = await req.security.getCompanyDengBaoSettingInfo(
      JSON.stringify({
        companyId: companyData.id,
      })
    );
    if (result.code !== 0) {
      req.err.show(result.msg);
      return;
    }
    const { switchStatus, ipList, ipType, limitType } = result.data;
    setOpen(switchStatus === '01');
    setIPList(ipList || []);
    setData(
      (ipList || []).map((item, index) => ({
        num: index + 1,
        ip: item,
        key: Date.now() + index,
      }))
    );
    if (ipType !== null) {
      setIPType(ipType || '0');
    }
    setLimit(limitType || '0');
    console.log(result, '限制信息', ipList);
  }, [companyData, editType]);
  const editCompanyDengBaoSettingInfo = async query => {
    console.log(open, '编辑', companyData);
    const result = await req.security.editCompanyDengBaoSettingInfo(
      JSON.stringify({
        companyId: companyData.id,
        ...query,
      })
    );
    if (result.code !== 0) {
      req.err.show(result.msg);
    }
    if (query && query.ipList) {
      message.success(editType === 'add' ? '添加成功' : '编辑成功');
    }
    getCompanyDengBaoSettingInfo();
    console.log('编辑', result, open);
  };

  const onChange = useCallback(
    e => {
      setOpen(e);
      editCompanyDengBaoSettingInfo({ switchStatus: e ? '01' : '00' });
    },
    [companyData.id]
  );
  const handleLimitChange = useCallback(
    e => {
      setLimit(e.target.value);
      editCompanyDengBaoSettingInfo({ limitType: e.target.value });
    },
    [companyData.id]
  );
  const handleIPTypeChange = useCallback(
    e => {
      setIPType(e.target.value);
      editCompanyDengBaoSettingInfo({ ipType: e.target.value });
    },
    [companyData.id]
  );
  const handleOk = useCallback(() => {
    console.log(data);
    if (!data.length) {
      message.error('请至少添加一条');
      return;
    }
    if (data.length !== 0 && data.filter(item => item.flag).length) {
      return;
    }
    editCompanyDengBaoSettingInfo({
      ipList: data.map(item => item.ip),
      ipType: IPType,
    }).finally(() => {
      setVisible(false);
    });
    console.log(data);
  }, [data, companyData.id, IPType]);
  const handleCancel = useCallback(() => {
    setVisible(false);
    getCompanyDengBaoSettingInfo();
  }, [companyData, editType]);

  useEffect(() => {
    // editCompanyDengBaoSettingInfo();
  }, [open, limit, IPType, data]);
  useEffect(() => {
    console.log(store, 'store');
    if (companyData.id) {
      getCompanyDengBaoSettingInfo();
    }
  }, [companyData.id]);
  useEffect(() => {
    req.company
      .getMyCrt()
      .then(r1 => {
        if (r1.code !== 0) {
          req.err.show(r1);
          return;
        }
        setCompanyData(r1.data);
      })
      .catch(err => {
        req.err.show(err);
      });
  }, []);
  return (
    <StyledDiv>
      <div className="title">登录安全设置</div>
      <div className="content">
        <div className="company_name">
          <div className="t">当前公司：</div>
          <div className="v">{companyData.name}</div>
        </div>
        <div className="login_limit">
          <div className="t">登录限制：</div>
          <Switch checked={open} onChange={onChange} />
        </div>
        <div className="tips">
          你可以为子账号（管理员登录）开启异常登录（异地登录，30天未登录）或IP限制登录（指定IP允许登录或不允许登录）约束子账号（管理员登录在安全环境下登录墨计星盘PC端）
        </div>
        {open ? (
          <>
            <div className="limit_wrap">
              <div className="limit_title">限制方式</div>
              <div className="limit_switch">
                <Radio.Group onChange={handleLimitChange} value={limit}>
                  <Radio.Button value="0">异常登录限制</Radio.Button>
                  <Radio.Button value="1">IP限制登录</Radio.Button>
                </Radio.Group>
                <div className="limit_tips">
                  {limit === '0'
                    ? '系统判断用户进行异常登录（如异地登录、30天未登录等），将要求用户进行二次身份验证，有效保障账号资产安全'
                    : '启用后子账户仅在特定情况下允许登录'}
                </div>
              </div>
            </div>
            {limit === '1' ? (
              <>
                <div className="limit_wrap">
                  <div className="limit_title">IP类型</div>
                  <div className="limit_switch">
                    <Radio.Group onChange={handleIPTypeChange} value={IPType}>
                      <Radio.Button value="0">白名单</Radio.Button>
                      <Radio.Button value="1">黑名单</Radio.Button>
                    </Radio.Group>
                    <div className="limit_tips">
                      {IPType === '0'
                        ? '设置白名单限制后，允许子账号（管理员登录）在白名单限制IP内登录控制台'
                        : '设置黑名单限制后，允许子账号（管理员登录）在黑名单限制IP内登录控制台'}
                    </div>
                  </div>
                </div>
                <div className="limit_wrap">
                  <div className="limit_title">配置IP</div>
                  <div className="ip_list">
                    {IPList.map((item, index) => (
                      <Button disabled key={index} className="btn">
                        {item}
                      </Button>
                    ))}
                    {!IPList.length ? (
                      <Button
                        type="primary"
                        ghost
                        onClick={() => {
                          setData([{ num: 1, ip: '', key: Date.now() }]);
                          setEditType('add');
                          setVisible(true);
                        }}
                      >
                        去添加
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        ghost
                        onClick={() => {
                          setEditType('edit');
                          setVisible(true);
                        }}
                      >
                        管理IP地址
                      </Button>
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : null}
        <Modal
          title="限制IP"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Alert
            message={`设置后用户${
              IPType === '1' ? '不' : ''
            }可在以下IP地址环境中登录`}
            type="info"
            showIcon
          />
          <Table
            size="small"
            dataSource={data}
            locale={{ emptyText: '请添加IP地址' }}
            columns={columns}
            pagination={false}
            scroll={{ y: 240 }}
          />
          <ListTips>
            <Button
              size="small"
              disabled={data.length === 10}
              onClick={() => {
                setData([
                  ...data,
                  { num: data.length + 1, ip: '', key: Date.now() },
                ]);
              }}
            >
              添加一条
            </Button>
            <div className="tips_t">还可添加{10 - data.length}条</div>
          </ListTips>
        </Modal>
      </div>
    </StyledDiv>
  );
}
