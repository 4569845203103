import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MailOutlined, MobileOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col } from 'antd';

import req from '../../../req';

const maxGetCodeDelay = 60;

const StyledFormModal = styled.div`
  .main_box {
    width: 508px;
    height: 298px;
    border-radius: 4px;
    background-color: #fff;
    padding: 40px 70px 0;
    box-sizing: border-box;
  }
`;

class FormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getCodeDelay: 0,
      submitLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendCode = this.sendCode.bind(this);
    this.countTimer = 0;
  }

  /**
   * 表单提交
   */
  handleSubmit(e) {
    e.preventDefault();
    const { form, user } = this.props;
    const that = this;
    form.validateFields((error, values) => {
      if (!error) {
        that.setState({
          submitLoading: true,
        });
        req.user
          .bindPhone(
            {
              userId: user.id,
            },
            JSON.stringify(values)
          )
          .then(r1 => {
            that.setState({
              submitLoading: false,
            });
            if (r1.code !== 0) {
              req.err.show(r1);
              return;
            }
            that.props.onSucc();
          })
          .catch(err => {
            that.setState({
              submitLoading: false,
            });
            req.err.show(err);
          });
      }
    });
  }

  /**
   * 发送验证码
   */
  sendCode() {
    const that = this;
    const { form } = this.props;
    form.validateFields(['mobileNumber'], {}, r1 => {
      if (r1) {
        return;
      }
      const mobileNumber = form.getFieldValue('mobileNumber');
      req.sys
        .sendSMS(
          JSON.stringify({
            mobileNumber,
          })
        )
        .then(r2 => {
          if (r2.code !== 0) {
            req.err.show(r2);
            return;
          }
          that.setState(
            {
              getCodeDelay: maxGetCodeDelay,
            },
            () => {
              that.startCodeDelayCount();
            }
          );
        })
        .catch(req.err.show);
    });
  }

  /**
   * 倒计时
   */
  startCodeDelayCount() {
    const { getCodeDelay } = this.state;
    clearTimeout(this.countTimer);
    this.countTimer = setTimeout(() => {
      this.setState({
        getCodeDelay: getCodeDelay - 1,
      });
      if (getCodeDelay > 0) {
        this.startCodeDelayCount();
      }
    }, 1000);
  }

  render() {
    const { getCodeDelay, submitLoading } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <StyledFormModal>
        <div className="main_box">
          <div
            style={{
              color: 'rgba(0, 0, 0, 0.65)',
              fontSize: 14,
              marginBottom: 16,
            }}
          >
            绑定手机号后立即体验
          </div>
          <Form onSubmit={this.handleSubmit} className="form_modal">
            <Form.Item>
              {getFieldDecorator('mobileNumber', {
                rules: [
                  { required: true, message: '请填写手机号' },
                  { pattern: /^\d{11}$/, message: '手机号格式不正确' },
                ],
              })(
                <Input
                  prefix={
                    <MobileOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="手机号"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Row gutter={14}>
                <Col span={16}>
                  {getFieldDecorator('validateCode', {
                    rules: [{ required: true, message: '请输入验证码' }],
                  })(
                    <Input
                      prefix={
                        <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                      }
                      placeholder="验证码"
                      size="large"
                    />
                  )}
                </Col>
                <Col span={8}>
                  {getCodeDelay <= 0 ? (
                    <Button
                      size="large"
                      style={{ width: 120 }}
                      onClick={this.sendCode}
                    >
                      获取验证码
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      style={{ width: 120 }}
                      disabled
                    >{`${getCodeDelay}s`}</Button>
                  )}
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                loading={submitLoading}
              >
                立即体验
              </Button>
            </Form.Item>
          </Form>
        </div>
      </StyledFormModal>
    );
  }
}

FormModal.propTypes = {
  form: PropTypes.object,
  user: PropTypes.object,
};

FormModal.defaultProps = {
  form: null,
  user: null,
};

export default Form.create({ name: 'form_modal' })(FormModal);
