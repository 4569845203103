const api = {
  getCompanyDengBaoSettingInfo: {
    url: '/bi-api/api/company/threeLevelDengBao/getCompanyDengBaoSettingInfo',
    method: 'post',
  },
  editCompanyDengBaoSettingInfo: {
    url: '/bi-api/api/company/threeLevelDengBao/editCompanyDengBaoSettingInfo',
    method: 'POST',
  },
};

export default api;
