import React from 'react';
import PropTypes from 'prop-types';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu, Badge } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import navMap from '../../../config/nav_map';
import req from '../../../req';
// import config from '../../../config';

function mapStateToProps(state) {
  return {
    blacklistCount: state.blacklistCount,
  };
}

const navObj = {};

Object.keys(navMap).forEach(key => {
  const { path, label } = navMap[key];
  navObj[key] = {
    key: path,
    label,
  };
});

navObj.companyData.icon = 'bank';
navObj.registerCompany.icon = 'user';
navObj.dashboard.icon = 'dashboard';
navObj.companyOverview.icon = 'fund';
navObj.projectOverview.icon = 'unordered-list';
navObj.dataExport.icon = 'table';
navObj.dataExport.children = [
  navObj.dataExportAttend,
  navObj.dataExportSalary,
  navObj.dataExportRoster,
  navObj.dataExportOrg,
];
navObj.blacklist.icon = 'stop';
navObj.organization.icon = 'apartment';
navObj.logAduit.icon = 'solution';
navObj.learningResource.icon = 'read';
navObj.loginSecuritySetting.icon = 'safety-certificate';
navObj.workerComplaints.icon = 'exclamation-circle';

const initMenu = (cmpInfo, showFlag, superAdmin, currentCompanyManagerFlag) => {
  console.log('cmpInfo', cmpInfo);
  const tree = [];
  if (!cmpInfo || !cmpInfo.id) {
    tree.push(navObj.registerCompany);
  } else {
    // if (authorityMap[config.authority.company.VIEW_DASHBOARD]) {
    //   tree.push(navObj.companyData);
    //   tree.push(navObj.companyOverview);
    // }
    if (showFlag) {
      tree.push(navObj.companyData);
      tree.push(navObj.companyOverview);
    }
    tree.push(navObj.projectOverview);
    tree.push(navObj.dashboard);
    if (currentCompanyManagerFlag) {
      tree.push(navObj.learningResource);
    }
    tree.push(navObj.dataExport);
    tree.push(navObj.blacklist);
    tree.push(navObj.workerComplaints);
    tree.push(navObj.organization);
    if (superAdmin) {
      tree.push(navObj.logAduit);
      tree.push(navObj.loginSecuritySetting);
    }
  }
  return tree;
};

const initMenuKeys = (tree, pathname) => {
  let openKey = '';
  let selectedKey = '';
  tree.find(item => {
    const { key, children } = item;
    if (children && children.length) {
      const match = children.find(it => it.key === pathname);
      if (match) {
        openKey = key;
        selectedKey = match.key;
      }
    } else {
      const match = key === pathname;
      if (match) {
        openKey = key;
        selectedKey = key;
      }
    }
    return !!selectedKey;
  });
  return {
    openKeys: openKey ? [openKey] : [],
    selectedKeys: selectedKey ? [selectedKey] : [],
  };
};

const createMenuItem = (info, collapsed, count) => {
  if (info.children && info.children.length) {
    const cList = info.children.map(c => (
      <Menu.Item key={c.key}>
        <Link to={c.key} style={{ display: 'block' }}>
          <span>{c.label}</span>
        </Link>
      </Menu.Item>
    ));
    return (
      <Menu.SubMenu
        key={info.key}
        title={
          <React.Fragment>
            <LegacyIcon type={info.icon} />
            <span>{info.label}</span>
          </React.Fragment>
        }
      >
        {cList}
      </Menu.SubMenu>
    );
  }
  const diffItem = () => {
    if (collapsed) {
      return count ? (
        <Badge dot offset={[0, 10]}>
          <LegacyIcon type={info.icon} />
        </Badge>
      ) : (
        <LegacyIcon type={info.icon} />
      );
    }
    return count ? (
      <>
        <LegacyIcon type={info.icon} />
        <span>{info.label}</span>
        <Badge count={count} overflowCount={99} style={{ marginLeft: 5 }} />
      </>
    ) : (
      <>
        <LegacyIcon type={info.icon} />
        <span>{info.label}</span>
      </>
    );
  };

  const option = {
    key: info.key,
  };
  if (info.label === '黑名单') {
    option.title = info.label;
  }
  return (
    <Menu.Item {...option}>
      <Link to={info.key} style={{ display: 'block' }}>
        {info.label === '黑名单' ? (
          diffItem()
        ) : (
          <>
            <LegacyIcon type={info.icon} />
            <span>{info.label}</span>
          </>
        )}
      </Link>
    </Menu.Item>
  );
};

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuTree: [],
      defaultOpenKeys: [],
      defaultSelectedKeys: [],
    };
    this.mounted = false;
    this.cmpInfo = null;
    this.showFlag = null; // (无公司角色仅是项目成员)
    this.superAdmin = null; // 是否是公司超管
    this.currentCompanyManagerFlag = null; // 是否是当前公司或当前顶部公司的公司管理员
  }

  componentDidMount() {
    const that = this;
    const { history } = this.props;
    this.unlisten = history.listen(() => {
      const { pathname } = history.location;
      if (
        !that.cmpInfo ||
        that.showFlag === null ||
        that.superAdmin === null ||
        this.currentCompanyManagerFlag === null
      ) {
        return;
      }
      const tree = initMenu(
        that.cmpInfo,
        that.showFlag,
        that.superAdmin,
        this.currentCompanyManagerFlag
      );
      console.log('pathname', pathname, tree);
      const keys = initMenuKeys(tree, pathname);
      that.setState({
        defaultSelectedKeys: keys.selectedKeys,
      });
    });
    this.mounted = true;
    // 是否展示公司数据总览以及数据看板(无公司角色仅是项目成员)
    Promise.all([
      this.getMyCrtCompany(),
      this.getMyInfo(),
      this.getAuthority(),
      this.isCurrentCompanyManager(),
      // req.company.showCompanyOrgDataSummaryOrNo(),
    ]).then(([r1, r2, r3, r4]) => {
      if (
        !r1 ||
        !r2 ||
        !r3 ||
        !r4 ||
        r1.code !== 0 ||
        r2.code !== 0 ||
        r3.code !== 0 ||
        r4.code !== 0
      ) {
        return;
      }
      that.cmpInfo = r1.data;
      that.showFlag = r2.data.flag;
      that.superAdmin = r3.data.superAdmin;
      that.currentCompanyManagerFlag = r4.data.flag;
      const tree = initMenu(
        r1.data,
        r2.data.flag,
        r3.data.superAdmin,
        r4.data.flag
      );
      const keys = initMenuKeys(tree, history.location.pathname);
      that.setState({
        menuTree: tree,
        defaultOpenKeys: keys.openKeys,
        defaultSelectedKeys: keys.selectedKeys,
      });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unlisten();
  }

  /**
   * 获取当前公司
   */
  getMyCrtCompany() {
    const that = this;
    return req.company
      .getMyCrt()
      .then(res => {
        if (!that.mounted) {
          return null;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return null;
        }
        return res;
      })
      .catch(req.err.show);
  }

  /**
   * 获取当前个人信息
   */
  getMyInfo() {
    const that = this;
    return req
      .cachify('user.getMyInfo')()
      .then(res => {
        if (!that.mounted) {
          return null;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return null;
        }
        const cid = res.data.currentTopCompany
          ? res.data.currentTopCompany.id
          : null;
        if (!cid) {
          return null;
        }
        return that.showCompanyOrgDataSummaryOrNo(cid);
      })
      .catch(req.err.show);
  }

  /**
   * 获取权限
   */
  getAuthority() {
    const that = this;
    return req.authority
      .getMap()
      .then(res => {
        if (!that.mounted) {
          return null;
        }
        if (res.code !== 0) {
          return null;
        }
        return res;
      })
      .catch(req.err.show);
  }

  /**
   * 是否是当前公司或当前顶部公司的公司管理员
   */
  isCurrentCompanyManager() {
    const that = this;
    return req.company
      .isCurrentCompanyManager()
      .then(res => {
        if (!that.mounted) {
          return null;
        }
        if (res.code !== 0) {
          return null;
        }
        return res;
      })
      .catch(req.err.show);
  }

  /**
   * 是否展示公司数据总览以及数据看板(无公司角色仅是项目成员)
   */
  showCompanyOrgDataSummaryOrNo(tid) {
    const that = this;
    return req.company
      .showCompanyOrgDataSummaryOrNo({
        companyId: tid,
      })
      .then(res => {
        if (!that.mounted) {
          return null;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return null;
        }
        return res;
      })
      .catch(req.err.show);
  }

  render() {
    const { menuTree, defaultOpenKeys, defaultSelectedKeys } = this.state;
    const { collapsed, blacklistCount } = this.props;
    if (!menuTree || !menuTree.length) {
      return null;
    }
    return (
      <Menu
        mode="inline"
        selectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        style={{
          marginTop: 15,
          borderRight: 0,
        }}
      >
        {menuTree.map(item => createMenuItem(item, collapsed, blacklistCount))}
      </Menu>
    );
  }
}

Nav.propTypes = {
  history: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
  blacklistCount: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(withRouter(Nav));
