import React from 'react';
import PropTypes from 'prop-types';
import req from '../../../req/index';
import config from '../../../config';

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.isUnmounted = false;
  }

  componentDidMount() {
    this.getMyInfo();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  /**
   * 获取当前个人信息
   */
  getMyInfo() {
    const that = this;
    const { history } = this.props;
    req
      .cachify('user.getMyInfo')()
      .then(res => {
        if (that.isUnmounted) {
          return;
        }
        // 登录过期
        if (res.code === req.err.CODE.sessionExpired) {
          history.replace('/login');
          return;
        }
        // 异常
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        const { companyDTOs, topCompanyRoles } = res.data;
        const code = config.companyRoleCode;
        const topRoleMap = {};
        if (topCompanyRoles && topCompanyRoles.length) {
          topCompanyRoles.forEach(r => {
            topRoleMap[r.roleCode] = true;
          });
        }
        // 没有公司
        if (!companyDTOs || !companyDTOs.length) {
          history.replace('/register_company');
          return;
        }
        // 公司级别权限
        if (topRoleMap[code.super] || topRoleMap[code.admin]) {
          history.replace('/company/data');
          return;
        }
        // 项目级别权限
        history.replace('/project/overview');
      })
      .catch(req.err.show);
  }

  render() {
    return null;
  }
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Root;
