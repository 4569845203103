import { fix2 } from './index';

/**
 * 日期转通用对象
 */
export function date2obj(date) {
  const Y = date.getFullYear();
  const M = date.getMonth();
  const D = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();
  const d = date.getDay();

  return {
    Y,
    M,
    D,
    h,
    m,
    s,
    d,
  };
}

/**
 * 日期转文本
 */
export function date2str(date, separator = '-') {
  const obj = date2obj(date);
  return [obj.Y, fix2(obj.M + 1), fix2(obj.D)].join(separator);
}

/**
 * 时间戳转日期
 */
export function timestamp2date(timestamp, separator = '-') {
  const obj = date2obj(new Date(timestamp));
  return [obj.Y, fix2(obj.M + 1), fix2(obj.D)].join(separator);
}

/**
 * 时间戳转时间
 */
export function timestamp2dateTime(
  timestamp,
  separator1 = '-',
  separator2 = ':'
) {
  const obj = date2obj(new Date(timestamp));
  return `${[obj.Y, fix2(obj.M + 1), fix2(obj.D)].join(separator1)} ${[
    fix2(obj.h),
    fix2(obj.m),
    fix2(obj.s),
  ].join(separator2)}`;
}
/**
 * 时间显示
 */
export function timeToText(timestamp) {
  const now = new Date().getTime();
  const obj = date2obj(new Date(timestamp));
  if (now - timestamp > 2 * 24 * 60 * 60 * 1000) {
    return timestamp2dateTime(timestamp);
  }
  if (now - timestamp > 24 * 60 * 60 * 1000) {
    return `昨天 ${fix2(obj.h)}:${fix2(obj.h)}`;
  }
  if (now - timestamp < 60 * 60 * 1000) {
    return Math.floor((now - timestamp) / (60 * 1000)) === 0
      ? '刚刚'
      : `${Math.floor((now - timestamp) / (60 * 1000))}分钟前`;
  }
  return `${Math.floor((now - timestamp) / (60 * 60 * 1000))}小时前`;
}

/**
 * 时间文本正常化
 */
export function normalizeTimeStr(str, showPrefix = false) {
  if (!str) {
    return '';
  }
  const arr = str.split(':').map(s => s - 0);
  if (arr[0] < 24) {
    return str;
  }
  const h = arr[0] % 24;
  const m = arr[1];
  const prefix = showPrefix ? '次日' : '';
  return prefix + [fix2(h), fix2(m)].join(':');
}

export default {};
