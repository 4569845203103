import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from 'antd';

import req from '../../../../req';
import config from '../../../../config';

const StyledCompanyPicker = styled.div`
  background-color: #fff;
  max-width: 1000px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

  .company_list {
    width: 300px;
    height: 500px;
    overflow: auto;

    .header {
      height: 52px;
      box-shadow: 0 1px 0 0 #e8e8e8;
    }

    .btn {
      padding: 1px 8px;
      border-radius: 2px;
      border: solid 1px #dee0e3;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;

      &:hover {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .list {
      margin-top: 12px;

      .item {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 8px;
        cursor: pointer;

        &.z-crt {
          background-color: #f7f7f9;
          color: rgba(0, 0, 0, 0.85);
        }

        &:hover {
          background-color: #f7f7f9;
          color: rgba(0, 0, 0, 0.85);

          .info {
            .setup {
              display: block;
            }
          }

          .action {
            display: block;
          }
        }

        .info {
          padding: 10px 24px;

          .name {
            width: 200px;
            word-break: break-all;
          }

          .setup {
            display: none;
            color: #1890ff;
            cursor: pointer;
          }
        }

        .action {
          display: none;
          padding: 0 24px 4px 24px;
        }
      }
    }
  }

  .project_list {
    width: 300px;
    height: 500px;
    border-left: 1px solid #e8e8e8;

    .header {
      margin: 0 24px;
      height: 52px;
      box-shadow: 0 1px 0 0 #e8e8e8;
    }

    .btn {
      padding: 1px 8px;
      border-radius: 2px;
      border: solid 1px #dee0e3;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;

      &:hover {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .search {
      padding: 0 24px;
      margin-top: 16px;
    }

    .list {
      margin-top: 9px;
      height: 370px;
      overflow: auto;

      .item {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 8px;
        padding: 10px 24px;
        cursor: pointer;

        &:hover {
          background-color: #f7f7f9;
          color: rgba(0, 0, 0, 0.85);
        }

        .name {
          width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .status {
          margin-left: 10px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
`;

const StyledParentName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;
  padding: 10px 24px;

  &:hover,
  &.z-crt {
    background-color: #f7f7f9;
    color: rgba(0, 0, 0, 0.85);
  }
`;

function status2label(status) {
  switch (status) {
    case '00':
      return '在建';
    case '01':
      return '完工';
    case '02':
      return '停工';
    default:
      return '';
  }
}

function CompaniesBox({
  title,
  list,
  crt,
  defaultCrt,
  onChange,
  onSwitch,
  topCompanyRoleMap,
  ...rest
}) {
  useEffect(() => {
    if (defaultCrt && defaultCrt !== crt) {
      const defaultCmp = list.find(d => d.id === defaultCrt);
      if (defaultCmp) {
        onChange(defaultCmp);
      }
    }
  }, [defaultCrt]);
  const codes = config.companyRoleCode;
  return (
    <div className="company_list" data-flex-box="0" {...rest}>
      <div className="header" data-flex="main:left cross:center">
        {title}
      </div>
      <div className="list">
        {list.map(item => {
          const showAction = topCompanyRoleMap[item.topId]
            ? topCompanyRoleMap[item.topId][codes.super] ||
              topCompanyRoleMap[item.topId][codes.admin]
            : false;
          return (
            <div
              className={crt === item.id ? 'item z-crt' : 'item'}
              onMouseEnter={() => onChange(item)}
              key={item.path}
              onClick={() => onSwitch(item)}
            >
              <div className="info" data-flex="main:left cross:center">
                <div className="name" title={item.name}>
                  {item.shortName || item.name || '-'}
                </div>
                <div data-flex-box="1" />
                <div className="setup" data-flex-box="0">
                  切换
                </div>
              </div>
              {showAction ? (
                <div className="action" onClick={e => e.stopPropagation()}>
                  <span
                    className="btn"
                    onClick={() => onSwitch(item, '/company/data')}
                  >
                    公司总览
                  </span>
                  <span
                    className="btn"
                    onClick={() => onSwitch(item, '/company/overview')}
                    style={{ marginLeft: 8 }}
                  >
                    数据看板
                  </span>
                </div>
              ) : null}
              {/* <div className="action" onClick={e => e.stopPropagation()}>
                <span
                  className="btn"
                  onClick={() => onSwitch(item, '/company/data')}
                >
                  公司总览
                </span>
                <span
                  className="btn"
                  onClick={() => onSwitch(item, '/company/overview')}
                  style={{ marginLeft: 8 }}
                >
                  数据看板
                </span>
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

CompaniesBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  list: PropTypes.array.isRequired,
  crt: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
  topCompanyRoleMap: PropTypes.object.isRequired,
  defaultCrt: PropTypes.string,
};

CompaniesBox.defaultProps = {
  defaultCrt: '',
};

class ProjectsBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
    };
    this.page = 1;
    this.keyword = '';
    this.list = [];
    this.loading = false;
    this.getList = this.getList.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.timer = null;
    this.delay = 1000;
  }

  componentDidMount() {
    const that = this;
    this.mounted = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      that.getList();
    }, this.delay);
  }

  componentDidUpdate(prevProps) {
    const { cid } = this.props;
    if (prevProps.cid !== cid) {
      this.page = 1;
      this.keyword = '';
      this.list = [];
      this.loading = false;
      clearTimeout(this.timer);
      this.getList();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.page = 1;
    this.keyword = '';
    this.list = [];
    this.loading = false;
    clearTimeout(this.timer);
  }

  onSearch(value) {
    this.keyword = value;
    this.page = 1;
    this.list = [];
    this.getList();
  }

  getList() {
    const that = this;
    const { cid } = this.props;
    if (this.loading || !this.mounted) {
      return;
    }
    this.loading = true;
    const data = {
      companyId: cid,
      page: that.page,
      size: 8,
      name: that.keyword,
      projectType: that.keyword ? '00' : '01',
    };
    req.company
      .searchCompanyProjects(data)
      .then(res => {
        that.loading = false;
        if (!that.mounted || cid !== that.props.cid) {
          return;
        }
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        that.list = that.list.concat(res.data.list);
        that.setState({
          list: that.list,
          total: res.data.total,
        });
        if (res.data.list.length) {
          that.page += 1;
        }
      })
      .catch(err => {
        that.loading = false;
        req.err.show(err);
      });
  }

  render() {
    const { list, total } = this.state;
    const { onItemTap, ...rest } = this.props;
    return (
      <div className="project_list" data-flex-box="0" {...rest}>
        <div className="header" data-flex="main:left cross:center">
          项目快速入口
        </div>
        <div className="search">
          <Input.Search placeholder="请输入项目名" onSearch={this.onSearch} />
        </div>
        <div className="list">
          {list.map(item => (
            <div
              className="item"
              data-flex="main:left"
              onClick={() => onItemTap(item)}
              key={item.id}
            >
              <div className="name">{item.name}</div>
              <div data-flex-box="1" />
              <div className="status" data-flex-box="0">
                {status2label(item.status)}
              </div>
            </div>
          ))}
          {list.length < total ? (
            <div style={{ textAlign: 'center', marginTop: 24 }}>
              <span className="btn" onClick={this.getList}>
                更多项目
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

ProjectsBox.propTypes = {
  cid: PropTypes.string.isRequired,
  onItemTap: PropTypes.func.isRequired,
};

function list2tree(list, basePath) {
  return list.map((item, idx) => {
    const path = basePath ? `${basePath}.${idx}` : `${idx}`;
    return {
      id: item.id,
      topId: item.topCompanyId || item.id,
      name: item.name,
      shortName: item.shortName,
      children: list2tree(item.childrenCompanys, path),
      path,
    };
  });
}

function CompanyPicker({ history, topRoles, update, defaultCrt }) {
  const [tree, setTree] = useState([]);
  const [deep, setDeep] = useState([]);
  const [projectBoxInfo, setProjectBoxInfo] = useState({});
  const [topCompanyRoleMap, setTopCompanyRoleMap] = useState({});
  const onCompanyChange = useCallback(
    info => {
      const { path } = info;
      const paths = path.split('.').map(n => n - 0);
      const newDeep = [];
      const last = paths.reduce(
        (pV, cV) => {
          const list = pV.children;
          newDeep.push({
            list,
            crt: list[cV].id,
            parent: pV,
          });
          return list[cV];
        },
        {
          children: tree,
        }
      );
      setProjectBoxInfo(last.children && last.children.length ? '' : last);
      if (
        deep.length >= newDeep.length &&
        !newDeep.find((item, index) => item.crt !== deep[index].crt)
      ) {
        return;
      }
      if (last.children && last.children.length) {
        newDeep.push({
          list: last.children,
          crt: '',
          parent: last,
        });
      }
      setDeep(newDeep);
    },
    [tree]
  );
  const getTree = useCallback(() => {
    req.company
      .getMyCompanies()
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        const data = list2tree(res.data.list);
        setTree(data);
        setDeep([{ list: data, crt: '' }]);
      })
      .catch(req.err.show);
  }, []);

  function onCrtSwicth(info, url) {
    return req.company
      .switchCompany({
        topCompanyId: info.topId,
        companyId: info.id,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        let newUrl = url || history.location.pathname;
        if (/\/project/.test(newUrl)) {
          newUrl = '/project/overview';
        }
        history.replace(
          `/reload${newUrl ? `?url=${encodeURIComponent(newUrl)}` : ''}`
        );
      })
      .catch(req.err.show);
  }
  function onProjectTap(info) {
    return req.company
      .switchCompany({
        topCompanyId: projectBoxInfo.topId,
        companyId: projectBoxInfo.id,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        const target = `/project/projectItem/${info.id}`;
        history.replace(`/reload?url=${encodeURIComponent(target)}`);
      })
      .catch(req.err.show);
  }
  function checkCompanyRole(info) {
    if (topCompanyRoleMap[info.topId]) {
      return;
    }
    req.company
      .getCompanyRole({
        companyId: info.id,
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
          return;
        }
        const roleMap = {};
        res.data.list.forEach(r => {
          roleMap[r.roleCode] = true;
        });
        setTopCompanyRoleMap(
          Object.assign(
            {
              [info.id]: roleMap,
            },
            topCompanyRoleMap
          )
        );
      })
      .catch(req.err.show);
  }
  function onCompaniesBoxChange(info) {
    checkCompanyRole(info);
    onCompanyChange(info);
  }
  useEffect(() => {
    getTree();
    setTopCompanyRoleMap({});
  }, [update]);
  const [first, ...restDeep] = deep;
  const topRoleMap = {};
  if (topRoles && topRoles.length) {
    topRoles.forEach(r => {
      topRoleMap[r.roleCode] = true;
    });
  }

  function handleParentProjectBox(info, level) {
    // 判断是否处于末端公司，如果不是，关掉在他之后的子公司面板，然后在显示快捷项目入口栏
    // 交互上不会有越两级的情况
    if (level !== deep.length - 2) {
      const newDeep = deep.slice(0, deep.length - 1);
      setDeep(newDeep);
    }
    setProjectBoxInfo(info);
  }

  return (
    <StyledCompanyPicker
      data-flex="main:left cross:top"
      onClick={e => e.nativeEvent.stopImmediatePropagation()}
    >
      {first ? (
        <CompaniesBox
          title={
            <div style={{ margin: '0 24px' }}>
              <div style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>
                公司切换
              </div>
              <div data-flex-box="1" />
              <div>
                <Link
                  to={`/reload?url=${encodeURIComponent(
                    '/organization/overview'
                  )}`}
                  className="btn"
                >
                  组织架构
                </Link>
              </div>
            </div>
          }
          list={first.list}
          crt={first.crt}
          defaultCrt={defaultCrt}
          onChange={onCompaniesBoxChange}
          onSwitch={onCrtSwicth}
          topCompanyRoleMap={topCompanyRoleMap}
        />
      ) : null}
      {restDeep.map((d, i) => (
        <CompaniesBox
          title={
            <StyledParentName
              className={`${projectBoxInfo.id === d.parent.id ? 'z-crt' : ''}`}
              onMouseEnter={() => handleParentProjectBox(d.parent, i)}
            >
              {d.parent.name}
            </StyledParentName>
          }
          list={d.list}
          crt={projectBoxInfo.id === d.parent.id ? d.parent.id : d.crt}
          onChange={onCompanyChange}
          onSwitch={onCrtSwicth}
          style={{ borderLeft: '1px solid #e8e8e8' }}
          key={d.parent.id}
          topCompanyRoleMap={topCompanyRoleMap}
        />
      ))}
      {projectBoxInfo.id ? (
        <ProjectsBox cid={projectBoxInfo.id} onItemTap={onProjectTap} />
      ) : null}
    </StyledCompanyPicker>
  );
}

CompanyPicker.propTypes = {
  history: PropTypes.object.isRequired,
  topRoles: PropTypes.array,
  update: PropTypes.number.isRequired,
  defaultCrt: PropTypes.string,
};

CompanyPicker.defaultProps = {
  topRoles: [],
  defaultCrt: '',
};

export default withRouter(CompanyPicker);
