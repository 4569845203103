import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BellOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Popover, Badge } from 'antd';
import { connect } from 'react-redux';

import MessageList from '../MessageList';

import './index.css';

const StyledUserInfo = styled.div`
  padding-right: 30px;
  .bell {
    display: inline-block;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
  }

  .avt {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 8px;
    margin-left: 40px;
  }

  .name {
    cursor: pointer;
  }
`;

function mapStateToProps(state) {
  return {
    unReadMessageCount: state.unReadMessageCount,
  };
}
const mapDispatchToProps = {
  receiveMessageCount: () => ({ type: 'changeMessageCount' }),
  receiveBlacklistCount: () => ({ type: 'changeBlacklistCount' }),
};

const UserInfo = ({
  info,
  logout,
  unReadMessageCount,
  receiveMessageCount,
  receiveBlacklistCount,
}) => {
  if (!info || !info.id) {
    return null;
  }
  const [countPopoverVisible, setCountPopoverVisible] = useState(false);
  function onCountPopoverVisibleChnage(value) {
    setCountPopoverVisible(value);
  }
  useEffect(() => {
    receiveMessageCount();
    receiveBlacklistCount();
  }, []);
  const menu = (
    <Menu>
      <Menu.Item>
        <span onClick={logout}>
          <LogoutOutlined style={{ marginRight: 8 }} />
          退出登录
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <StyledUserInfo data-flex="cross:center">
      {info && info.id ? (
        <React.Fragment>
          <Popover
            content={
              <MessageList
                onShowMore={() => setCountPopoverVisible(false)}
                visible={countPopoverVisible}
              />
            }
            placement="bottom"
            trigger="click"
            overlayClassName="message_popover"
            onVisibleChange={onCountPopoverVisibleChnage}
            visible={countPopoverVisible}
            style={{ zIndex: 900 }}
          >
            <Badge count={unReadMessageCount} offset={[-10, 5]}>
              <BellOutlined className="bell" />
            </Badge>
          </Popover>
          <img className="avt" alt={info.name} src={info.img} />
          <Dropdown overlay={menu}>
            <span className="name">{info.name}</span>
          </Dropdown>
        </React.Fragment>
      ) : null}
    </StyledUserInfo>
  );
};

UserInfo.propTypes = {
  info: PropTypes.object,
  logout: PropTypes.func.isRequired,
  unReadMessageCount: PropTypes.number.isRequired,
  receiveMessageCount: PropTypes.func.isRequired,
  receiveBlacklistCount: PropTypes.func.isRequired,
};

UserInfo.defaultProps = {
  info: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
