import styled from 'styled-components';

const StyledInfoModal = styled.div`
  .head {
    color: #535a64;
    font-size: 16px;
  }

  .info_box {
    margin-top: 24px;
    border-radius: 2px;
    background-color: #fafafa;
    padding: 24px;
  }

  .info_box .row {
    margin-bottom: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }

  .info_box .row:last-child {
    margin-bottom: 0;
  }

  .info_box .label {
    width: 100px;
    text-align: right;
    color: rgba(0, 0, 0, 0.85);
  }

  .info_box .avt {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 8px;
  }
`;

export default StyledInfoModal;
