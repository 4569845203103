import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Details from '../../../Message/Details';
import noMsgImage from '../../../../img/null-msg@3x.png';
import req from '../../../../req';
import config from '../../../../config';
import BlackDetails from '../../../Message/black';
import ApplyJoin from '../../../Message/ApplyJoin';
import ProjectTransferModal from '../../../Message/ProjectTransferModal';
import JoinParticipantModal from '../../../Message/JoinParticipantModal';
import ContentModal from '../../../Message/ContentModal';
import AttendRecordApproveModal from '../../../Message/AttendRecordApproveModal';
import ModifyAttendRecordModal from '../../../Message/ModifyAttendRecordModal';

const {
  ADD_CHILD_COMPANY,
  ADD_PARENT_COMPANY,
  ADD_BLACKLIST,
  JOIN_COMPANY,
  JOIN_PROJECT,
  PROJECT_ADD_COMPANY,
  PROJECT_MIGRATE_COMPANY,
  UPPER_LEVEL_ITEMS_TO_LOWER_LEVEL,
  LOWER_LEVEL_TO_UPPER_LEVEL_ITEMS,
  ATTEND_MAKEUP,
  ATTEND_MISTAKE,
  MODIFY_ATTEND_RECORD,
} = config.message.typeMap;

class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      page: 1,
      size: 5,
      companyStatus: false,
      blackStatus: false,
      joinApplyStatus: false,
      selectId: '',
      crtUserInfos: {},
      projectTransferModalVisible: false,
      joinParticipantModalVisible: false,
      contentModalVisible: false,
      attendRecordApproveModalVisible: false,
      modifyAttendRecordModalVisible: false,
    };
    this.toggleCompanyStatus = this.toggleCompanyStatus.bind(this);
    this.listernerAgreeOrReject = this.listernerAgreeOrReject.bind(this);
    this.toggleBlackStatus = this.toggleBlackStatus.bind(this);
    this.toggleJoinApplyStatus = this.toggleJoinApplyStatus.bind(this);
    this.onProjectTransferModalCancel = this.onProjectTransferModalCancel.bind(
      this
    );
    this.onProjectTransferModalOk = this.onProjectTransferModalOk.bind(this);
    this.onJoinParticipantModalCancel = this.onJoinParticipantModalCancel.bind(
      this
    );
    this.onJoinParticipantModalOk = this.onJoinParticipantModalOk.bind(this);
    this.onContentModalClose = this.onContentModalClose.bind(this);
    this.saveId = this.saveId.bind(this);
    this.onAttendRecordApproveModalCancel = this.onAttendRecordApproveModalCancel.bind(
      this
    );
    this.onAttendRecordApproveModalOk = this.onAttendRecordApproveModalOk.bind(
      this
    );
    this.onModifyAttendRecordModalCancel = this.onModifyAttendRecordModalCancel.bind(
      this
    );
    this.onModifyAttendRecordModalOk = this.onModifyAttendRecordModalOk.bind(
      this
    );
  }

  componentDidMount() {
    this.getMyInfo();
    this.getList();
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    if (prevProps.visible === false && visible === true) {
      this.getList();
    }
  }

  onProjectTransferModalCancel() {
    this.setState({
      projectTransferModalVisible: false,
    });
  }

  onProjectTransferModalOk() {
    this.setState({
      projectTransferModalVisible: false,
    });
  }

  onJoinParticipantModalCancel() {
    this.setState({
      joinParticipantModalVisible: false,
    });
  }

  onJoinParticipantModalOk() {
    this.setState({
      joinParticipantModalVisible: false,
    });
  }

  onContentModalClose() {
    this.setState({
      contentModalVisible: false,
    });
  }

  onAttendRecordApproveModalCancel() {
    this.setState({
      attendRecordApproveModalVisible: false,
    });
  }

  onAttendRecordApproveModalOk() {
    this.setState({
      attendRecordApproveModalVisible: false,
    });
  }

  onModifyAttendRecordModalCancel() {
    this.setState({
      modifyAttendRecordModalVisible: false,
    });
  }

  onModifyAttendRecordModalOk() {
    this.setState({
      modifyAttendRecordModalVisible: false,
    });
  }

  getList() {
    const that = this;
    const { page, size } = this.state;
    req.sys
      .getMessageList({
        page,
        size,
        type: '01',
      })
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res);
        }
        that.setState({
          list: res.data.companyMessageList,
        });
        // onDataLoad(res.data);
      })
      .catch(req.err.show);
  }

  /**
   * 获取当前个人信息
   */
  getMyInfo() {
    const that = this;
    req.user
      .getMyInfo()
      .then(res => {
        if (res.code !== 0) {
          req.err.show(res.msg);
          return;
        }
        that.setState({
          crtUserInfos: res.data,
        });
      })
      .catch(req.err.show);
  }

  saveId(item) {
    const that = this;
    this.setState(
      {
        selectId: item.id,
      },
      () => {
        switch (item.messageType) {
          case ADD_CHILD_COMPANY:
          case ADD_PARENT_COMPANY:
            that.toggleCompanyStatus();
            break;
          case ADD_BLACKLIST:
            that.toggleBlackStatus();
            break;
          case JOIN_COMPANY:
          case JOIN_PROJECT:
            that.toggleJoinApplyStatus();
            break;
          case PROJECT_ADD_COMPANY:
          case PROJECT_MIGRATE_COMPANY:
            that.setState({
              projectTransferModalVisible: true,
            });
            break;
          case UPPER_LEVEL_ITEMS_TO_LOWER_LEVEL:
          case LOWER_LEVEL_TO_UPPER_LEVEL_ITEMS:
            that.setState({
              joinParticipantModalVisible: true,
            });
            break;
          case ATTEND_MAKEUP:
          case ATTEND_MISTAKE:
            that.setState({
              attendRecordApproveModalVisible: true,
            });
            break;
          case MODIFY_ATTEND_RECORD:
            that.setState({
              modifyAttendRecordModalVisible: true,
            });
            break;
          default:
            that.setState({
              contentModalVisible: true,
            });
            break;
        }
      }
    );
  }

  toggleBlackStatus() {
    const { blackStatus } = this.state;
    this.setState({
      blackStatus: !blackStatus,
    });
  }

  toggleCompanyStatus() {
    const { companyStatus } = this.state;
    this.setState({
      companyStatus: !companyStatus,
    });
  }

  toggleJoinApplyStatus() {
    const { joinApplyStatus } = this.state;
    this.setState({
      joinApplyStatus: !joinApplyStatus,
    });
  }

  listernerAgreeOrReject() {
    // const { location, history } = this.props;
    // const url = location.pathname === '/message';
    // if (url) {
    //   // console.log('小铃铛里的list，路由等于message', location);
    //   const target = `/reload?url=${encodeURIComponent(location.pathname)}`;
    //   history.replace(target);
    //   return;
    // }
    this.getList();
  }

  render() {
    const {
      list,
      companyStatus,
      selectId,
      crtUserInfos,
      blackStatus,
      joinApplyStatus,
      projectTransferModalVisible,
      joinParticipantModalVisible,
      contentModalVisible,
      attendRecordApproveModalVisible,
      modifyAttendRecordModalVisible,
    } = this.state;
    const { onShowMore } = this.props;
    const noMsg = (
      <div
        style={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
          textAlign: 'center',
        }}
      >
        <div>
          <img
            src={noMsgImage}
            alt="没有信息通知"
            style={{ width: 160, height: 88 }}
          />
        </div>
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.45)',
            fontSize: '14px',
            marginTop: 15,
          }}
        >
          暂无通知消息
        </p>
      </div>
    );
    const listItems = (list || []).map(item => (
      <div
        key={item.id}
        className="list_item_in_form"
        onClick={() => this.saveId(item)}
      >
        <div>{item.title}</div>
      </div>
    ));
    return (
      <div>
        <div
          style={{
            width: 336,
            fontSize: '14px',
            height: 354, // 400-46
            overflow: 'auto',
          }}
        >
          {!list || !list.length ? noMsg : listItems}
        </div>
        <p
          style={{
            color: '#4a90e2',
            textAlign: 'center',
            marginBottom: 0,
            borderTop: '1px solid #e8e8e8',
            width: '100%',
            padding: '12px 0',
            zIndex: 100,
            cursor: 'pointer',
          }}
        >
          <Link to="/message" onClick={onShowMore}>
            前往消息中心
          </Link>
        </p>
        <Details
          flag={companyStatus}
          hideStatus={this.toggleCompanyStatus}
          id={selectId}
          listernerAgreeOrReject={this.listernerAgreeOrReject}
          crtUserInfos={crtUserInfos}
        />
        <BlackDetails
          id={selectId}
          toggleModel={this.toggleBlackStatus}
          modelStatus={blackStatus}
          listernerAgreeOrReject={this.listernerAgreeOrReject}
          crtUserInfos={crtUserInfos}
        />
        <ApplyJoin
          id={selectId}
          toggleModel={this.toggleJoinApplyStatus}
          modelStatus={joinApplyStatus}
          listernerAgreeOrReject={this.listernerAgreeOrReject}
          crtUserInfos={crtUserInfos}
        />
        <ProjectTransferModal
          id={selectId}
          onCancel={this.onProjectTransferModalCancel}
          onOk={this.onProjectTransferModalOk}
          visible={projectTransferModalVisible}
        />
        <JoinParticipantModal
          id={selectId}
          onCancel={this.onJoinParticipantModalCancel}
          onOk={this.onJoinParticipantModalOk}
          visible={joinParticipantModalVisible}
        />
        <ContentModal
          id={selectId}
          onClose={this.onContentModalClose}
          visible={contentModalVisible}
        />
        <AttendRecordApproveModal
          id={selectId}
          onCancel={this.onAttendRecordApproveModalCancel}
          onOk={this.onAttendRecordApproveModalOk}
          visible={attendRecordApproveModalVisible}
        />
        <ModifyAttendRecordModal
          id={selectId}
          onCancel={this.onModifyAttendRecordModalCancel}
          onOk={this.onModifyAttendRecordModalOk}
          visible={modifyAttendRecordModalVisible}
        />
      </div>
    );
  }
}

MessageList.propTypes = {
  onShowMore: PropTypes.func.isRequired,
  // history: PropTypes.object.isRequired,
  // location: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default withRouter(MessageList);
