import loadable from '@loadable/component';

const Overview = loadable(() => import('./Overview'));
const Join = loadable(() => import('./Join'));
const Append = loadable(() => import('./Append'));

export default {
  Overview,
  Join,
  Append,
};
