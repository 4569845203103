import loadable from '@loadable/component';

const Overview = loadable(() => import('./Overview'));
const OverviewExport = loadable(() => import('./Overview/Export'));
const ProjectItem = loadable(() => import('./ProjectItem'));
const WorkePortrait = loadable(() => import('./WorkerPortrait'));

export default {
  Overview,
  ProjectItem,
  WorkePortrait,
  OverviewExport,
};
