export default {
  install(R) {
    /**
     * 获取权限字典
     */
    function getParticipantTypeDict() {
      return R.cachify('sys.getDicts')({
        key: '30001',
      });
    }
    const r = R;
    r.participantType = {
      getDicts(data) {
        return getParticipantTypeDict(data);
      },
      getDictsMap(data) {
        return getParticipantTypeDict(data).then(res => {
          if (res.code !== 0) {
            return res;
          }
          return {
            code: 0,
            data: res.data.reduce((pV, cV) => {
              const p = pV;
              p[cV.code] = cV;
              return pV;
            }, {}),
          };
        });
      },
    };
  },
};
